import { IColorSkin } from "../../variablesAvatar/VariableAvatar";

// Mesomorphic Ectomorph
export const getCSkinFem = (
  colorsSkin: IColorSkin,
  cloth: boolean
): React.SVGProps<SVGGElement> => (
  <g transform="translate(3.401 -6.847)">
    <g id="legs" transform="matrix(.8863 0 0 1 7.742 0)">
      <g id="leg-left" transform="matrix(.88278 0 0 1 -87.537 15.658)">
        <path
          id="tibia-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-1.112-24.01-1.112-26.082v-10.717a6.984 6.984 0 016.98-6.98z"
          transform="translate(-.426 -.667)"
        ></path>
        <path
          id="thigh-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M191.234 175.194h7.531a4.55 4.55 0 014.502 3.902l1.745 33.311c.212 1.481.319 2.976.319 4.472v11.072c0 1.729-.143 3.456-.426 5.162l-.926 5.564a27.538 27.538 0 01-3.213 9.063s-2.293 4.918-2.293 6.087c0 3.73.495 8.854-5.006 8.768h-4.452c-3.995 0-9.246.016-9.246-3.98 0-.862-.163-10.669-.399-11.843l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
          transform="translate(.852 -.667)"
        ></path>
        <g id="foot-left" transform="translate(-2.556) translate(.852 -.667)">
          <path
            id="color3"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
          ></path>
          <path
            id="color31"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
          ></path>
        </g>
      </g>
      <g id="leg-right" transform="matrix(.88278 0 0 1 -86.784 14.991)">
        <path
          id="tibia-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-1.112-24.01-1.112-26.082v-10.717a6.984 6.984 0 016.98-6.98z"
          transform="matrix(-1 0 0 1 352.135 0)"
        ></path>
        <path
          id="thigh-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M191.234 175.194h7.531a4.55 4.55 0 014.502 3.902l1.745 33.311c.212 1.481.319 2.976.319 4.472v11.072c0 1.729-.143 3.456-.426 5.162l-.926 5.564a27.538 27.538 0 01-3.213 9.063s-2.293 4.918-2.293 6.087c0 3.73.495 8.854-5.006 8.768h-4.452c-3.995 0-9.246.016-9.246-3.98 0-.862-.163-10.669-.399-11.843l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
          transform="matrix(-1 0 0 1 350.857 0)"
        ></path>
        <g
          id="foot-right"
          transform="matrix(1 0 0 1 1.704 .667) translate(.852 -.667)"
        >
          <path
            id="color32"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M161.873 308.505h-8.116c-4.68 0-8.473 3.384-8.473 7.558 0 4.174 3.793 7.557 8.473 7.557h8.116c4.679 0 8.473-3.383 8.473-7.557s-3.794-7.558-8.473-7.558z"
          ></path>
          <path
            id="color33"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M167.54 315.333h-19.45c-1.549 0-2.806 1.121-2.806 2.503v3.274c0 1.382 1.257 2.502 2.806 2.502h19.45c1.55 0 2.806-1.12 2.806-2.502v-3.274c0-1.382-1.256-2.503-2.806-2.503z"
          ></path>
        </g>
      </g>
    </g>
    <g transform="matrix(.76586 0 0 1 16.086 0)">
      <g id="deltoids">
        <path
          fill={colorsSkin.strong}
          fillOpacity="0.99"
          fillRule="nonzero"
          d="M36.092 135.201c0-15.71 66.361-14.507 66.361 0l-2.528 15.532-3.502 44.198c0 15.71-10.799.569-28.457.569-8.031 0-15.055 2.1-20.327 2.911-6.318.973-8.193 3.371-8.193-5.193l-3.354-42.485v-15.532z"
        ></path>
        <path
          fill={colorsSkin.light}
          fillOpacity="0.99"
          fillRule="nonzero"
          d="M67.966 106.844c1.352 0 2.699.074 4.034.218 16.102 1.737 30.453 13.632 30.453 28.139l-2.528 15.532-3.502 44.198c0 15.71-10.799.569-28.457.569-8.031 0-15.055 2.1-20.327 2.911-6.318.973-8.193 3.371-8.193-5.193l-3.354-42.485v-15.532c0-15.71 14.216-28.357 31.874-28.357z"
          transform="matrix(.92253 0 0 1 5.367 0)"
        ></path>
      </g>
    </g>
    <g transform="matrix(.73664 0 0 1 17.932 0)">
      <path
        id="trunk"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M67.925 170.441h1.023c16.943 0 22.442-8.764 25.223 3.114l3.223 20.006c2.778 11.868-11.503 25.547-28.446 25.547h-1.023c-16.943 0-31.863-14.378-29.085-26.246l4.426-19.307c2.781-11.878 7.716-3.114 24.659-3.114z"
      ></path>
    </g>
    <g id="arms" transform="matrix(.8863 0 0 1 7.742 0)">
      <g id="arm-right" transform="matrix(.82307 0 0 1 13.177 0)">
        <path
          id="bicep-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M106.462 123.095c5.175-1.001 11.84 6.466 12.98 12.361 0 0 3.368 30.511 3.336 32.875-.057 4.141-1.945 7.178-6.027 7.967-4.457.863-7.418-1.357-8.882-5.756-.497-1.493-3.462-27.641-3.533-29.225-.195-4.355-1.768-17.469 2.126-18.222z"
          transform="matrix(-1 0 0 1 135.265 -1)"
        ></path>
        <path
          id="forearm-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M114.564 156.787l.067-.002c4.241-.131 7.919 4.578 8.215 10.517l2.223 44.6c.295 5.94-2.903 10.861-7.145 10.992l-.066.002c-4.241.131-7.92-4.578-8.216-10.518l-2.222-44.6c-.296-5.94 2.903-10.861 7.144-10.991z"
          transform="matrix(-1 0 0 1 135.265 -1)"
        ></path>
        <g id="hand-right" fill={colorsSkin.light} fillRule="nonzero">
          <path d="M21.541 202.739l-2.666-.015c-5.109-.027-9.491 5.517-9.789 12.383l-.264 6.1c-.298 6.866 3.602 12.454 8.711 12.481l2.665.015c5.109.027 9.491-5.517 9.789-12.383l.265-6.1c.297-6.866-3.603-12.454-8.711-12.481z"></path>
          <path d="M22.199 220.089c-1.111-4.367-5.03-6.578-8.752-4.939-3.722 1.639-5.839 6.508-4.728 10.874l3.036 11.926c1.111 4.366 5.03 6.577 8.752 4.938 3.723-1.639 5.839-6.507 4.728-10.874l-3.036-11.925z"></path>
        </g>
        <path
          id="thumb-right"
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M30.913 217.757c.125-3.044-1.581-5.522-3.812-5.536-2.23-.014-4.14 2.443-4.266 5.487l-.343 8.313c-.126 3.044 1.581 5.523 3.812 5.537 2.23.013 4.14-2.443 4.266-5.487l.343-8.314z"
        ></path>
      </g>
      <g
        id="arm-left"
        fillRule="nonzero"
        transform="matrix(.82307 0 0 1 12.049 0)"
      >
        <path
          id="bicep-left1"
          fill={colorsSkin.light}
          d="M106.462 123.095c5.175-1.001 11.84 6.466 12.98 12.361 0 0 3.368 30.511 3.336 32.875-.057 4.141-1.945 7.178-6.027 7.967-4.457.863-7.418-1.357-8.882-5.756-.497-1.493-3.462-27.641-3.533-29.225-.195-4.355-1.768-17.469 2.126-18.222z"
        ></path>
        <path
          id="forearm-left1"
          fill={colorsSkin.light}
          d="M114.564 156.787l.067-.002c4.241-.131 7.919 4.578 8.215 10.517l2.223 44.6c.295 5.94-2.903 10.861-7.145 10.992l-.066.002c-4.241.131-7.92-4.578-8.216-10.518l-2.222-44.6c-.296-5.94 2.903-10.861 7.144-10.991z"
        ></path>
        <g id="hand-left" fill={colorsSkin.light}>
          <path d="M114.657 202.739l2.665-.015c5.109-.027 9.492 5.517 9.789 12.383l.265 6.1c.297 6.866-3.603 12.454-8.711 12.481l-2.666.015c-5.109.027-9.491-5.517-9.789-12.383l-.264-6.1c-.298-6.866 3.602-12.454 8.711-12.481z"></path>
          <path d="M113.998 220.089c1.111-4.367 5.03-6.578 8.752-4.939 3.723 1.639 5.839 6.508 4.728 10.874l-3.036 11.926c-1.111 4.366-5.03 6.577-8.752 4.938-3.722-1.639-5.839-6.507-4.728-10.874l3.036-11.925z"></path>
        </g>
        <path
          id="thumb-left"
          fill={colorsSkin.strong}
          d="M105.284 217.757c-.125-3.044 1.581-5.522 3.812-5.536 2.231-.014 4.141 2.443 4.266 5.487l.343 8.313c.126 3.044-1.581 5.523-3.811 5.537-2.231.013-4.141-2.443-4.267-5.487l-.343-8.314z"
        ></path>
      </g>
    </g>
    <g transform="matrix(.7295 0 0 1 18.421 0)">
      <path
        id="shoulders"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M29.534 115.593h77.272c7.658 0 13.823 5.745 13.823 12.882s-6.165 12.882-13.823 12.882H29.534c-7.658 0-13.823-5.745-13.823-12.882s6.165-12.882 13.823-12.882z"
      ></path>
    </g>
    <g transform="matrix(.7295 0 0 1 18.421 0)">
      <path
        id="bust"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M56.05 114.065h24.095c13.464 0 24.302 9.892 24.302 22.18 0 12.287-10.838 22.179-24.302 22.179H56.05c-13.463 0-24.302-9.892-24.302-22.179 0-12.288 10.839-22.18 24.302-22.18z"
      ></path>
    </g>
    <g transform="matrix(.7295 0 0 1 18.421 0)">
      <path
        id="breasts-back"
        fill={colorsSkin.strong}
        fillOpacity="0.99"
        fillRule="nonzero"
        d="M68.738 141.357a5.648 5.648 0 015.659 5.66v5.249a5.647 5.647 0 01-5.659 5.66 5.647 5.647 0 01-5.66-5.66v-5.249a5.648 5.648 0 015.66-5.66z"
      ></path>
    </g>
    <g transform="matrix(.7295 0 0 .95453 18.421 6.158)">
      <path
        id="breasts"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M54.444 135.418a8.105 8.105 0 00-4.042 1.053c-3.843.216-7.446 1.666-10.078 4.055-2.632 2.389-4.097 5.539-4.097 8.812 0 3.42 1.599 6.701 4.446 9.119 2.846 2.419 6.707 3.777 10.733 3.777 3.667-.003 7.208-1.135 9.97-3.185 2.761-2.05 4.556-4.88 5.052-7.968a5.59 5.59 0 00.157-1.312v-8.015c0-3.51-3.262-6.336-7.313-6.336h-4.828zm22.482 0c-4.05 0-7.312 2.826-7.312 6.336v8.015c0 .45.055.889.157 1.312.496 3.088 2.291 5.918 5.052 7.968 2.761 2.05 6.303 3.182 9.97 3.185 4.026 0 7.886-1.358 10.733-3.777 2.846-2.418 4.446-5.699 4.446-9.119-.001-3.273-1.465-6.423-4.098-8.812-2.632-2.389-6.234-3.839-10.077-4.055a8.106 8.106 0 00-4.043-1.053h-4.828z"
      ></path>
    </g>
    <g transform="translate(65.978 182.619)">
      <path
        id="navel"
        fill={colorsSkin.strong}
        d="M3.426 1.376a1.262 1.262 0 00-2.522 0v2.347a1.261 1.261 0 002.522 0V1.376z"
      ></path>
    </g>
    {!cloth && (
      <g id="topwear" fill="#4B4B4B" fillRule="nonzero">
        <path
          d="M160.721 116.733h21.855c12.212 0 22.042 10.124 22.042 22.699 0 12.575-.426 29.127-21.59 26.123h-22.307c-21.87 2.768-22.043-13.548-22.043-26.123 0-12.575 9.831-22.699 22.043-22.699z"
          transform="matrix(.80428 0 0 .98154 -69.456 -1.835)"
        ></path>
        <path
          fillOpacity="0.99"
          d="M171.53 109.344c1.226 0 2.448.075 3.659.223 14.605 1.777 27.354 11.235 27.622 28.797l-.523 16.142-59.669-.247v-15.895c-.26-18.102 12.895-29.02 28.911-29.02z"
          transform="matrix(.80428 0 0 .98154 -69.456 -1.835)"
        ></path>
      </g>
    )}
    <g id="underwear">
      <path
        fill="#4F4F4F"
        fillRule="nonzero"
        d="M39.583 189.84c1.616 22.732 13.622 40.432 28.513 40.432 15.086 0 29.944-16.927 31.303-40.087l-59.816-.345z"
        transform="matrix(.7295 0 0 1 17.421 0)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M99.399 169.542l-30.677 5.869s1.182 65.326 2.079 70.445h30.717c1.621-29.005-.152-60.198-2.119-76.314z"
        transform="matrix(.7295 0 0 1 17.421 0) matrix(1 0 0 .7295 0 66.505)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M39.583 189.84l29.038 4.544v35.79c.214 5.319.102 10.601-.472 15.831l-30.717-.182c-2.126-29.149.238-38.282 2.151-55.983z"
        transform="matrix(.7295 0 0 1 17.421 0)"
      ></path>
    </g>
  </g>
);

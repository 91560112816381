import { getBeardMale } from "../beard/males/BeardMale";
import { IColorHair, IColorSkin } from "../variablesAvatar/VariableAvatar";
import { getFacesMales } from "../faces/males/FacesMales";
import { getHairMale } from "../hairs/males/HairsMales";
import { getFacesFemale } from "../faces/female/FacesFemales";

export const getHead = (
  gender: string,
  skin: string,
  hair: string,
  beard: string,
  face: string,
  colorsSkin: IColorSkin,
  colorsHair: IColorHair
): React.SVGProps<SVGGElement> => {
  interface ITransformHead {
    BM: string;
    EnM: string;
    EcM: string;
    MEc: string;
    BEc: string;
    EnEc: string;
    EcEn: string;
    BEn: string;
    MEn: string;
    "M-Ec": string;
    "En-Ec": string;
    "M-En": string;
    C: string;
  }

  const transformHeadMale: ITransformHead = {
    BM: "translate(23.335 -9.537)",
    EnM: "translate(23.335 -9.537)",
    EcM: "translate(23.335 -9.367)",
    MEc: "translate(23.83 -9.535)",
    BEc: "translate(23.877 -9.173)",
    EnEc: "translate(24.177 -9.346)",
    EcEn: "translate(24.235 -9.174)",
    BEn: "translate(23.927 -8.495)",
    MEn: "translate(23.524 -8.643)",
    "M-Ec": "translate(23.712 -9.513)",
    "En-Ec": "translate(23.661 -8.643)",
    "M-En": "translate(23.664 -9.56)",
    C: "translate(23.877 -9.56)",
  };

  const transformHeadFemale: ITransformHead = {
    BM: "translate(3.901 -12.034)",
    EnM: "translate(3.901 -12.034)",
    EcM: "translate(3.901 -12.113)",
    MEc: "translate(3.901 -4.305)",
    BEc: "translate(3.901 -2.988)",
    EnEc: "translate(3.901 -2.951)",
    EcEn: "translate(3.904 -10.755)",
    BEn: "translate(3.904 -6.16)",
    MEn: "translate(3.524 -6.16)",
    "M-Ec": "translate(3.446 -6.067)",
    "En-Ec": "translate(3.901 -2.951)",
    "M-En": "translate(3.45 -4.77)",
    C: "translate(3.904 -3.434)",
  };

  const headMale: JSX.Element = (
    <g transform={(transformHeadMale as any)[skin]}>
      <g id="neck">
        <path
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M189.823 81.332c0-9.98-6.756-18.07-15.092-18.07-8.337 0-15.094 8.09-15.094 18.07v7.414c0 9.98 6.757 18.071 15.094 18.071 8.336 0 15.092-8.091 15.092-18.071v-7.414z"
          transform="translate(-128.151)"
        ></path>
        <path
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M169.181 102.439l-8.238-3.959a1.842 1.842 0 10-1.596 3.319l8.239 3.961a1.841 1.841 0 101.595-3.321zM179.194 102.439l8.239-3.959a1.843 1.843 0 012.459.861 1.843 1.843 0 01-.863 2.458l-8.238 3.961a1.842 1.842 0 01-1.597-3.321zM177.31 64.477h-5.854c-6.72 0-12.167 5.447-12.167 12.166v4.992c0 6.719 5.447 12.166 12.167 12.166h5.854c6.719 0 12.166-5.447 12.166-12.166v-4.992c0-6.719-5.447-12.166-12.166-12.166z"
          transform="translate(-128.151)"
        ></path>
      </g>
      <g id="ears">
        <path
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M196.283 53.888h-42.537c-4.863 0-8.806 4.027-8.806 8.995 0 4.969 3.943 8.996 8.806 8.996h42.537c4.863 0 8.807-4.027 8.807-8.996 0-4.968-3.944-8.995-8.807-8.995z"
          transform="matrix(.98337 0 0 1 .28 0) translate(-128.151)"
        ></path>
        <path
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M195.649 55.248h-42.042c-3.638 0-6.586 3.003-6.586 6.708s2.948 6.709 6.586 6.709h42.042c3.637 0 6.586-3.004 6.586-6.709 0-3.705-2.949-6.708-6.586-6.708z"
          transform="matrix(.98337 0 0 1 .28 0) matrix(1.01842 0 0 1 -130.859 0)"
        ></path>
      </g>
      <path
        id="head"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M180.931 27.735H168.34c-10.431 0-18.888 8.456-18.888 18.888v22.384c0 10.433 8.457 18.89 18.888 18.89h12.591c10.431 0 18.888-8.457 18.888-18.89V46.623c0-10.432-8.457-18.888-18.888-18.888z"
        transform="translate(-128.151)"
      ></path>
      <g id="chin">
        <path
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M180.532 85.336h-11.765a2.304 2.304 0 000 4.608h11.765a2.304 2.304 0 000-4.608z"
          transform="translate(-128.151)"
        ></path>
        <path
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M176.549 82.761h-3.402a.493.493 0 00-.492.493v.29c0 .272.22.493.492.493h3.402a.493.493 0 00.492-.493v-.29a.493.493 0 00-.492-.493z"
          transform="translate(-128.151)"
        ></path>
      </g>
      <g id="nose">
        <path
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M178.315 61.001a3.698 3.698 0 10-7.397 0v6.526a3.7 3.7 0 007.397 0v-6.526z"
          transform="translate(-128.151)"
        ></path>
        <path
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M174.211 68.387l-3.439-2.923a1.351 1.351 0 00-1.749 2.058l3.439 2.923a1.351 1.351 0 001.749-2.058zM175.155 68.387l3.439-2.923a1.35 1.35 0 111.749 2.058l-3.44 2.923a1.351 1.351 0 01-1.748-2.058zM172.298 54.231a1.036 1.036 0 10-2.073 0v1.255a1.037 1.037 0 002.073 0v-1.255zM179.182 54.231a1.036 1.036 0 10-2.073 0v1.255a1.037 1.037 0 002.073 0v-1.255z"
          transform="translate(-128.151)"
        ></path>
      </g>
      <g id="eyes" transform="translate(-128.151)">
        <path
          id="eye-right"
          fill="#61534B"
          fillRule="nonzero"
          d="M165.654 56.376a2.314 2.314 0 10-4.629 0v.927a2.314 2.314 0 004.629 0v-.927z"
        ></path>
        <path
          id="eye-left"
          fill="#61534B"
          fillRule="nonzero"
          d="M188.163 56.376a2.314 2.314 0 10-4.629 0v.926a2.314 2.314 0 104.629 0v-.926z"
        ></path>
      </g>
      <g id="faces" transform="translate(-128.151)">
        {(getFacesMales() as any)[face]}
      </g>
      <g id="hairs" transform="matrix(.98169 0 0 1.01033 .365 -.639)">
        {(getHairMale(colorsHair) as any)[hair]}
      </g>
      <g id="beards" transform="matrix(.9809 0 0 1 .397 0)">
        {(getBeardMale(colorsHair) as any)[beard]}
      </g>
    </g>
  );

  const headFemale: JSX.Element = (
    <g transform={(transformHeadFemale as any)[skin]}>
      <g id="neck">
        <path
          id="long-neck"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M68.298 77.641c8.142 0 14.698 9.865 14.698 22.12v9.075c0 12.255-5.889 12.611-14.031 12.582-8.565-.03-15.365-.327-15.365-12.582v-9.075c0-12.255 6.555-22.12 14.698-22.12z"
        ></path>
        <path
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M66.083 79.298h4.428c5.098 0 9.202 5.738 9.202 12.866v5.278c0 7.128-4.104 12.866-9.202 12.866h-4.428c-5.098 0-9.202-5.738-9.202-12.866v-5.278c0-7.128 4.104-12.866 9.202-12.866z"
        ></path>
        <path
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M52.07 116.33c-.939 0-1.695.756-1.695 1.695a1.69 1.69 0 001.695 1.694h9.424l2.705 1.972c.761.555 1.82.389 2.375-.372a1.696 1.696 0 00-.372-2.375l-2.801-2.042c-.029-.021-.061-.033-.091-.052a1.682 1.682 0 00-1.219-.52H52.07zm22.04 0c-.481 0-.912.2-1.219.52-.03.019-.062.031-.091.052l-2.801 2.042a1.696 1.696 0 00-.372 2.375 1.695 1.695 0 002.374.372l2.706-1.972h9.424a1.69 1.69 0 001.695-1.694c0-.939-.756-1.695-1.695-1.695H74.11z"
          transform="matrix(.73039 0 0 .70961 18.558 33.389)"
        ></path>
      </g>
      <g id="ears" fillRule="nonzero">
        <path
          fill={colorsSkin.light}
          d="M45.78 67.241h45.951a9.492 9.492 0 019.512 9.513 9.49 9.49 0 01-9.512 9.512H45.78a9.49 9.49 0 01-9.512-9.512 9.492 9.492 0 019.512-9.513z"
        ></path>
        <path
          fill={colorsSkin.strong}
          d="M46.114 69.66h45.281a7.079 7.079 0 017.094 7.094 7.079 7.079 0 01-7.094 7.094H46.114a7.079 7.079 0 01-7.094-7.094 7.079 7.079 0 017.094-7.094z"
        ></path>
      </g>
      <path
        id="chin"
        fill={colorsSkin.strong}
        fillRule="nonzero"
        d="M66.431 100.002h2.974c.239 0 .431.168.431.376v.222c0 .208-.192.376-.431.376h-2.974c-.239 0-.431-.168-.431-.376v-.222c0-.208.192-.376.431-.376z"
      ></path>
      {gender === "female" && hair === "hair2" && (
        <path
          id="hair2-part2"
          fill={colorsHair.light}
          fillRule="nonzero"
          d="M29.98.931a6.944 6.944 0 014.436 0l3.056 1.029a6.935 6.935 0 002.059.362l3.246.074a6.949 6.949 0 014.366 1.674l1.903 1.633A6.941 6.941 0 0051.037 6.9l2.34.916a6.947 6.947 0 013.835 3.694l.571 1.31a6.96 6.96 0 001.557 2.236l.978.94a6.948 6.948 0 012.105 5.661l-.061.648a6.95 6.95 0 00.38 3.005l.21.582a6.949 6.949 0 01-.742 6.187l-.483.73a6.958 6.958 0 00-1.063 2.713l-.152.937a6.949 6.949 0 01-3.156 4.76l-1.646 1.036a6.958 6.958 0 00-1.849 1.702l-1.191 1.582a6.951 6.951 0 01-4.203 2.637l-2.91.576a6.924 6.924 0 00-2.045.752l-2.564 1.435a6.951 6.951 0 01-4.423.808l-3.296-.494a6.912 6.912 0 00-2.061 0l-3.296.494a6.951 6.951 0 01-4.423-.808l-2.565-1.435a6.924 6.924 0 00-2.045-.752l-2.909-.576a6.951 6.951 0 01-4.203-2.637l-1.191-1.582a6.947 6.947 0 00-1.85-1.702l-1.645-1.036a6.946 6.946 0 01-3.156-4.76l-.153-.937a6.94 6.94 0 00-1.062-2.713l-.483-.73a6.946 6.946 0 01-.742-6.187l.209-.582a6.965 6.965 0 00.381-3.005l-.061-.648a6.944 6.944 0 012.104-5.661l.979-.94a6.942 6.942 0 001.556-2.236l.571-1.31a6.946 6.946 0 013.836-3.694l2.34-.916a6.941 6.941 0 001.991-1.197l1.903-1.633a6.947 6.947 0 014.366-1.674l3.246-.074a6.941 6.941 0 002.059-.362L29.98.931z"
          transform="matrix(1.28437 0 0 1.32933 27.4 23.995)"
        ></path>
      )}
      {gender === "female" && hair === "hair1" && (
        <path
          id="hair1-part2"
          fill={colorsHair.light}
          fillRule="nonzero"
          d="M16.436 54.231l.124.86a14.401 14.401 0 002.362 6.059 14.396 14.396 0 012.512 8.124v2.321a21.88 21.88 0 01-.51 4.696l-1.142 5.205a3.76 3.76 0 00-.086.799v3.479c0 2.452-3.118 3.498-4.597 1.542a2.537 2.537 0 01-.439-.912l-.471-1.85a12.019 12.019 0 01-.131-5.356l.405-1.994a16.19 16.19 0 00.324-3.227V71.54c0-1.773-.55-3.501-1.575-4.948a8.562 8.562 0 01-1.513-3.92l-.028-.227a9.459 9.459 0 00-1.076-3.374l-1.059-1.954a14.97 14.97 0 00-1.343-2.053l-1.059-1.36a9.903 9.903 0 01-2.089-6.084 9.91 9.91 0 00-.932-4.195l-1.265-2.704a3.718 3.718 0 013.368-5.293h5.239l1.156 2.556a5.398 5.398 0 01.166 4.044 5.404 5.404 0 00.357 4.424l1.763 3.197a13.508 13.508 0 011.539 4.582z"
          transform="matrix(1.40091 0 0 1.43833 35.31 36.54)"
        ></path>
      )}
      <path
        id="head"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M63.59 40.504h10.329c8.585 0 22.163 12.121 22.163 24.041v16.913c0 11.92-12.726 24.966-21.843 24.966H63.271c-9.117 0-21.843-13.046-21.843-24.966V64.545c0-11.92 13.577-24.041 22.162-24.041z"
      ></path>

      <g id="hair">
        {gender === "female" && hair === "hair3" && (
          <g id="hair3" fillRule="nonzero">
            <path
              fill={colorsHair.light}
              d="M64.388 37.762A26.889 26.889 0 0037.44 64.71a26.878 26.878 0 004.7 15.243V68.5c0-4.27 2.126-8.568 5.209-12.188l11.695-.02a8.49 8.49 0 008.462-9.315h2.504a8.49 8.49 0 008.462 9.315l11.722.02c3.083 3.62 5.209 7.918 5.209 12.188v11.453a26.879 26.879 0 004.701-15.243 26.89 26.89 0 00-26.949-26.948h-8.767z"
            ></path>
            <path
              id="hair1"
              fill={colorsHair.light}
              d="M76.945 31.724H60.559a7.581 7.581 0 00-7.582 7.582v3.858a7.582 7.582 0 007.582 7.583h16.386a7.583 7.583 0 007.582-7.583v-3.858a7.582 7.582 0 00-7.582-7.582z"
            ></path>
          </g>
        )}
        {gender === "female" && hair === "hair1" && (
          <g id="hair11" transform="matrix(1.40091 0 0 1.43833 35.31 36.54)">
            <path
              id="hair1-part1"
              fill={colorsHair.light}
              fillRule="nonzero"
              d="M8.001 4.707C12.675 2.411 19.472 1.163 22.332.9c.618-.057 1.23.005 1.84.12l8.72 1.642a10 10 0 015.643 3.205l4.361 4.935a9.996 9.996 0 012.508 6.622v2.777c0 .399.096.792.28 1.147 1.325 2.55-2.015 4.941-4.003 2.865l-4.001-4.18a3.309 3.309 0 00-1.735-.956l-12.954-2.63a9.994 9.994 0 00-2.004-.2l-6.747.01c-1.456.002-2.874.572-3.74 1.742a27.591 27.591 0 00-2.899 4.988c-.858 1.922-3.774 3.068-5.458 1.806a2.492 2.492 0 01-.904-2.675c.065-.224.098-.456.098-.69l-.001-2.092c-.001-.73.618-3.202.813-3.905 1.104-3.972 2.152-8.906 5.852-10.724z"
            ></path>
          </g>
        )}
        {gender === "female" && hair === "hair2" && (
          <g transform="matrix(1.34734 0 0 .9864 26.107 32.802)">
            <g id="hair2">
              <path
                id="hair2-part1"
                fill={colorsHair.light}
                d="M33.796 24.197a5.867 5.867 0 00-4.397 0l-1.307.529a5.873 5.873 0 01-2.914.384l-2.075-.255a5.067 5.067 0 00-4.142 1.387 5.072 5.072 0 01-2.986 1.397l-2.391.255a2.514 2.514 0 00-2.212 2.084l-.001.003V16.278c13.409-14.469 26.928-14.816 40.565 0v14.366l-.112-.666a2.515 2.515 0 00-2.213-2.084l-2.391-.255a5.072 5.072 0 01-2.986-1.397 5.066 5.066 0 00-4.141-1.387l-2.076.255a5.873 5.873 0 01-2.914-.384l-1.307-.529z"
              ></path>
            </g>
          </g>
        )}
      </g>

      <g id="nose" fill={colorsSkin.strong} fillRule="nonzero">
        <path d="M68.752 73.22c1.823 0 3.291 1.552 3.291 3.479v6.141c0 1.927-1.468 3.479-3.291 3.479s-3.291-1.552-3.291-3.479v-6.141c0-1.927 1.468-3.479 3.291-3.479z"></path>
        <path d="M74.237 82.373c0 1.536-1.3 2.771-2.914 2.771h-5.14c-1.614 0-2.913-1.235-2.913-2.771 0-1.535 1.299-2.77 2.913-2.77h5.14c1.614 0 2.914 1.235 2.914 2.77z"></path>
      </g>
      <g id="faces">{(getFacesFemale(colorsSkin) as any)[face]}</g>
      <g id="eyes" fill="#61534B" fillRule="nonzero">
        <path d="M56.866 70.773h1.211c1.1 0 1.985.886 1.985 1.985v2.795a1.98 1.98 0 01-1.985 1.984h-1.211a1.98 1.98 0 01-1.985-1.984v-2.795a1.98 1.98 0 011.985-1.985zM80.638 70.773h-1.211a1.98 1.98 0 00-1.985 1.985v2.795a1.98 1.98 0 001.985 1.984h1.211a1.98 1.98 0 001.985-1.984v-2.795a1.98 1.98 0 00-1.985-1.985z"></path>
      </g>
    </g>
  );

  return <g>{gender === "male" ? headMale : headFemale}</g>;
};

import { IColorSkin } from "../../variablesAvatar/VariableAvatar";

interface IFace {
  neutral: React.SVGProps<SVGGElement>;
  sad: React.SVGProps<SVGGElement>;
  angry: React.SVGProps<SVGGElement>;
  smiling: React.SVGProps<SVGGElement>;
}

export const getFacesFemale = (colorsSkin: IColorSkin): IFace => ({
  neutral: (
    <g id="neutral">
      <path
        fill={colorsSkin.strong}
        fillRule="nonzero"
        d="M60.456 89.137a1.63 1.63 0 00-1.018.341c-.271.218-.422.515-.422.824v.877c0 1.731-.379 4.503.71 5.713 1.09 1.21 3.733.879 5.618.879h6.687c1.885 0 4.321.639 5.411-.571 1.09-1.21.918-4.29.918-6.021v-.877c0-.309-.152-.606-.422-.824a1.633 1.633 0 00-1.019-.341H60.456z"
        transform="matrix(1 0 0 .35006 0 61.546)"
      ></path>
      <path
        fill="#4D4D4D"
        fillRule="nonzero"
        d="M52.511 65.683l9.257.755a1.82 1.82 0 011.669 1.965 1.82 1.82 0 01-1.965 1.669l-9.258-.755a1.82 1.82 0 01-1.669-1.965 1.82 1.82 0 011.966-1.669zM84.993 65.683l-9.257.755a1.82 1.82 0 00-1.669 1.965 1.82 1.82 0 001.965 1.669l9.258-.755a1.82 1.82 0 001.669-1.965 1.82 1.82 0 00-1.966-1.669z"
        transform="matrix(1 0 0 .35006 0 61.546) matrix(1 0 0 2.85666 0 -175.815)"
      ></path>
    </g>
  ),
  sad: (
    <g id="sad">
      <path
        fill="#fff"
        d="M11.025 51.565c-4.492 0-8.139 3.828-8.139 8.543s3.647 8.543 8.139 8.543c-1.348-1.993-2.442-5.135-2.442-8.543 0-3.408 1.094-6.55 2.442-8.543z"
        transform="matrix(0 .75 -1.06216 0 132.599 88.956)"
      ></path>
      <path
        fill="#4F4F4F"
        d="M20.028 47.89c-1.431 0-2.593 1.639-2.593 3.658 0 2.02 1.162 3.659 2.593 3.659-.43-.854-.778-2.199-.778-3.659 0-1.459.348-2.805.778-3.658z"
        transform="matrix(-.26512 -1.71029 1.70626 -.2645 -26.154 112.454)"
      ></path>
      <path
        fill="#4F4F4F"
        d="M20.028 47.89c-1.431 0-2.593 1.639-2.593 3.658 0 2.02 1.162 3.659 2.593 3.659-.43-.854-.778-2.199-.778-3.659 0-1.459.348-2.805.778-3.658z"
        transform="matrix(.26512 -1.71029 -1.70626 -.2645 162.829 112.454)"
      ></path>
    </g>
  ),
  angry: (
    <g id="angry" fillRule="nonzero">
      <path
        id="smile"
        fill="#fff"
        d="M60.456 89.137a1.63 1.63 0 00-1.018.341c-.271.218-.422.515-.422.824v.877c0 1.731.616 3.317 1.705 4.526a6.242 6.242 0 004.623 2.066h6.687a6.24 6.24 0 004.623-2.066c1.09-1.209 1.706-2.795 1.706-4.526v-.877c0-.309-.152-.606-.422-.824a1.633 1.633 0 00-1.019-.341H60.456z"
      ></path>
      <path
        fill="#4D4D4D"
        d="M52.511 65.683l9.257.755a1.82 1.82 0 011.669 1.965 1.82 1.82 0 01-1.965 1.669l-9.258-.755a1.82 1.82 0 01-1.669-1.965 1.82 1.82 0 011.966-1.669zM84.993 65.683l-9.257.755a1.82 1.82 0 00-1.669 1.965 1.82 1.82 0 001.965 1.669l9.258-.755a1.82 1.82 0 001.669-1.965 1.82 1.82 0 00-1.966-1.669z"
      ></path>
    </g>
  ),
  smiling: (
    <g id="smiling" fillRule="nonzero">
      <path
        id="smile"
        fill="#fff"
        d="M60.456 89.137a1.63 1.63 0 00-1.018.341c-.271.218-.422.515-.422.824v.877c0 1.731.616 3.317 1.705 4.526a6.242 6.242 0 004.623 2.066h6.687a6.24 6.24 0 004.623-2.066c1.09-1.209 1.706-2.795 1.706-4.526v-.877c0-.309-.152-.606-.422-.824a1.633 1.633 0 00-1.019-.341H60.456z"
      ></path>
      <path
        fill="#4D4D4D"
        d="M52.511 65.683l9.257.755a1.82 1.82 0 011.669 1.965 1.82 1.82 0 01-1.965 1.669l-9.258-.755a1.82 1.82 0 01-1.669-1.965 1.82 1.82 0 011.966-1.669z"
      ></path>
      <path
        fill="#4D4D4D"
        d="M49.378 67.324l1.821-1.209a1.597 1.597 0 012.218.448c.49.739.29 1.728-.448 2.218l-1.822 1.209c-.738.49-1.727.29-2.217-.448a1.596 1.596 0 01.448-2.218zM84.993 65.683l-9.257.755a1.82 1.82 0 00-1.669 1.965 1.82 1.82 0 001.965 1.669l9.258-.755a1.82 1.82 0 001.669-1.965 1.82 1.82 0 00-1.966-1.669z"
      ></path>
      <path
        fill="#4D4D4D"
        d="M88.126 67.324l-1.821-1.209a1.597 1.597 0 00-2.218.448c-.49.739-.29 1.728.448 2.218l1.822 1.209c.738.49 1.727.29 2.217-.448a1.596 1.596 0 00-.448-2.218z"
      ></path>
    </g>
  ),
});

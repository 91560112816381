import { getSpecificColors } from "../../../../datas/Colors";
import { ITypesSkin } from "../../Avatar";

export const getClothFemale = (mainColor: number): ITypesSkin => ({
  BM: (
    <g id="clothes" transform="matrix(.9544 0 0 1 7.007 -12.034)">
      <g id="tshirt1" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H92.269c-.315 6.067-5.841 10.888-16.627 10.888H62.506c-12.648 0-18.126-4.821-18.44-10.888H29.609z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
        ></path>
      </g>
      <g id="shorts1" fillRule="nonzero">
        <path
          fill="#4F4F4F"
          d="M39.176 189.945c1.616 22.732 14.029 40.327 28.92 40.327 15.086 0 28.383-17.167 29.742-40.327H39.176z"
        ></path>
        <path
          fill="#4B4B4B"
          d="M97.865 189.941L69.117 194.4l-.242 35.777 2.698 15.679h32.945c.202-29.005-.135-38.803-6.653-55.915zM39.192 189.909l29.429 4.475v35.79l-2.745 15.679H33.007c-.209-29.102-.523-38.776 6.185-55.944z"
        ></path>
      </g>
      <g id="shoes1">
        <path
          fill="#fff"
          d="M82.571 304.089h18.483l.754 7.189-19.352 1.432.115-8.621z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0816 0 0 1.17288 -15.68 -53.425) matrix(-.95423 0 0 1.56842 248.505 -181.439)"
        ></path>
        <path
          fill="#fff"
          d="M82.571 304.089h18.386l.755 6.98-19.256 1.641.115-8.621z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0816 0 0 1.17288 -15.68 -53.425) matrix(.96852 0 0 1.56842 108.463 -181.439)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.99.237-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289zM161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c.064-3.359-1.289-3.427-2.879-3.427z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
        ></path>
        <g>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
            transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
          ></path>
        </g>
      </g>
    </g>
  ),
  EnM: (
    <g id="clothes" transform="matrix(1.03227 0 0 1 1.704 -12.034)">
      <g id="tshirt" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H92.269c-.315 6.067-5.841 10.888-16.627 10.888H62.506c-12.648 0-18.126-4.821-18.44-10.888H29.609z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
        ></path>
      </g>
      <g id="shorts" fillRule="nonzero">
        <path
          fill="#4F4F4F"
          d="M39.176 189.945c1.616 22.732 14.029 40.327 28.92 40.327 15.086 0 28.383-17.167 29.742-40.327H39.176z"
        ></path>
        <path
          fill="#4B4B4B"
          d="M97.865 189.941L69.117 194.4l-.242 35.777 2.698 15.679h32.945c.202-29.005-.135-38.803-6.653-55.915zM39.192 189.909l29.429 4.475v35.79l-2.745 15.679H33.007c-.209-29.102-.523-38.776 6.185-55.944z"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          d="M82.571 304.089h18.483l.754 7.189-19.352 1.432.115-8.621z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1 0 0 1.17288 0 -53.425) matrix(-1.02946 0 0 1.56842 252.853 -181.439)"
        ></path>
        <path
          fill="#fff"
          d="M82.571 304.089h18.386l.755 6.98-19.256 1.641.115-8.621z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1 0 0 1.17288 0 -53.425) matrix(1.02946 0 0 1.56842 103.175 -181.439)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.947-.034-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289zM161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c-.046-3.63-1.289-3.427-2.879-3.427z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
        ></path>
        <g>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
            transform="matrix(.94102 0 0 1 -98.74 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
          ></path>
        </g>
      </g>
    </g>
  ),
  EcM: (
    <g id="clothes" transform="translate(-94.529 -9.518) scale(.97017)">
      <g id="shorts" fillRule="nonzero">
        <path
          fill="#4F4F4F"
          d="M145.416 194.389c1.466 23.263 12.725 41.27 26.232 41.27 13.683 0 25.744-17.569 26.977-41.27h-53.209z"
        ></path>
        <path
          fill="#4B4B4B"
          d="M198.649 194.385l-26.076 4.563-.219 36.614 2.447 16.046h29.882c.183-29.684-.122-39.711-6.034-57.223zM145.43 194.352l26.693 4.579v36.627l-2.489 16.046H139.82c-.189-29.783-.474-39.683 5.61-57.252z"
        ></path>
      </g>
      <g id="t-shirt" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M185.888 137.83h-26.597c-6.732 0-21.42-10.193-21.42 0l2.246 9.197c0 13.259 1.623 20.38 12.628 20.38h37.689c9.789 0 12.19-5.387 12.19-18.455l4.205-10.598c0-10.193-14.209-.524-20.941-.524z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M136.738 118.279c-8.116 0-12.652 7.361-12.652 12.98 0 9.465 3.752 13.363 14.823 13.363h65.127c11.35 0 15.509-2.646 15.509-13.352 0-5.62-4.844-12.991-12.832-12.991h-13.14c-.286 6.209-5.298 11.143-15.081 11.143h-11.915c-11.472 0-16.441-4.934-16.726-11.143h-13.113z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M193.025 118.279a.871.871 0 00-.501.141c-.135.093-.215.222-.219.358-.335 5.646-6.639 10.107-14.448 10.107h-12.195c-7.808 0-14.112-4.461-14.446-10.107a.434.434 0 00-.065-.196.578.578 0 00-.162-.161.823.823 0 00-.233-.104.944.944 0 00-.537.018.708.708 0 00-.221.118.543.543 0 00-.142.171.395.395 0 00-.041.198c.367 6.181 7.307 11.088 15.847 11.088h12.195c8.541 0 15.482-4.907 15.848-11.088a.388.388 0 00-.04-.202.509.509 0 00-.143-.174.727.727 0 00-.226-.119.908.908 0 00-.271-.048z"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M164.125 317.063l-18.66-.041c-.847 0-.981 12.634-.981 12.634l.85 7.823c0 1.156.686 2.091 1.533 2.091h15.928c.846 0 1.533-.935 1.533-2.091-.402-.049.34-20.453-.203-20.416z"
          transform="matrix(.8572 0 0 1 22.778 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M156.364 323.517h-5.044c-1.198 0-2.17 1.325-2.17 2.959v2.656c0 1.635.972 2.961 2.17 2.961h5.044c1.199 0 2.169-1.326 2.169-2.961v-2.656c0-1.634-.97-2.959-2.169-2.959z"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M157.741 328.077h-7.436c-4.287 0-7.764 4.742-7.764 10.592 0 5.85 3.477 10.591 7.764 10.591h7.436c4.288 0 7.763-4.741 7.763-10.591s-3.475-10.592-7.763-10.592z"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M163.378 337.699h-18.54c-1.477 0-2.715-.108-2.674 3.507v4.587c0 1.939 1.197 3.509 2.674 3.509h18.54c1.478 0 2.675-1.57 2.675-3.509v-4.587c0-1.936-1.197-3.507-2.675-3.507z"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M164.537 344.864h-21.028c-.535 0-.968.591-.968 1.319v1.726c0 .729.433 1.32.968 1.32h21.028c.534 0 .967-.591.967-1.32v-1.726c0-.728-.433-1.319-.967-1.319zM156.894 328.609h-5.896c-.308 0-.557.34-.557.76 0 .418.249.758.557.758h5.896c.306 0 .556-.34.556-.758 0-.42-.25-.76-.556-.76z"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M158.898 333.495h-2.833c-.998 0-1.809 1.104-1.809 2.469 0 1.363.811 2.468 1.809 2.468h2.833c1 0 1.809-1.105 1.809-2.468 0-1.365-.809-2.469-1.809-2.469z"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M156.894 331.038h-5.896c-.308 0-.557.34-.557.758 0 .419.249.759.557.759h5.896c.306 0 .556-.34.556-.759 0-.418-.25-.758-.556-.758z"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M198.802 317.022h-19.545c-.895 0-.23 20.456-.23 20.456 0 1.156.727 2.092 1.622 2.092h16.841c.894 0 1.62-.936 1.62-2.092l.716-7.771c-.305.289-.461-12.371-1.024-12.685z"
          transform="matrix(.81455 0 0 1 35.046 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M192.728 323.547h-4.98c-1.183 0-2.143 1.326-2.143 2.96v2.656c0 1.635.96 2.96 2.143 2.96h4.98c1.183 0 2.142-1.325 2.142-2.96v-2.656c0-1.634-.959-2.96-2.142-2.96z"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M194.034 328.077h-7.341c-4.233 0-7.666 4.742-7.666 10.592 0 5.85 3.433 10.591 7.666 10.591h7.341c4.233 0 7.975-4.657 7.975-10.507 0-5.851-3.742-10.676-7.975-10.676z"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M199.654 337.699h-18.305c-1.457 0-2.64 1.571-2.64 3.507v4.587c0 1.939 1.183 3.509 2.64 3.509h18.305c1.458 0 2.64-1.57 2.64-3.509v-4.587c-.021-3.884-1.182-3.507-2.64-3.507z"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M200.744 344.864h-20.761c-.527 0-.956.591-.956 1.319v1.726c0 .729.429 1.32.956 1.32h20.761c.528 0 .955-.591.955-1.32v-1.726c0-.728-.427-1.319-.955-1.319zM194.108 328.609h-5.822c-.304 0-.55.34-.55.76 0 .418.246.758.55.758h5.822c.302 0 .549-.34.549-.758 0-.42-.247-.76-.549-.76z"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M196.084 333.524h-2.796c-.986 0-1.786 1.106-1.786 2.469 0 1.363.8 2.468 1.786 2.468h2.796c.987 0 1.786-1.105 1.786-2.468s-.799-2.469-1.786-2.469z"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M194.108 331.066h-5.822c-.304 0-.55.341-.55.759 0 .419.246.759.55.759h5.822c.302 0 .549-.34.549-.759 0-.418-.247-.759-.549-.759z"
        ></path>
      </g>
    </g>
  ),
  MEc: (
    <g id="clothes" transform="matrix(.8863 0 0 1 11.644 -9.554)">
      <g id="tshirt" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H92.269c-.315 6.067-5.841 10.888-16.627 10.888H62.506c-12.648 0-18.126-4.821-18.44-10.888H29.609z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
      </g>
      <g id="shorts" fillRule="nonzero">
        <path
          fill="#4F4F4F"
          d="M39.176 189.945c1.616 22.732 14.029 40.327 28.92 40.327 15.086 0 28.383-17.167 29.742-40.327H39.176z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
        <path
          fill="#4B4B4B"
          d="M97.865 189.941L69.117 194.4l-.242 35.777 2.698 15.679h32.945c.202-29.005-.135-38.803-6.653-55.915zM39.192 189.909l29.429 4.475v35.79l-2.745 15.679H33.007c-.209-29.102-.523-38.776 6.185-55.944z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.00652 0 0 1 -1.476 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.12667 0 0 1 -21.331 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(.98732 0 0 1 9.545 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.12671 0 0 1 -18.057 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.77453 0 0 1 -69.222 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
        ></path>
      </g>
    </g>
  ),
  BEc: (
    <g id="clothes" transform="matrix(.8863 0 0 1 11.144 -6.884)">
      <g id="tshirt" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H92.269c-.315 6.067-5.841 10.888-16.627 10.888H62.506c-12.648 0-18.126-4.821-18.44-10.888H29.609z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
      </g>
      <g id="shorts" fillRule="nonzero">
        <path
          fill="#4F4F4F"
          d="M40.407 189.941c1.616 22.732 12.798 40.331 27.689 40.331 15.086 0 29.042-16.927 30.401-40.087l-58.09-.244z"
          transform="matrix(.82307 0 0 1 10.92 0)"
        ></path>
        <path
          fill="#4B4B4B"
          d="M98.523 190.185L69.117 194.4l1.129 36.1c-.562 5.119-.342 10.237.555 15.356h30.717c1.621-29.005 3.523-38.559-2.995-55.671zM40.372 190.185l28.249 4.199v35.79c.214 5.319.102 10.601-.472 15.831l-30.717-.182c-.209-29.102-3.768-38.47 2.94-55.638z"
          transform="matrix(.82307 0 0 1 10.92 0)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.03217 0 0 1 -4.356 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.20057 0 0 1.01162 -31.042 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.24899 0 0 1.01162 -38.579 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -31.16 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.02073 0 0 1.00888 -2.355 -1.267)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.17289 0 0 1.00888 -33.464 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.22014 0 0 1.00888 -42.401 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
      </g>
    </g>
  ),
  EnEc: (
    <g id="clothes" transform="matrix(.8863 0 0 1 11.144 -6.847)">
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M40.407 189.941c-2.327 22.986 12.798 40.331 27.689 40.331 15.086 0 33.456-17.053 30.401-40.087l-58.09-.244z"
          transform="matrix(.83048 0 0 1 10.48 0) matrix(1.11777 0 0 1 -8.18 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M101.913 190.188L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l30.343-.149c1.62-29.005 2.383-37.045.933-55.668zM36.832 189.941l31.789 4.443v35.79a105.94 105.94 0 01-.532 15.831l-30.378-.179c-1.402-29.085-2.142-38.566-.879-55.885z"
          transform="matrix(.83048 0 0 1 10.48 0)"
        ></path>
      </g>
      <g id="tshirt" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
          transform="matrix(.82307 0 0 .94909 12.049 5.884)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H92.269c-.315 6.067-5.841 10.888-16.627 10.888H62.506c-12.648 0-18.126-4.821-18.44-10.888H29.609z"
          transform="matrix(.82307 0 0 .94909 12.049 5.884)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
          transform="matrix(.82307 0 0 .94909 12.049 5.884)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.03217 0 0 1 -4.356 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289zM161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -31.16 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.02073 0 0 1.00888 -2.355 -1.267)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
      </g>
    </g>
  ),
  EcEn: (
    <g id="clothes" transform="matrix(.8863 0 0 1 11.144 -11.606)">
      <g id="shorts1">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M39.689 189.941c-2.327 22.986 13.516 40.331 28.407 40.331 15.086 0 34.141-17.298 31.085-40.331H39.689z"
          transform="matrix(.95502 0 0 1 1.83 0) matrix(1.11777 0 0 1 -8.18 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M103.263 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.171-17.111-2.871-27.931z"
          transform="matrix(.95502 0 0 1 1.83 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M103.755 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.664-17.111-2.379-27.931z"
          transform="matrix(.95502 0 0 1 1.83 0) matrix(-1 0 0 1 139.256 0)"
        ></path>
      </g>
      <g id="tshirt" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
          transform="matrix(.82307 0 0 .89257 12.049 12.416)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H92.269c-.315 6.067-5.841 10.888-16.627 10.888H62.506c-12.648 0-18.126-4.821-18.44-10.888H29.609z"
          transform="matrix(.82307 0 0 .89257 12.049 12.416)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
          transform="matrix(.82307 0 0 .89257 12.049 12.416)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043l-.207 10.713.207 7.193c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.03217 0 0 1 -4.356 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-4.035-1.289-3.427-2.88-3.427z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289zM161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.13844 0 0 1.01162 -31.16 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044l.369-7.381-.369-10.524c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(.99444 0 0 1.00888 2.39 -1.267)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-3.016.258-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c.137-3.169-1.289-3.427-2.879-3.427z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.92739 0 0 1 -94.97 13.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
      </g>
    </g>
  ),
  BEn: (
    <g id="clothes" transform="matrix(.8863 0 0 1 11.144 -10.072)">
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M39.689 189.941c-2.327 22.986 13.516 40.331 28.407 40.331 15.086 0 34.141-17.298 31.085-40.331H39.689z"
          transform="matrix(1.05848 0 0 1 -5.37 0) matrix(1.11777 0 0 1 -8.18 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M103.263 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.171-17.111-2.871-27.931z"
          transform="matrix(1.05848 0 0 1 -5.37 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M103.755 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.664-17.111-2.379-27.931z"
          transform="matrix(1.05848 0 0 1 -5.37 0) matrix(-1 0 0 1 139.256 0)"
        ></path>
      </g>
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
          transform="matrix(.82307 0 0 .95636 12.049 5.044) matrix(1.0996 0 0 1 -5.728 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H95.462c-.315 6.067-8.251 11.343-19.82 11.365H62.506c-12.718.591-21.702-5.355-21.527-11.365h-11.37z"
          transform="matrix(.82307 0 0 .95636 12.049 5.044)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
          transform="matrix(.82307 0 0 .95636 12.049 5.044) matrix(1.1259 0 0 1 -8.59 0)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.03217 0 0 1 -4.356 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.1657 0 0 1.01162 -24.933 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.16188 0 0 1.01162 -24.367 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289zM161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.13844 0 0 1.01162 -31.16 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.02905 0 0 1.00888 -4.63 -1.267)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.13293 0 0 1.00888 -25.987 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.14188 0 0 1.00888 -27.835 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(1.00565 0 0 1 -108.605 13.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
      </g>
    </g>
  ),
  MEn: (
    <g id="clothes" transform="matrix(.8863 0 0 1 11.264 -10.066)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
          transform="matrix(1.16469 0 0 .94304 -11.215 9.318)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694l-13.698-.096c-.315 6.067-6.629 10.984-17.415 10.984H62.506c-12.648 0-18.126-4.821-18.44-10.888H29.609z"
          transform="matrix(1.16469 0 0 .94304 -11.215 9.318) matrix(.92274 0 0 1 4.65 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
          transform="matrix(1.16469 0 0 .94304 -11.215 9.318) matrix(.93221 0 0 1.0084 4.271 -1.066)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.0552 0 0 1 -.547 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.13844 0 0 1.01162 -13.26 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.18722 0 0 1.01162 -20.895 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.20539 0 0 1.01162 -24.172 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.13844 0 0 1.01162 -13.33 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.13844 0 0 1.01162 -13.26 -2.142)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.13844 0 0 1.01162 -23.457 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.13844 0 0 1.01162 -13.26 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.04982 0 0 1.00888 .315 -1.267)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.11442 0 0 1.00888 -14.259 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.16126 0 0 1.00888 -23.178 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.1857 0 0 1.00888 -27.809 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.15146 0 0 1.00888 -20.945 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.11442 0 0 1.00888 -13.154 -1.267)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.11442 0 0 1.00888 -13.154 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.11442 0 0 1.00888 -13.154 -1.267)"
        ></path>
      </g>
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M39.689 189.941c-2.327 22.986 13.516 40.331 28.407 40.331 15.086 0 34.141-17.298 31.085-40.331H39.689z"
          transform="matrix(1.05848 0 0 1 -5.37 0) matrix(1.11777 0 0 1 -8.18 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M103.263 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.171-17.111-2.871-27.931z"
          transform="matrix(1.05848 0 0 1 -5.37 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M103.755 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.664-17.111-2.379-27.931z"
          transform="matrix(1.05848 0 0 1 -5.37 0) matrix(-1 0 0 1 139.256 0)"
        ></path>
      </g>
    </g>
  ),
  "M-Ec": (
    <g id="clothes" transform="matrix(.8863 0 0 1 11.188 -9.554)">
      <g id="tshirt" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
          transform="matrix(.9343 0 0 1 4.474 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H92.269c-.315 6.067-5.841 10.888-16.627 10.888H62.506c-12.648 0-18.126-4.821-18.44-10.888H29.609z"
          transform="matrix(.9343 0 0 1 4.474 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
          transform="matrix(.9343 0 0 1 4.474 0)"
        ></path>
      </g>
      <g id="shorts" fillRule="nonzero">
        <path
          fill="#4F4F4F"
          d="M39.176 189.945c1.616 22.732 14.029 40.327 28.92 40.327 15.086 0 28.383-17.167 29.742-40.327H39.176z"
          transform="matrix(.9343 0 0 1 4.474 0)"
        ></path>
        <path
          fill="#4B4B4B"
          d="M97.865 189.941L69.117 194.4l-.242 35.777 2.698 15.679h32.945c.202-29.005-.135-38.803-6.653-55.915zM39.192 189.909l29.429 4.475v35.79l-2.745 15.679H33.007c-.209-29.102-.523-38.776 6.185-55.944z"
          transform="matrix(.9343 0 0 1 4.474 0)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(.9912 0 0 1 .576 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.11497 0 0 1 -19.865 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.16176 0 0 1 -27.121 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.0882 0 0 1 -15.287 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(.98364 0 0 1 10.413 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.07433 0 0 1 -8.002 1.333)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.10278 0 0 1 -13.128 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.13209 0 0 1 -18.428 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.8792 0 0 1 -87.778 15.687) matrix(1.07433 0 0 1 -6.937 1.333)"
        ></path>
      </g>
    </g>
  ),
  "En-Ec": (
    <g id="clothes" transform="matrix(.8863 0 0 1 11.144 -6.847)">
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M39.689 189.941c-2.327 22.986 13.516 40.331 28.407 40.331 15.086 0 34.141-17.298 31.085-40.331H39.689z"
          transform="matrix(.89631 0 0 1 5.908 0) matrix(1.11777 0 0 1 -8.18 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M102.683 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l30.343-.149c1.62-29.005 3.153-37.292 1.703-55.915zM36.184 189.941l32.437 4.443v35.79a105.94 105.94 0 01-.532 15.831l-30.378-.179c-1.402-29.085-2.789-38.566-1.527-55.885z"
          transform="matrix(.89631 0 0 1 5.908 0)"
        ></path>
      </g>
      <g id="tshirt" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H92.269c-.315 6.067-5.841 10.888-16.627 10.888H62.506c-12.648 0-18.126-4.821-18.44-10.888H29.609z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.03217 0 0 1 -4.356 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.19998 0 0 1.01162 -30.733 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.24837 0 0 1.01162 -38.266 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.13844 0 0 1.01162 -31.16 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.02073 0 0 1.00888 -2.355 -1.267)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.17816 0 0 1.00888 -34.576 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.22562 0 0 1.00888 -43.554 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.85565 0 0 1 -82.357 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
      </g>
    </g>
  ),
  "M-En": (
    <g id="clothes" transform="matrix(.8863 0 0 1 11.19 -9.517)">
      <g id="v" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
          transform="matrix(1.16469 0 0 1.03048 -11.428 -4.959)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H92.269c.624 4.979-6.7 11.338-16.702 11.365l-13.135.065c-12.286-.316-18.052-5.363-18.366-11.43H29.609z"
          transform="matrix(1.16469 0 0 1.03048 -11.428 -4.959)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
          transform="matrix(1.16469 0 0 1.03048 -11.428 -4.959)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.09101 0 0 1 -10.984 3.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.13844 0 0 1.01162 -18.374 -.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.23248 0 0 1.01162 -33.72 -.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.25239 0 0 1.01162 -37.322 -.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.20235 0 0 1.01162 -29.332 -.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.13844 0 0 1.01162 -18.374 -.142)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.13844 0 0 1.01162 -28.571 -.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.13844 0 0 1.01162 -18.374 -.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.04982 0 0 1.00888 6.25 .747)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.11442 0 0 1.00888 -8.323 .747)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.22986 0 0 1.00888 -29.627 .747)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.27022 0 0 1.00888 -37.1 .747)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.22791 0 0 1.00888 -28.81 .747)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.11442 0 0 1.00888 -7.219 .747)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.11442 0 0 1.00888 -7.219 .747)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.99699 0 0 1 -115.095 13.355) matrix(1.11442 0 0 1.00888 -7.219 .747)"
        ></path>
      </g>
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M39.176 189.945c1.616 22.732 14.029 40.327 28.92 40.327 15.086 0 28.383-17.167 29.742-40.327H39.176z"
          transform="matrix(1.16469 0 0 1 -11.215 0) matrix(1.05411 0 0 1.0001 -4.15 -.023)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M98.981 190.106L69.117 194.4l-.242 35.777 2.698 15.679h32.945c-.403-29.615-2.67-37.404-5.537-55.75zM37.145 189.941l31.476 4.443v35.79l-2.745 15.679H33.007c-.209-29.102 3.062-36.993 4.138-55.912z"
          transform="matrix(1.16469 0 0 1 -11.215 0)"
        ></path>
      </g>
    </g>
  ),
  C: (
    <g id="clothes" transform="matrix(.8863 0 0 1 11.144 -6.847)">
      <g id="tshirt" fillRule="nonzero">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M83.795 134.68H54.472c-7.422 0-23.615-9.96-23.615 0l2.476 8.987c0 12.956 1.789 19.914 13.922 19.914h41.552c10.792 0 13.44-5.264 13.44-18.033l4.635-10.356c0-9.96-15.665-.512-23.087-.512z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          d="M29.609 115.576c-8.948 0-13.949 7.192-13.949 12.683 0 9.249 4.137 13.058 16.342 13.058h71.802c12.514 0 17.099-2.586 17.099-13.047 0-5.492-5.341-12.694-14.148-12.694H92.269c-.315 6.067-5.841 10.888-16.627 10.888H62.506c-12.648 0-18.126-4.821-18.44-10.888H29.609z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          d="M91.664 115.576a1.05 1.05 0 00-.552.137c-.149.091-.237.217-.242.35-.369 5.517-7.319 9.876-15.928 9.876H61.497c-8.608 0-15.558-4.359-15.927-9.876a.383.383 0 00-.071-.191.605.605 0 00-.179-.158.95.95 0 00-.257-.101 1.142 1.142 0 00-.592.017.83.83 0 00-.243.116.526.526 0 00-.157.167.344.344 0 00-.045.193c.404 6.04 8.056 10.835 17.471 10.835h13.445c9.416 0 17.068-4.795 17.472-10.835a.34.34 0 00-.044-.197.506.506 0 00-.158-.17.842.842 0 00-.249-.117 1.132 1.132 0 00-.299-.046z"
          transform="matrix(.82307 0 0 1 12.049 0)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.03217 0 0 1 -4.356 1.333)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289zM161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -31.16 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.13844 0 0 1.01162 -20.963 -2.142)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.02073 0 0 1.00888 -2.355 -1.267)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -22.18 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.77453 0 0 1 -68.094 12.687) matrix(1.11442 0 0 1.00888 -21.076 -1.267)"
        ></path>
      </g>
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M39.583 189.84c1.616 22.732 13.622 40.432 28.513 40.432 15.086 0 29.944-16.927 31.303-40.087l-59.816-.345z"
          transform="matrix(.82307 0 0 1 10.92 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M99.399 169.542l-30.677 5.869s1.182 65.326 2.079 70.445h30.717c1.621-29.005-.152-60.198-2.119-76.314z"
          transform="matrix(.82307 0 0 1 10.92 0) matrix(1 0 0 .7295 0 66.505)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M39.583 189.84l29.038 4.544v35.79c.214 5.319.102 10.601-.472 15.831l-30.717-.182c-2.126-29.149.238-38.282 2.151-55.983z"
          transform="matrix(.82307 0 0 1 10.92 0)"
        ></path>
      </g>
    </g>
  ),
});

import { IColorSkin } from "../../variablesAvatar/VariableAvatar";

interface IFace {
  neutral: React.SVGProps<SVGGElement>;
  sad: React.SVGProps<SVGGElement>;
  angry: React.SVGProps<SVGGElement>;
  smiling: React.SVGProps<SVGGElement>;
}

export const getFacesMales = (): IFace => ({
  neutral: (
    <g id="neutral">
      <path
        fill="#fff"
        d="M182.172 75.746a.623.623 0 00-.623-.623h-13.478a.623.623 0 00-.624.623v1.246c0 .344.279.623.624.623h13.478a.623.623 0 00.623-.623v-1.246z"
      ></path>
      <path
        fill="#61534B"
        d="M190.872 51.869a.499.499 0 00-.499-.498h-6.98a.498.498 0 00-.498.498v.997c0 .276.223.499.498.499h6.98a.5.5 0 00.499-.499v-.997zM166.918 51.84a.499.499 0 00-.499-.498h-6.98a.499.499 0 00-.499.498v.998c0 .275.224.498.499.498h6.98a.499.499 0 00.499-.498v-.998z"
      ></path>
    </g>
  ),
  sad: (
    <g id="sad">
      <path
        fill="#4D4D4D"
        d="M183.615 48.093c-.588 1.366.66 3.022 2.787 3.698 2.127.675 4.331.115 4.92-1.251-1.076.124-2.636.007-4.173-.481-1.538-.488-2.811-1.271-3.534-1.966zM158.8 50.737c.609 1.372 2.824 1.912 4.945 1.204 2.121-.707 3.348-2.394 2.74-3.767-.715.71-1.979 1.516-3.512 2.027-1.533.511-3.094.648-4.173.536z"
      ></path>
      <path
        fill="#fff"
        d="M184.227 79.982c0-4.678-4.245-8.476-9.473-8.476-5.228 0-9.473 3.798-9.473 8.476 2.21-1.404 5.693-2.543 9.473-2.543 3.779 0 7.262 1.139 9.473 2.543z"
      ></path>
    </g>
  ),
  angry: (
    <g id="angry">
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M178.997 80.15c3.407 0 6.149-2.929 6.149-6.568v-1.063h-20.708v1.063c0 3.639 2.744 6.568 6.15 6.568h8.409z"
      ></path>
      <path
        fill="#4D4D4D"
        d="M190.661 51.02c-.081-.219-.36-.348-.622-.288l-6.666 1.539c-.263.061-.411.289-.33.508l.292.795c.08.22.359.348.622.288l6.666-1.539c.262-.061.41-.288.33-.508l-.292-.795zM166.652 52.804c.08-.219-.067-.447-.33-.507l-6.666-1.54c-.263-.06-.542.069-.622.288l-.292.795c-.081.22.067.447.33.508l6.666 1.539c.262.06.541-.068.622-.288l.292-.795z"
      ></path>
    </g>
  ),
  smiling: (
    <g id="smiling">
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M178.996 80.179c3.407 0 6.149-2.929 6.149-6.568v-1.063h-20.707v1.063c0 3.639 2.743 6.568 6.149 6.568h8.409z"
      ></path>
      <path
        fill="#61534B"
        d="M190.872 51.869a.499.499 0 00-.499-.498h-6.98a.498.498 0 00-.498.498v.997c0 .276.223.499.498.499h6.98a.5.5 0 00.499-.499v-.997zM166.918 51.84a.499.499 0 00-.499-.498h-6.98a.499.499 0 00-.499.498v.998c0 .275.224.498.499.498h6.98a.499.499 0 00.499-.498v-.998z"
      ></path>
    </g>
  ),
});

import { IColorHair } from "../../variablesAvatar/VariableAvatar";

interface IHair {
  none: React.SVGProps<SVGGElement>;
  short: React.SVGProps<SVGGElement>;
  medium: React.SVGProps<SVGGElement>;
  medium2: React.SVGProps<SVGGElement>;
  large: React.SVGProps<SVGGElement>;
  large2: React.SVGProps<SVGGElement>;
}

export const getHairMale = (colorsHair: IColorHair): IHair => ({
  none: <g></g>,
  short: (
    <g id="short-hair">
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M164.53 28.084c-9.124 0-16.469 7.551-16.469 16.934 0 .439.021.874.053 1.307-.032.22-.053.445-.053.675v10.373c0 2.227 1.53 4.071 3.573 4.489V48.324c0-9.69 7.588-17.492 17.013-17.492h13.31c9.424 0 17.013 7.802 17.013 17.492v13.555c2.1-.368 3.692-2.237 3.692-4.506V47c0-.23-.023-.455-.054-.675.032-.433.054-.868.054-1.307 0-9.383-7.346-16.934-16.47-16.934H164.53z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        d="M115.923 42.736c1.361-.683 2.077-1.457 2.077-2.245 0-2.479-6.945-4.491-15.5-4.491S87 38.012 87 40.491c0 .788.716 1.562 2.077 2.245h26.846z"
        transform="matrix(-1.22581 0 0 -1.48884 172.13 94.065)"
      ></path>
    </g>
  ),
  medium: (
    <g id="medium-hair">
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M164.53 28.084c-9.124 0-16.469 7.551-16.469 16.934 0 .439.021.874.053 1.307-.032.22-.053.445-.053.675v10.373c0 2.227 1.53 4.071 3.573 4.489V48.324c0-9.69 7.588-17.492 17.013-17.492h13.31c9.424 0 17.013 7.802 17.013 17.492v13.555c2.1-.368 3.692-2.237 3.692-4.506V47c0-.23-.023-.455-.054-.675.032-.433.054-.868.054-1.307 0-9.383-7.346-16.934-16.47-16.934H164.53z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M183.233 26.029h-14.838c-4.998 0-9.05 4.166-9.05 9.307 0 5.139 4.052 9.305 9.05 9.305h14.838c4.998 0 9.05-4.166 9.05-9.305 0-5.141-4.052-9.307-9.05-9.307z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M164.464 31.901c0-1.453-1.146-2.631-2.56-2.631-1.413 0-2.559 1.178-2.559 2.631v6.103c0 1.452 1.146 2.631 2.559 2.631 1.414 0 2.56-1.179 2.56-2.631v-6.103zM193.51 32.248c0-1.454-1.145-2.632-2.559-2.632-1.414 0-2.559 1.178-2.559 2.632v6.101c0 1.454 1.145 2.632 2.559 2.632 1.414 0 2.559-1.178 2.559-2.632v-6.101zM158.284 34.882c-.134-.958-.999-1.624-1.931-1.487-.933.138-1.581 1.028-1.447 1.987l.433 3.095c.133.959.998 1.625 1.931 1.487.933-.138 1.58-1.026 1.446-1.986l-.432-3.096zM194.339 35.721c.133-.959.998-1.624 1.931-1.487.933.138 1.58 1.027 1.446 1.987l-.432 3.095c-.134.959-.999 1.625-1.931 1.487-.933-.138-1.581-1.026-1.447-1.986l.433-3.096z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.light}
        fillRule="nonzero"
        d="M174.013 30.629h1.597c.942 0 1.706.786 1.706 1.754v6.408c0 .968-.764 1.754-1.706 1.754h-1.597c-.943 0-1.707-.786-1.707-1.754v-6.408c0-.968.764-1.754 1.707-1.754zM167.161 30.803h2.108c.738 0 1.335.615 1.335 1.373v6.788c0 .759-.597 1.374-1.335 1.374h-2.108c-.739 0-1.336-.615-1.336-1.374v-6.788c0-.758.597-1.373 1.336-1.373zM182.636 30.832h-2.109c-.737 0-1.336.615-1.336 1.373v6.789c0 .759.599 1.373 1.336 1.373h2.109c.737 0 1.335-.614 1.335-1.373v-6.789c0-.758-.598-1.373-1.335-1.373z"
        transform="translate(-128.151)"
      ></path>
    </g>
  ),
  medium2: (
    <g id="medium2-hair">
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M164.53 28.084c-9.124 0-16.469 7.551-16.469 16.934 0 .439.021.874.053 1.307-.032.22-.053.445-.053.675v10.373c0 2.227 1.53 4.071 3.573 4.489V48.324c0-9.69 7.588-17.492 17.013-17.492h13.31c9.424 0 17.013 7.802 17.013 17.492v13.555c2.1-.368 3.692-2.237 3.692-4.506V47c0-.23-.023-.455-.054-.675.032-.433.054-.868.054-1.307 0-9.383-7.346-16.934-16.47-16.934H164.53z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M165.65 31.203a20.475 20.475 0 00-20.52 20.519v9.681c0 3.442.843 6.678 2.329 9.521V59.385a18.071 18.071 0 0118.111-18.111h12.072a18.07 18.07 0 0118.11 18.111v11.539a20.486 20.486 0 002.329-9.521v-9.681a20.475 20.475 0 00-20.52-20.519H165.65z"
        transform="matrix(.99775 0 0 1 .047 0) matrix(1.01866 0 0 .98978 -127.538 -12.342)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M152.568 49.602c0-2.256-1.554-4.085-3.47-4.085-1.917 0-3.471 1.829-3.471 4.085v5.813c0 2.257 1.554 4.086 3.471 4.086 1.916 0 3.47-1.829 3.47-4.086v-5.813zM197.582 49.602c0-2.256-1.554-4.085-3.471-4.085-1.916 0-3.47 1.829-3.47 4.085v5.813c0 2.257 1.554 4.086 3.47 4.086 1.917 0 3.471-1.829 3.471-4.086v-5.813z"
        transform="matrix(.99775 0 0 1 .047 0) matrix(1.01866 0 0 .98978 -127.144 -12.235)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M181.094 31.501h-19.75c-5.882 0-10.651 4.769-10.651 10.651v5.025c0 5.882 4.769 10.651 10.651 10.651h19.75c5.882 0 10.651-4.769 10.651-10.651v-5.025c0-5.882-4.769-10.651-10.651-10.651z"
        transform="matrix(.99775 0 0 1 .047 0) matrix(1.01866 0 0 .98978 -127.144 -12.235)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M148.31 28.064a2.667 2.667 0 00-1.797.457 2.242 2.242 0 00-.938 1.47c-.041.251-.074.502-.099.752-.758 7.548 5.353 14.373 13.611 15.202l9.645.967c8.258.829 15.603-4.646 16.36-12.194.027-.269.04-.521.05-.752a2.242 2.242 0 00-.626-1.63 2.67 2.67 0 00-1.671-.807l-34.535-3.465z"
        transform="matrix(.99775 0 0 1 .047 0) matrix(1.01866 0 0 .98978 -127.144 -12.235)"
      ></path>
      <g>
        <path
          fill={colorsHair.light}
          fillRule="nonzero"
          d="M174.013 30.629h1.597c.942 0 1.706.786 1.706 1.754v6.408c0 .968-.764 1.754-1.706 1.754h-1.597c-.943 0-1.707-.786-1.707-1.754v-6.408c0-.968.764-1.754 1.707-1.754z"
          transform="translate(-127.689 -.808)"
        ></path>
      </g>
      <g>
        <path
          fill={colorsHair.light}
          fillRule="nonzero"
          d="M167.161 30.803h2.108c.738 0 1.335.615 1.335 1.373v6.788c0 .759-.597 1.374-1.335 1.374h-2.108c-.739 0-1.336-.615-1.336-1.374v-6.788c0-.758.597-1.373 1.336-1.373z"
          transform="translate(-127.689 -.808)"
        ></path>
      </g>
      <g>
        <path
          fill={colorsHair.light}
          fillRule="nonzero"
          d="M182.636 30.832h-2.109c-.737 0-1.336.615-1.336 1.373v6.789c0 .759.599 1.373 1.336 1.373h2.109c.737 0 1.335-.614 1.335-1.373v-6.789c0-.758-.598-1.373-1.335-1.373z"
          transform="translate(-127.689 -.808)"
        ></path>
      </g>
    </g>
  ),
  large: (
    <g id="large-hair">
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M164.531 28.084c-9.124 0-16.469 7.551-16.469 16.934 0 .439.021.874.053 1.307-.032.22-.053.445-.053.675v10.373c0 2.227 1.53 4.071 3.573 4.489V48.324c0-9.69 7.588-17.492 17.013-17.492h13.31c9.424 0 17.013 7.802 17.013 17.492v13.555c2.1-.368 3.692-2.237 3.692-4.506V47c0-.23-.023-.455-.054-.675.032-.433.054-.868.054-1.307 0-9.383-7.346-16.934-16.47-16.934h-21.662z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M183.234 26.029h-14.838c-4.998 0-9.05 4.166-9.05 9.307 0 5.139 4.052 9.305 9.05 9.305h14.838c4.998 0 9.05-4.166 9.05-9.305 0-5.141-4.052-9.307-9.05-9.307z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M164.465 31.901c0-1.453-1.146-2.631-2.56-2.631-1.413 0-2.559 1.178-2.559 2.631v6.103c0 1.452 1.146 2.631 2.559 2.631 1.414 0 2.56-1.179 2.56-2.631v-6.103zM193.511 32.248c0-1.454-1.145-2.631-2.559-2.631-1.414 0-2.559 1.177-2.559 2.631v6.102c0 1.454 1.145 2.631 2.559 2.631 1.414 0 2.559-1.177 2.559-2.631v-6.102zM158.286 34.883c-.134-.959-.999-1.625-1.931-1.487-.933.137-1.581 1.027-1.447 1.986l.433 3.095c.133.96.998 1.626 1.931 1.487.933-.137 1.58-1.026 1.446-1.985l-.432-3.096zM194.34 35.721c.133-.959.998-1.624 1.931-1.487.933.138 1.58 1.027 1.446 1.987l-.432 3.095c-.134.959-.999 1.625-1.931 1.487-.933-.138-1.581-1.026-1.447-1.986l.433-3.096z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.light}
        fillRule="nonzero"
        d="M174.014 30.629h1.597c.942 0 1.706.786 1.706 1.754v6.408c0 .968-.764 1.754-1.706 1.754h-1.597c-.943 0-1.707-.786-1.707-1.754v-6.408c0-.968.764-1.754 1.707-1.754zM167.161 30.803h2.109c.738 0 1.335.615 1.335 1.373v6.788c0 .759-.597 1.374-1.335 1.374h-2.109c-.738 0-1.335-.615-1.335-1.374v-6.788c0-.758.597-1.373 1.335-1.373zM182.638 30.833h-2.109c-.737 0-1.336.615-1.336 1.373v6.788c0 .759.599 1.373 1.336 1.373h2.109c.737 0 1.335-.614 1.335-1.373v-6.788c0-.758-.598-1.373-1.335-1.373z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M201.535 50.973a1.648 1.648 0 00-.607.064l-.222.067a1.623 1.623 0 00-1.153 1.599c-.07-.1-.154-.188-.242-.269v2.221a8.611 8.611 0 015.297 7.972 8.609 8.609 0 01-5.348 7.992 18.178 18.178 0 01-.662 3.751v1.242a2.072 2.072 0 00-.28-.352c-1.567 4.57-4.896 8.016-9.184 10.099v3.071c0 1.12.9 2.022 2.017 2.022h.931a2.015 2.015 0 002.017-2.022v-.545a2.01 2.01 0 001.862 1.244h.93a2.015 2.015 0 002.018-2.023v-.64c.356.571.983.952 1.706.952a2.015 2.015 0 002.017-2.022V74.034l.415 1.384a1.625 1.625 0 002.029 1.095 1.632 1.632 0 001.092-2.036l-2.213-7.387a1.62 1.62 0 001.683.481l.223-.068a1.628 1.628 0 001.091-2.034l-3.995-13.337a1.624 1.624 0 00-1.422-1.159zm-52.44.11a1.625 1.625 0 00-1.423 1.159l-3.995 13.337a1.63 1.63 0 001.092 2.034l.223.068a1.62 1.62 0 001.682-.481l-2.212 7.387a1.631 1.631 0 001.091 2.036c.865.26 1.77-.228 2.029-1.094l.084-.28v10.147c0 1.12.899 2.022 2.016 2.022.724 0 1.351-.381 1.707-.952v.64c0 1.121.899 2.023 2.017 2.023h.931a2.01 2.01 0 001.861-1.241v.542c0 1.12.9 2.022 2.018 2.022h.931a2.015 2.015 0 002.017-2.022v-3.071c-4.249-2.113-7.537-5.639-9.071-10.204a2.027 2.027 0 00-.394.457v-1.794a18.29 18.29 0 01-.512-3.199c-4.058-2.579-5.508-4.597-5.348-7.992.171-3.6 2.182-6.677 5.297-7.972v-1.914c-.019.024-.041.047-.059.072a1.626 1.626 0 00-1.154-1.599l-.222-.067a1.622 1.622 0 00-.606-.064zM193.51 32.248c0-1.454-1.145-2.632-2.559-2.632-1.414 0-2.559 1.178-2.559 2.632v6.101c0 1.454 1.145 2.632 2.559 2.632 1.414 0 2.559-1.178 2.559-2.632v-6.101zM158.284 34.882c-.134-.958-.999-1.624-1.931-1.487-.933.138-1.581 1.028-1.447 1.987l.433 3.095c.133.959.998 1.625 1.931 1.487.933-.138 1.58-1.026 1.446-1.986l-.432-3.096zM194.339 35.721c.133-.959.998-1.624 1.931-1.487.933.138 1.58 1.027 1.446 1.987l-.432 3.095c-.134.959-.999 1.625-1.931 1.487-.933-.138-1.581-1.026-1.447-1.986l.433-3.096z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.light}
        fillRule="nonzero"
        d="M174.013 30.629h1.597c.942 0 1.706.786 1.706 1.754v6.408c0 .968-.764 1.754-1.706 1.754h-1.597c-.943 0-1.707-.786-1.707-1.754v-6.408c0-.968.764-1.754 1.707-1.754zM167.161 30.803h2.108c.738 0 1.335.615 1.335 1.373v6.788c0 .759-.597 1.374-1.335 1.374h-2.108c-.739 0-1.336-.615-1.336-1.374v-6.788c0-.758.597-1.373 1.336-1.373zM182.636 30.832h-2.109c-.737 0-1.336.615-1.336 1.373v6.789c0 .759.599 1.373 1.336 1.373h2.109c.737 0 1.335-.614 1.335-1.373v-6.789c0-.758-.598-1.373-1.335-1.373z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M164.53 28.084c-9.124 0-16.469 7.551-16.469 16.934 0 .439.021.874.053 1.307-.032.22-.053.445-.053.675v10.373c0 2.227 1.53 4.071 3.573 4.489V48.324c0-9.69 7.588-17.492 17.013-17.492h13.31c9.424 0 17.013 7.802 17.013 17.492v13.555c2.1-.368 3.692-2.237 3.692-4.506V47c0-.23-.023-.455-.054-.675.032-.433.054-.868.054-1.307 0-9.383-7.346-16.934-16.47-16.934H164.53z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M183.233 26.029h-14.838c-4.998 0-9.05 4.166-9.05 9.307 0 5.139 4.052 9.305 9.05 9.305h14.838c4.998 0 9.05-4.166 9.05-9.305 0-5.141-4.052-9.307-9.05-9.307z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M164.464 31.901c0-1.453-1.146-2.631-2.56-2.631-1.413 0-2.559 1.178-2.559 2.631v6.103c0 1.452 1.146 2.631 2.559 2.631 1.414 0 2.56-1.179 2.56-2.631v-6.103z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.light}
        fillRule="nonzero"
        d="M167.161 30.803h2.108c.738 0 1.335.615 1.335 1.373v6.788c0 .759-.597 1.374-1.335 1.374h-2.108c-.739 0-1.336-.615-1.336-1.374v-6.788c0-.758.597-1.373 1.336-1.373zM182.636 30.832h-2.109c-.737 0-1.336.615-1.336 1.373v6.789c0 .759.599 1.373 1.336 1.373h2.109c.737 0 1.335-.614 1.335-1.373v-6.789c0-.758-.598-1.373-1.335-1.373zM174.013 30.629h1.597c.942 0 1.706.786 1.706 1.754v6.408c0 .968-.764 1.754-1.706 1.754h-1.597c-.943 0-1.707-.786-1.707-1.754v-6.408c0-.968.764-1.754 1.707-1.754z"
        transform="translate(-128.151)"
      ></path>
    </g>
  ),
  large2: (
    <g id="large2-hair">
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M201.535 50.973a1.648 1.648 0 00-.607.064l-.222.067a1.623 1.623 0 00-1.153 1.599c-.07-.1-.154-.188-.242-.269v2.221a8.611 8.611 0 015.297 7.972 8.609 8.609 0 01-5.348 7.992 18.178 18.178 0 01-.662 3.751v1.242a2.072 2.072 0 00-.28-.352c-1.567 4.57-4.896 8.016-9.184 10.099v3.071c0 1.12.9 2.022 2.017 2.022h.931a2.015 2.015 0 002.017-2.022v-.545a2.01 2.01 0 001.862 1.244h.93a2.015 2.015 0 002.018-2.023v-.64c.356.571.983.952 1.706.952a2.015 2.015 0 002.017-2.022V74.034l.415 1.384a1.625 1.625 0 002.029 1.095 1.632 1.632 0 001.092-2.036l-2.213-7.387a1.62 1.62 0 001.683.481l.223-.068a1.628 1.628 0 001.091-2.034l-3.995-13.337a1.624 1.624 0 00-1.422-1.159zm-52.44.11a1.625 1.625 0 00-1.423 1.159l-3.995 13.337a1.63 1.63 0 001.092 2.034l.223.068a1.62 1.62 0 001.682-.481l-2.212 7.387a1.631 1.631 0 001.091 2.036c.865.26 1.77-.228 2.029-1.094l.084-.28v10.147c0 1.12.899 2.022 2.016 2.022.724 0 1.351-.381 1.707-.952v.64c0 1.121.899 2.023 2.017 2.023h.931a2.01 2.01 0 001.861-1.241v.542c0 1.12.9 2.022 2.018 2.022h.931a2.015 2.015 0 002.017-2.022v-3.071c-4.249-2.113-7.537-5.639-9.071-10.204a2.027 2.027 0 00-.394.457v-1.794a18.29 18.29 0 01-.512-3.199c-4.058-2.579-5.508-4.597-5.348-7.992.171-3.6 2.182-6.677 5.297-7.972v-1.914c-.019.024-.041.047-.059.072a1.626 1.626 0 00-1.154-1.599l-.222-.067a1.622 1.622 0 00-.606-.064z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M164.53 28.084c-9.124 0-16.469 7.551-16.469 16.934 0 .439.021.874.053 1.307-.032.22-.053.445-.053.675v10.373c0 2.227 1.53 4.071 3.573 4.489V48.324c0-9.69 7.588-17.492 17.013-17.492h13.31c9.424 0 17.013 7.802 17.013 17.492v13.555c2.1-.368 3.692-2.237 3.692-4.506V47c0-.23-.023-.455-.054-.675.032-.433.054-.868.054-1.307 0-9.383-7.346-16.934-16.47-16.934H164.53z"
        transform="translate(-128.151)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M165.65 31.203a20.475 20.475 0 00-20.52 20.519v9.681c0 3.442.843 6.678 2.329 9.521V59.385a18.071 18.071 0 0118.111-18.111h12.072a18.07 18.07 0 0118.11 18.111v11.539a20.486 20.486 0 002.329-9.521v-9.681a20.475 20.475 0 00-20.52-20.519H165.65z"
        transform="matrix(.99775 0 0 1 .047 0) matrix(1.01866 0 0 .98978 -127.538 -12.342)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M152.568 49.602c0-2.256-1.554-4.085-3.47-4.085-1.917 0-3.471 1.829-3.471 4.085v5.813c0 2.257 1.554 4.086 3.471 4.086 1.916 0 3.47-1.829 3.47-4.086v-5.813zM197.582 49.602c0-2.256-1.554-4.085-3.471-4.085-1.916 0-3.47 1.829-3.47 4.085v5.813c0 2.257 1.554 4.086 3.47 4.086 1.917 0 3.471-1.829 3.471-4.086v-5.813z"
        transform="matrix(.99775 0 0 1 .047 0) matrix(1.01866 0 0 .98978 -127.144 -12.235)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M181.094 31.501h-19.75c-5.882 0-10.651 4.769-10.651 10.651v5.025c0 5.882 4.769 10.651 10.651 10.651h19.75c5.882 0 10.651-4.769 10.651-10.651v-5.025c0-5.882-4.769-10.651-10.651-10.651z"
        transform="matrix(.99775 0 0 1 .047 0) matrix(1.01866 0 0 .98978 -127.144 -12.235)"
      ></path>
      <path
        fill={colorsHair.medium}
        fillRule="nonzero"
        d="M148.31 28.064a2.667 2.667 0 00-1.797.457 2.242 2.242 0 00-.938 1.47c-.041.251-.074.502-.099.752-.758 7.548 5.353 14.373 13.611 15.202l9.645.967c8.258.829 15.603-4.646 16.36-12.194.027-.269.04-.521.05-.752a2.242 2.242 0 00-.626-1.63 2.67 2.67 0 00-1.671-.807l-34.535-3.465z"
        transform="matrix(.99775 0 0 1 .047 0) matrix(1.01866 0 0 .98978 -127.144 -12.235)"
      ></path>
      <g>
        <path
          fill={colorsHair.light}
          fillRule="nonzero"
          d="M174.013 30.629h1.597c.942 0 1.706.786 1.706 1.754v6.408c0 .968-.764 1.754-1.706 1.754h-1.597c-.943 0-1.707-.786-1.707-1.754v-6.408c0-.968.764-1.754 1.707-1.754z"
          transform="translate(-127.689 -.808)"
        ></path>
      </g>
      <g>
        <path
          fill={colorsHair.light}
          fillRule="nonzero"
          d="M167.161 30.803h2.108c.738 0 1.335.615 1.335 1.373v6.788c0 .759-.597 1.374-1.335 1.374h-2.108c-.739 0-1.336-.615-1.336-1.374v-6.788c0-.758.597-1.373 1.336-1.373z"
          transform="translate(-127.689 -.808)"
        ></path>
      </g>
      <g>
        <path
          fill={colorsHair.light}
          fillRule="nonzero"
          d="M182.636 30.832h-2.109c-.737 0-1.336.615-1.336 1.373v6.789c0 .759.599 1.373 1.336 1.373h2.109c.737 0 1.335-.614 1.335-1.373v-6.789c0-.758-.598-1.373-1.335-1.373z"
          transform="translate(-127.689 -.808)"
        ></path>
      </g>
    </g>
  ),
});

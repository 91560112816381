import { IColorSkin } from "../../variablesAvatar/VariableAvatar";

// Balanced Mesomorph
export const getBMSkin = (
  colorsSkin: IColorSkin
): React.SVGProps<SVGGElement> => (
  <g transform="translate(-104.838 -9.812)">
    <g id="arms" fillRule="nonzero">
      <g id="arm-left">
        <path
          id="bicep-left"
          fill={colorsSkin.light}
          d="M214.969 114.213l4.565-4.565c3.4-3.11 8.07-2.969 9.749.295l5.58 10.851a14.02 14.02 0 011.326 3.992l1.09 6.183c.578 3.278.551 6.777-.081 10.345l-1.138 6.425c-.166.936-.25 1.865-.25 2.775v2.21c0 1.06-.268 2.17-.78 3.23-.691 1.429-.883 2.926-.852 4.347.116 5.457-2.898 10.806-6.071 12.12l-.056.023c-3.588 1.486-7.401-2.277-8.517-8.404l-1.935-5.88-3.437-12.305c-.931-3.331-1.19-6.965-.765-10.723l.604-5.33.532-5.346c.118-1.179-.163-2.265-.797-3.083-1.464-1.892-.929-4.998 1.233-7.16z"
        ></path>
        <path
          id="forearm-left"
          fill={colorsSkin.light}
          d="M227.659 138.035l.067.003c4.278.19 7.825 5.194 7.921 11.178l.725 44.928c.096 5.983-3.294 10.68-7.572 10.49l-.067-.003c-4.278-.19-7.824-5.194-7.921-11.177l-.724-44.928c-.097-5.984 3.293-10.68 7.571-10.491z"
        ></path>
        <g id="hand-left" fill={colorsSkin.light}>
          <path d="M225.166 185.911l2.814.203c5.393.39 9.844 6.342 9.941 13.294l.086 6.176c.097 6.952-4.197 12.271-9.59 11.881l-2.814-.204c-5.393-.39-9.843-6.342-9.94-13.294l-.087-6.175c-.097-6.952 4.197-12.271 9.59-11.881z"></path>
          <path
            id="color"
            d="M223.326 203.261c1.368-4.265 5.838-6.117 9.985-4.137 4.147 1.981 6.4 7.044 5.032 11.31l-3.736 11.649c-1.367 4.265-5.838 6.117-9.984 4.136-4.147-1.981-6.4-7.044-5.032-11.309l3.735-11.649z"
          ></path>
        </g>
        <path
          id="thumb-left"
          fill={colorsSkin.strong}
          d="M214.51 200.326c-.032-3.072 1.817-5.429 4.129-5.266 2.312.163 4.212 2.786 4.243 5.858l.085 8.39c.031 3.072-1.817 5.43-4.129 5.266-2.312-.163-4.212-2.786-4.243-5.858l-.085-8.39z"
        ></path>
      </g>
      <g id="arm-right">
        <path
          id="bicep-right"
          fill={colorsSkin.light}
          d="M134.71 114.213l-4.565-4.565c-3.4-3.11-8.071-2.969-9.749.295l-5.58 10.851a14.052 14.052 0 00-1.327 3.992l-1.09 6.183c-.578 3.278-.55 6.777.082 10.345l1.138 6.425c.166.936.249 1.865.249 2.775v2.21c0 1.06.268 2.17.781 3.23.69 1.429.882 2.926.852 4.347-.116 5.457 2.898 10.806 6.07 12.12l.056.023c3.588 1.486 7.402-2.277 8.518-8.404l1.934-5.88 3.438-12.305c.93-3.331 1.189-6.965.764-10.723l-.603-5.33-.532-5.346c-.118-1.179.163-2.265.796-3.083 1.464-1.892.929-4.998-1.232-7.16z"
        ></path>
        <path
          id="forearm-right"
          fill={colorsSkin.light}
          d="M122.016 138.035l-.067.003c-4.278.19-7.824 5.194-7.921 11.178l-.724 44.928c-.097 5.983 3.293 10.68 7.572 10.49l.066-.003c4.279-.19 7.825-5.194 7.922-11.177l.724-44.928c.097-5.984-3.293-10.68-7.572-10.491z"
        ></path>
        <g id="hand-right" fill={colorsSkin.light}>
          <path
            id="color3"
            d="M124.51 185.911l-2.814.203c-5.393.39-9.844 6.342-9.941 13.294l-.086 6.176c-.097 6.952 4.196 12.271 9.589 11.881l2.814-.204c3.418-.247 6.458-2.728 8.25-6.282 1.036-2.054 1.655-4.466 1.691-7.012l.086-6.175c.097-6.952-4.196-12.271-9.589-11.881z"
          ></path>
          <path
            id="color31"
            d="M126.349 203.261c-1.367-4.265-5.838-6.117-9.984-4.137-4.147 1.981-6.4 7.044-5.032 11.31l3.735 11.649c1.368 4.265 5.838 6.117 9.985 4.136 4.147-1.981 6.4-7.044 5.032-11.309l-3.736-11.649z"
          ></path>
        </g>
        <path
          id="thumb-right"
          fill={colorsSkin.strong}
          d="M135.166 200.326c.031-3.072-1.817-5.429-4.129-5.266-2.312.163-4.212 2.786-4.243 5.858l-.085 8.39c-.032 3.072 1.817 5.43 4.129 5.266 2.312-.163 4.212-2.786 4.243-5.858l.085-8.39z"
        ></path>
      </g>
    </g>
    <g id="legs" fill={colorsSkin.light} fillRule="nonzero">
      <g id="leg-right">
        <path
          id="tibia-right"
          d="M161.971 246.652h-2.196a9.472 9.472 0 00-9.472 9.472l-.001 24.595.001 17.613-1.894 18.05c0 3.945 3.869 7.142 8.642 7.142h2.016c4.773 0 8.642-3.197 8.642-7.142v-26.356c0-1.835.449-3.642 1.305-5.266a11.268 11.268 0 001.306-5.266v-3.465c0-2.031-.253-4.054-.75-6.023a24.576 24.576 0 01-.749-6.024v-10.48a6.851 6.851 0 00-6.85-6.85z"
        ></path>
        <path
          id="thigh-right"
          d="M160.127 175.223H147.22c-.633 0-1.146.513-1.146 1.146l-4.145 38.669v13.496c0 1.713.139 3.424.419 5.114l.934 5.673a27.913 27.913 0 003.214 9.145l2.707 4.814a6.835 6.835 0 01.877 3.35 6.831 6.831 0 006.831 6.83h4.298a7.315 7.315 0 007.314-7.314c0-.864.152-1.721.451-2.532l.976-2.642c.418-1.134.747-2.3.983-3.485l1.474-7.407 1.424-9.482c.231-1.546.348-3.105.348-4.668v-36.654c0-7.762-6.291-14.053-14.052-14.053z"
        ></path>
        <g id="foot-right">
          <path
            id="color32"
            d="M161.873 308.505h-8.116c-4.68 0-8.473 3.384-8.473 7.558 0 4.174 3.793 7.557 8.473 7.557h8.116c4.679 0 8.473-3.383 8.473-7.557s-3.794-7.558-8.473-7.558z"
          ></path>
          <path
            id="color33"
            d="M167.54 315.333h-19.45c-1.549 0-2.806 1.121-2.806 2.503v3.274c0 1.382 1.257 2.502 2.806 2.502h19.45c1.55 0 2.806-1.12 2.806-2.502v-3.274c0-1.382-1.256-2.503-2.806-2.503z"
          ></path>
        </g>
      </g>
      <g id="leg-left">
        <path
          id="tibia-left"
          d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-.45-3.499-1.306-5.042a10.403 10.403 0 01-1.305-5.041v-3.779c0-2.023.246-4.038.732-6.002l.018-.071a25.57 25.57 0 00.749-6.147v-10.717a6.984 6.984 0 016.98-6.98z"
        ></path>
        <path
          id="thigh-left"
          d="M191.234 175.194h7.531a4.55 4.55 0 014.502 3.902l4.788 33.311c.212 1.481.319 2.976.319 4.472v11.072c0 1.729-.143 3.456-.426 5.162l-.926 5.564a27.538 27.538 0 01-3.213 9.063l-2.706 4.764a6.755 6.755 0 00-.881 3.336 6.755 6.755 0 01-6.755 6.755h-4.452a7.235 7.235 0 01-7.235-7.235c0-.862-.154-1.718-.455-2.525l-.972-2.611a22.64 22.64 0 01-.983-3.452l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
        ></path>
        <g id="foot-left">
          <path
            id="color34"
            d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
          ></path>
          <path
            id="color35"
            d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
          ></path>
        </g>
      </g>
    </g>
    <g id="torso">
      <path
        id="trunk"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M206.362 138.849c0-19.513-15.69-27.041-31.609-27.041-15.92 0-30.453 7.528-30.453 27.041l1.628 33.818c0 19.514 12.905 35.333 28.825 35.333 15.919 0 28.825-11.375 28.825-30.888l2.784-38.263z"
      ></path>
      <path
        id="shoulders"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M214.151 94.883h-79.544c-8.61 0-15.591 6.98-15.591 15.591 0 8.61 6.981 15.59 15.591 15.59h79.544c8.61 0 15.59-6.98 15.59-15.59 0-8.611-6.98-15.591-15.59-15.591z"
      ></path>
      <path
        id="deltoids"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M216.797 118.579c0-18.088-18.911-32.751-42.237-32.751-23.328 0-42.238 14.663-42.238 32.751 0 18.087 18.91 32.75 42.238 32.75 8.693 0 16.773-2.037 23.49-5.528 11.305-5.877 18.747-15.876 18.747-27.222z"
      ></path>
      <path
        id="abs-back"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M176.982 139.667h-4.666c-11.126 0-20.146 8.164-20.146 18.235l.306 15.69c.178.03.138.158 0 0-.053-.061-.144-3.736-.074-3.757l23.098 17.672c-.071-.059-.014 1.171 0 0 .011-.932.02.018 0 0-.023-.02-.122.044-.169-.129-.073-.265 22.568-17.393 22.277-17.543-.241-.124.009.038 0 0l-.481-11.933c0-10.071-9.019-18.235-20.145-18.235z"
        transform="translate(0 3)"
      ></path>
      <path
        id="torso-shadow"
        fill={colorsSkin.strong}
        fillRule="nonzero"
        d="M196.395 94.883h-43.672c-11.267 0-20.401 8.039-20.401 17.957v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956V112.84c0-9.918-9.134-17.957-20.402-17.957z"
      ></path>
      <path
        id="torso1"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M200.206 94.883h-51.293c-9.163 0-16.591 7.47-16.591 16.687v10.057c0 9.216 7.428 16.688 16.591 16.688h51.293c9.163 0 16.591-7.472 16.591-16.688V111.57c0-9.217-7.428-16.687-16.591-16.687z"
      ></path>
      <g id="nipples" fill={colorsSkin.strong} fillRule="nonzero">
        <path
          id="nipples1"
          d="M178.089 131.458c0-1.138-1.592-2.06-3.558-2.06-1.964 0-3.557.922-3.557 2.06v3.995c0 1.137 1.593 2.06 3.557 2.06 1.966 0 3.558-.923 3.558-2.06v-3.995z"
        ></path>
        <path
          id="color36"
          d="M151.932 133.158h-3.021c-1.204 0-2.181.882-2.181 1.97 0 1.086.977 1.968 2.181 1.968h3.021c1.204 0 2.18-.882 2.18-1.968 0-1.088-.976-1.97-2.18-1.97z"
        ></path>
        <path
          id="color37"
          d="M201.112 133.158h-3.02c-1.205 0-2.181.882-2.181 1.97 0 1.086.976 1.968 2.181 1.968h3.02c1.205 0 2.181-.882 2.181-1.968 0-1.088-.976-1.97-2.181-1.97z"
        ></path>
      </g>
      <g id="abs">
        <g id="abs-tablet" fill={colorsSkin.strong} fillRule="nonzero">
          <path d="M169.662 145.801h-5.35c-2.552 0-4.62 1.803-4.62 4.028 0 2.224 2.068 4.027 4.62 4.027h5.35c2.552 0 4.62-1.803 4.62-4.027 0-2.225-2.068-4.028-4.62-4.028zM169.662 155.377h-5.35c-2.552 0-4.62 1.804-4.62 4.027 0 2.225 2.068 4.029 4.62 4.029h5.35c2.552 0 4.62-1.804 4.62-4.029 0-2.223-2.068-4.027-4.62-4.027zM169.662 164.78h-5.35c-2.552 0-4.62 1.803-4.62 4.027 0 2.225 2.068 4.028 4.62 4.028h5.35c2.552 0 4.62-1.803 4.62-4.028 0-2.224-2.068-4.027-4.62-4.027zM186.094 145.801h-5.35c-2.552 0-4.62 1.803-4.62 4.027 0 2.225 2.068 4.028 4.62 4.028h5.35a5.18 5.18 0 001.801-.318c1.656-.612 2.818-2.042 2.818-3.71 0-2.224-2.068-4.027-4.619-4.027zM186.094 155.377h-5.35c-2.552 0-4.62 1.804-4.62 4.027 0 2.225 2.068 4.029 4.62 4.029h5.35c2.551 0 4.619-1.804 4.619-4.029 0-2.223-2.068-4.027-4.619-4.027zM186.094 164.779h-5.35c-2.552 0-4.62 1.803-4.62 4.028 0 2.224 2.068 4.027 4.62 4.027h5.35c2.551 0 4.619-1.803 4.619-4.027 0-2.225-2.068-4.028-4.619-4.028z"></path>
        </g>
      </g>
    </g>
    <g id="underwear">
      <path
        fill="#4F4F4F"
        fillRule="nonzero"
        d="M150.351 177.277c-1.518 0-2.983.244-4.37.69 1.623 17.749 13.856 31.459 28.805 31.459 15.145 0 27.5-14.066 28.864-32.149h-53.299z"
        transform="matrix(.99674 0 0 1 .459 0) translate(.154)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M203.332 179.475l-28.248 3.309v46.917h35.057c.268-14.102-4.272-33.823-6.809-50.226zM145.814 179.476l28.865 3.308v46.917h-34.085c-.267-14.102 2.683-33.822 5.22-50.225z"
        transform="matrix(.99674 0 0 1 .459 0) translate(.154)"
      ></path>
    </g>
  </g>
);

// Endomorphic Mesomorph
export const getEnMSkin = (
  colorsSkin: IColorSkin
): React.SVGProps<SVGGElement> => (
  <g transform="translate(0 -10.487)">
    <g
      id="arms"
      fillRule="nonzero"
      transform="translate(-105.499 .675) matrix(1.05625 0 0 1 -9.173 0)"
    >
      <g id="arm-left">
        <path
          id="bicep-left"
          fill={colorsSkin.light}
          d="M214.969 114.213l4.565-4.565c3.4-3.11 8.07-2.969 9.749.295l5.58 10.851a14.02 14.02 0 011.326 3.992l1.09 6.183c.578 3.278.551 6.777-.081 10.345l-1.138 6.425c-.166.936-.25 1.865-.25 2.775v2.21c0 1.06-.268 2.17-.78 3.23-.691 1.429-.883 2.926-.852 4.347.116 5.457-2.898 10.806-6.071 12.12l-.056.023c-3.588 1.486-7.401-2.277-8.517-8.404l-1.935-5.88-3.437-12.305c-.931-3.331-1.19-6.965-.765-10.723l.604-5.33.532-5.346c.118-1.179-.163-2.265-.797-3.083-1.464-1.892-.929-4.998 1.233-7.16z"
        ></path>
        <path
          id="forearm-left"
          fill={colorsSkin.light}
          d="M227.659 138.035l.067.003c4.278.19 7.825 5.194 7.921 11.178l.725 44.928c.096 5.983-3.294 10.68-7.572 10.49l-.067-.003c-4.278-.19-7.824-5.194-7.921-11.177l-.724-44.928c-.097-5.984 3.293-10.68 7.571-10.491z"
        ></path>
        <g id="hand-left" fill={colorsSkin.light}>
          <path d="M225.166 185.911l2.814.203c5.393.39 9.844 6.342 9.941 13.294l.086 6.176c.097 6.952-4.197 12.271-9.59 11.881l-2.814-.204c-5.393-.39-9.843-6.342-9.94-13.294l-.087-6.175c-.097-6.952 4.197-12.271 9.59-11.881z"></path>
          <path
            id="color"
            d="M223.326 203.261c1.368-4.265 5.838-6.117 9.985-4.137 4.147 1.981 6.4 7.044 5.032 11.31l-3.736 11.649c-1.367 4.265-5.838 6.117-9.984 4.136-4.147-1.981-6.4-7.044-5.032-11.309l3.735-11.649z"
          ></path>
        </g>
        <path
          id="thumb-left"
          fill={colorsSkin.strong}
          d="M214.51 200.326c-.032-3.072 1.817-5.429 4.129-5.266 2.312.163 4.212 2.786 4.243 5.858l.085 8.39c.031 3.072-1.817 5.43-4.129 5.266-2.312-.163-4.212-2.786-4.243-5.858l-.085-8.39z"
        ></path>
      </g>
      <g id="arm-right">
        <path
          id="bicep-right"
          fill={colorsSkin.light}
          d="M134.71 114.213l-4.565-4.565c-3.4-3.11-8.071-2.969-9.749.295l-5.58 10.851a14.052 14.052 0 00-1.327 3.992l-1.09 6.183c-.578 3.278-.55 6.777.082 10.345l1.138 6.425c.166.936.249 1.865.249 2.775v2.21c0 1.06.268 2.17.781 3.23.69 1.429.882 2.926.852 4.347-.116 5.457 2.898 10.806 6.07 12.12l.056.023c3.588 1.486 7.402-2.277 8.518-8.404l1.934-5.88 3.438-12.305c.93-3.331 1.189-6.965.764-10.723l-.603-5.33-.532-5.346c-.118-1.179.163-2.265.796-3.083 1.464-1.892.929-4.998-1.232-7.16z"
        ></path>
        <path
          id="forearm-right"
          fill={colorsSkin.light}
          d="M122.016 138.035l-.067.003c-4.278.19-7.824 5.194-7.921 11.178l-.724 44.928c-.097 5.983 3.293 10.68 7.572 10.49l.066-.003c4.279-.19 7.825-5.194 7.922-11.177l.724-44.928c.097-5.984-3.293-10.68-7.572-10.491z"
        ></path>
        <g id="hand-right" fill={colorsSkin.light}>
          <path
            id="color3"
            d="M124.51 185.911l-2.814.203c-5.393.39-9.844 6.342-9.941 13.294l-.086 6.176c-.097 6.952 4.196 12.271 9.589 11.881l2.814-.204c3.418-.247 6.458-2.728 8.25-6.282 1.036-2.054 1.655-4.466 1.691-7.012l.086-6.175c.097-6.952-4.196-12.271-9.589-11.881z"
          ></path>
          <path
            id="color31"
            d="M126.349 203.261c-1.367-4.265-5.838-6.117-9.984-4.137-4.147 1.981-6.4 7.044-5.032 11.31l3.735 11.649c1.368 4.265 5.838 6.117 9.985 4.136 4.147-1.981 6.4-7.044 5.032-11.309l-3.736-11.649z"
          ></path>
        </g>
        <path
          id="thumb-right"
          fill={colorsSkin.strong}
          d="M135.166 200.326c.031-3.072-1.817-5.429-4.129-5.266-2.312.163-4.212 2.786-4.243 5.858l-.085 8.39c-.032 3.072 1.817 5.43 4.129 5.266 2.312-.163 4.212-2.786 4.243-5.858l.085-8.39z"
        ></path>
      </g>
    </g>
    <g
      id="legs"
      fill={colorsSkin.light}
      fillRule="nonzero"
      transform="translate(-105.499 .675) matrix(1.05625 0 0 1 -9.173 0)"
    >
      <g id="leg-right">
        <path
          id="tibia-right"
          d="M161.971 246.652h-2.196a9.472 9.472 0 00-9.472 9.472l-.001 24.595.001 17.613-1.894 18.05c0 3.945 3.869 7.142 8.642 7.142h2.016c4.773 0 8.642-3.197 8.642-7.142v-26.356c0-1.835.449-3.642 1.305-5.266a11.268 11.268 0 001.306-5.266v-3.465c0-2.031-.253-4.054-.75-6.023a24.576 24.576 0 01-.749-6.024v-10.48a6.851 6.851 0 00-6.85-6.85z"
        ></path>
        <path
          id="thigh-right"
          d="M160.127 175.223H147.22c-.633 0-1.146.513-1.146 1.146l-4.145 38.669v13.496c0 1.713.139 3.424.419 5.114l.934 5.673a27.913 27.913 0 003.214 9.145l2.707 4.814a6.835 6.835 0 01.877 3.35 6.831 6.831 0 006.831 6.83h4.298a7.315 7.315 0 007.314-7.314c0-.864.152-1.721.451-2.532l.976-2.642c.418-1.134.747-2.3.983-3.485l1.474-7.407 1.424-9.482c.231-1.546.348-3.105.348-4.668v-36.654c0-7.762-6.291-14.053-14.052-14.053z"
        ></path>
        <g id="foot-right">
          <path
            id="color32"
            d="M161.873 308.505h-8.116c-4.68 0-8.473 3.384-8.473 7.558 0 4.174 3.793 7.557 8.473 7.557h8.116c4.679 0 8.473-3.383 8.473-7.557s-3.794-7.558-8.473-7.558z"
          ></path>
          <path
            id="color33"
            d="M167.54 315.333h-19.45c-1.549 0-2.806 1.121-2.806 2.503v3.274c0 1.382 1.257 2.502 2.806 2.502h19.45c1.55 0 2.806-1.12 2.806-2.502v-3.274c0-1.382-1.256-2.503-2.806-2.503z"
          ></path>
        </g>
      </g>
      <g id="leg-left">
        <path
          id="tibia-left"
          d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-.45-3.499-1.306-5.042a10.403 10.403 0 01-1.305-5.041v-3.779c0-2.023.246-4.038.732-6.002l.018-.071a25.57 25.57 0 00.749-6.147v-10.717a6.984 6.984 0 016.98-6.98z"
        ></path>
        <path
          id="thigh-left"
          d="M191.234 175.194h7.531a4.55 4.55 0 014.502 3.902l4.788 33.311c.212 1.481.319 2.976.319 4.472v11.072c0 1.729-.143 3.456-.426 5.162l-.926 5.564a27.538 27.538 0 01-3.213 9.063l-2.706 4.764a6.755 6.755 0 00-.881 3.336 6.755 6.755 0 01-6.755 6.755h-4.452a7.235 7.235 0 01-7.235-7.235c0-.862-.154-1.718-.455-2.525l-.972-2.611a22.64 22.64 0 01-.983-3.452l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
        ></path>
        <g id="foot-left">
          <path
            id="color34"
            d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
          ></path>
          <path
            id="color35"
            d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
          ></path>
        </g>
      </g>
    </g>
    <g id="torso" transform="translate(-105.499 .675)">
      <path
        id="trunk"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M206.362 138.849c0-19.513-15.69-27.041-31.609-27.041-15.92 0-30.453 7.528-30.453 27.041l1.628 33.818c0 19.514 12.905 35.333 28.825 35.333 15.919 0 28.825-11.375 28.825-30.888l2.784-38.263z"
        transform="matrix(1.05625 0 0 1 -9.173 0)"
      ></path>
      <path
        id="shoulders"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M214.151 94.883h-79.544c-8.61 0-15.591 6.98-15.591 15.591 0 8.61 6.981 15.59 15.591 15.59h79.544c8.61 0 15.59-6.98 15.59-15.59 0-8.611-6.98-15.591-15.59-15.591z"
        transform="matrix(1.05625 0 0 1 -9.173 0)"
      ></path>
      <path
        id="deltoids"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M216.797 118.579c0-18.088-18.911-32.751-42.237-32.751-23.328 0-42.238 14.663-42.238 32.751 0 18.087 18.91 32.75 42.238 32.75 8.693 0 16.773-2.037 23.49-5.528 11.305-5.877 18.747-15.876 18.747-27.222z"
        transform="matrix(1.05625 0 0 1 -9.173 0)"
      ></path>
      <path
        id="abs-back"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M176.982 139.667h-4.666c-11.126 0-20.146 8.164-20.146 18.235 0 0 .162 11.954.232 11.933l22.929 10.316c-.071-.059 22.568-10.166 22.277-10.316-.241-.124.009.038 0 0l-.481-11.933c0-10.071-9.019-18.235-20.145-18.235z"
        transform="matrix(1.05625 0 0 1 -9.173 3)"
      ></path>
      <path
        id="torso-shadow"
        fill={colorsSkin.strong}
        fillRule="nonzero"
        d="M196.395 94.883h-43.672c-11.267 0-20.401 8.039-20.401 17.957v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956V112.84c0-9.918-9.134-17.957-20.402-17.957z"
        transform="matrix(1.05625 0 0 1 -9.173 0)"
      ></path>
      <path
        id="torso1"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M200.206 94.883h-51.293c-9.163 0-16.591 7.47-16.591 16.687v10.057c0 9.216 7.428 16.688 16.591 16.688h51.293c9.163 0 16.591-7.472 16.591-16.688V111.57c0-9.217-7.428-16.687-16.591-16.687z"
        transform="matrix(1.05625 0 0 1 -9.173 0)"
      ></path>
      <g
        id="nipples"
        fill={colorsSkin.strong}
        fillRule="nonzero"
        transform="matrix(1.05625 0 0 1 -9.173 0)"
      >
        <path
          id="nipples1"
          d="M178.089 131.458c0-1.138-1.592-2.06-3.558-2.06-1.964 0-3.557.922-3.557 2.06v3.995c0 1.137 1.593 2.06 3.557 2.06 1.966 0 3.558-.923 3.558-2.06v-3.995z"
        ></path>
        <path
          id="color36"
          d="M151.932 133.158h-3.021c-1.204 0-2.181.882-2.181 1.97 0 1.086.977 1.968 2.181 1.968h3.021c1.204 0 2.18-.882 2.18-1.968 0-1.088-.976-1.97-2.18-1.97z"
        ></path>
        <path
          id="color37"
          d="M201.112 133.158h-3.02c-1.205 0-2.181.882-2.181 1.97 0 1.086.976 1.968 2.181 1.968h3.02c1.205 0 2.181-.882 2.181-1.968 0-1.088-.976-1.97-2.181-1.97z"
        ></path>
      </g>
      <g transform="matrix(1.05625 0 0 1 -9.173 2)">
        <g id="abs">
          <g id="abs-tablet" fill={colorsSkin.strong} fillRule="nonzero">
            <path d="M169.662 145.801h-5.35c-2.552 0-4.62 1.803-4.62 4.028 0 2.224 2.068 4.027 4.62 4.027h5.35c2.552 0 4.62-1.803 4.62-4.027 0-2.225-2.068-4.028-4.62-4.028zM169.662 155.377h-5.35c-2.552 0-4.62 1.804-4.62 4.027 0 2.225 2.068 4.029 4.62 4.029h5.35c2.552 0 4.62-1.804 4.62-4.029 0-2.223-2.068-4.027-4.62-4.027zM169.662 164.78h-5.35c-2.552 0-4.62 1.803-4.62 4.027 0 2.225 2.068 4.028 4.62 4.028h5.35c2.552 0 4.62-1.803 4.62-4.028 0-2.224-2.068-4.027-4.62-4.027zM186.094 145.801h-5.35c-2.552 0-4.62 1.803-4.62 4.027 0 2.225 2.068 4.028 4.62 4.028h5.35a5.18 5.18 0 001.801-.318c1.656-.612 2.818-2.042 2.818-3.71 0-2.224-2.068-4.027-4.619-4.027zM186.094 155.377h-5.35c-2.552 0-4.62 1.804-4.62 4.027 0 2.225 2.068 4.029 4.62 4.029h5.35c2.551 0 4.619-1.804 4.619-4.029 0-2.223-2.068-4.027-4.619-4.027zM186.094 164.779h-5.35c-2.552 0-4.62 1.803-4.62 4.028 0 2.224 2.068 4.027 4.62 4.027h5.35c2.551 0 4.619-1.803 4.619-4.027 0-2.225-2.068-4.028-4.619-4.028z"></path>
          </g>
        </g>
      </g>
    </g>
    <g id="underwear">
      <path
        fill="#4F4F4F"
        fillRule="nonzero"
        d="M150.351 177.277c-1.518 0-4.858.137-4.777.074 1.623 17.749 14.263 32.075 29.212 32.075 15.145 0 27.228-14.066 28.592-32.149h-53.027z"
        transform="matrix(1.05625 0 0 1 -5.24 7.591) translate(-103.451 -6.916)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M203.382 177.277l-28.298 5.507v46.917h35.057c.268-14.102-4.222-36.021-6.759-52.424zM145.573 178.37l29.106 4.414v46.917h-34.085c-.267-14.102 2.442-34.928 4.979-51.331z"
        transform="matrix(1.05625 0 0 1 -5.24 7.591) translate(-103.451 -6.916)"
      ></path>
    </g>
  </g>
);

// Ectomorphic Mesomorph
export const getEcMSkin = (
  colorsSkin: IColorSkin
): React.SVGProps<SVGGElement> => (
  <g transform="translate(-105.5 -9.982)">
    <g id="arms" fillRule="nonzero" transform="matrix(.9389 0 0 1 11.346 0)">
      <g id="arm-left">
        <path
          id="bicep-left"
          fill={colorsSkin.light}
          d="M214.969 114.213l4.565-4.565c3.4-3.11 8.07-2.969 9.749.295l5.58 10.851a14.02 14.02 0 011.326 3.992l1.09 6.183c.578 3.278.551 6.777-.081 10.345l-1.138 6.425c-.166.936-.25 1.865-.25 2.775v2.21c0 1.06-.268 2.17-.78 3.23-.691 1.429-.883 2.926-.852 4.347.116 5.457-2.898 10.806-6.071 12.12l-.056.023c-3.588 1.486-7.401-2.277-8.517-8.404l-1.935-5.88-3.437-12.305c-.931-3.331-1.19-6.965-.765-10.723l.604-5.33.532-5.346c.118-1.179-.163-2.265-.797-3.083-1.464-1.892-.929-4.998 1.233-7.16z"
        ></path>
        <path
          id="forearm-left"
          fill={colorsSkin.light}
          d="M227.659 138.035l.067.003c4.278.19 7.825 5.194 7.921 11.178l.725 44.928c.096 5.983-3.294 10.68-7.572 10.49l-.067-.003c-4.278-.19-7.824-5.194-7.921-11.177l-.724-44.928c-.097-5.984 3.293-10.68 7.571-10.491z"
        ></path>
        <g id="hand-left" fill={colorsSkin.light}>
          <path d="M225.166 185.911l2.814.203c5.393.39 9.844 6.342 9.941 13.294l.086 6.176c.097 6.952-4.197 12.271-9.59 11.881l-2.814-.204c-5.393-.39-9.843-6.342-9.94-13.294l-.087-6.175c-.097-6.952 4.197-12.271 9.59-11.881z"></path>
          <path
            id="color"
            d="M223.326 203.261c1.368-4.265 5.838-6.117 9.985-4.137 4.147 1.981 6.4 7.044 5.032 11.31l-3.736 11.649c-1.367 4.265-5.838 6.117-9.984 4.136-4.147-1.981-6.4-7.044-5.032-11.309l3.735-11.649z"
          ></path>
        </g>
        <path
          id="thumb-left"
          fill={colorsSkin.strong}
          d="M214.51 200.326c-.032-3.072 1.817-5.429 4.129-5.266 2.312.163 4.212 2.786 4.243 5.858l.085 8.39c.031 3.072-1.817 5.43-4.129 5.266-2.312-.163-4.212-2.786-4.243-5.858l-.085-8.39z"
        ></path>
      </g>
      <g id="arm-right">
        <path
          id="bicep-right"
          fill={colorsSkin.light}
          d="M134.71 114.213l-4.565-4.565c-3.4-3.11-8.071-2.969-9.749.295l-5.58 10.851a14.052 14.052 0 00-1.327 3.992l-1.09 6.183c-.578 3.278-.55 6.777.082 10.345l1.138 6.425c.166.936.249 1.865.249 2.775v2.21c0 1.06.268 2.17.781 3.23.69 1.429.882 2.926.852 4.347-.116 5.457 2.898 10.806 6.07 12.12l.056.023c3.588 1.486 7.402-2.277 8.518-8.404l1.934-5.88 3.438-12.305c.93-3.331 1.189-6.965.764-10.723l-.603-5.33-.532-5.346c-.118-1.179.163-2.265.796-3.083 1.464-1.892.929-4.998-1.232-7.16z"
        ></path>
        <path
          id="forearm-right"
          fill={colorsSkin.light}
          d="M122.016 138.035l-.067.003c-4.278.19-7.824 5.194-7.921 11.178l-.724 44.928c-.097 5.983 3.293 10.68 7.572 10.49l.066-.003c4.279-.19 7.825-5.194 7.922-11.177l.724-44.928c.097-5.984-3.293-10.68-7.572-10.491z"
        ></path>
        <g id="hand-right" fill={colorsSkin.light}>
          <path
            id="color3"
            d="M124.51 185.911l-2.814.203c-5.393.39-9.844 6.342-9.941 13.294l-.086 6.176c-.097 6.952 4.196 12.271 9.589 11.881l2.814-.204c3.418-.247 6.458-2.728 8.25-6.282 1.036-2.054 1.655-4.466 1.691-7.012l.086-6.175c.097-6.952-4.196-12.271-9.589-11.881z"
          ></path>
          <path
            id="color31"
            d="M126.349 203.261c-1.367-4.265-5.838-6.117-9.984-4.137-4.147 1.981-6.4 7.044-5.032 11.31l3.735 11.649c1.368 4.265 5.838 6.117 9.985 4.136 4.147-1.981 6.4-7.044 5.032-11.309l-3.736-11.649z"
          ></path>
        </g>
        <path
          id="thumb-right"
          fill={colorsSkin.strong}
          d="M135.166 200.326c.031-3.072-1.817-5.429-4.129-5.266-2.312.163-4.212 2.786-4.243 5.858l-.085 8.39c-.032 3.072 1.817 5.43 4.129 5.266 2.312-.163 4.212-2.786 4.243-5.858l.085-8.39z"
        ></path>
      </g>
    </g>
    <g
      id="legs"
      fill={colorsSkin.light}
      fillRule="nonzero"
      transform="matrix(.9389 0 0 1 11.346 0)"
    >
      <g id="leg-right">
        <path
          id="tibia-right"
          d="M161.971 246.652h-2.196a9.472 9.472 0 00-9.472 9.472l-.001 24.595.001 17.613-1.894 18.05c0 3.945 3.869 7.142 8.642 7.142h2.016c4.773 0 8.642-3.197 8.642-7.142v-26.356c0-1.835.449-3.642 1.305-5.266a11.268 11.268 0 001.306-5.266v-3.465c0-2.031-.253-4.054-.75-6.023a24.576 24.576 0 01-.749-6.024v-10.48a6.851 6.851 0 00-6.85-6.85z"
        ></path>
        <path
          id="thigh-right"
          d="M160.127 175.223H147.22c-.633 0-1.146.513-1.146 1.146l-4.145 38.669v13.496c0 1.713.139 3.424.419 5.114l.934 5.673a27.913 27.913 0 003.214 9.145l2.707 4.814a6.835 6.835 0 01.877 3.35 6.831 6.831 0 006.831 6.83h4.298a7.315 7.315 0 007.314-7.314c0-.864.152-1.721.451-2.532l.976-2.642c.418-1.134.747-2.3.983-3.485l1.474-7.407 1.424-9.482c.231-1.546.348-3.105.348-4.668v-36.654c0-7.762-6.291-14.053-14.052-14.053z"
        ></path>
        <g id="foot-right">
          <path
            id="color32"
            d="M161.873 308.505h-8.116c-4.68 0-8.473 3.384-8.473 7.558 0 4.174 3.793 7.557 8.473 7.557h8.116c4.679 0 8.473-3.383 8.473-7.557s-3.794-7.558-8.473-7.558z"
          ></path>
          <path
            id="color33"
            d="M167.54 315.333h-19.45c-1.549 0-2.806 1.121-2.806 2.503v3.274c0 1.382 1.257 2.502 2.806 2.502h19.45c1.55 0 2.806-1.12 2.806-2.502v-3.274c0-1.382-1.256-2.503-2.806-2.503z"
          ></path>
        </g>
      </g>
      <g id="leg-left">
        <path
          id="tibia-left"
          d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-.45-3.499-1.306-5.042a10.403 10.403 0 01-1.305-5.041v-3.779c0-2.023.246-4.038.732-6.002l.018-.071a25.57 25.57 0 00.749-6.147v-10.717a6.984 6.984 0 016.98-6.98z"
        ></path>
        <path
          id="thigh-left"
          d="M191.234 175.194h7.531a4.55 4.55 0 014.502 3.902l4.788 33.311c.212 1.481.319 2.976.319 4.472v11.072c0 1.729-.143 3.456-.426 5.162l-.926 5.564a27.538 27.538 0 01-3.213 9.063l-2.706 4.764a6.755 6.755 0 00-.881 3.336 6.755 6.755 0 01-6.755 6.755h-4.452a7.235 7.235 0 01-7.235-7.235c0-.862-.154-1.718-.455-2.525l-.972-2.611a22.64 22.64 0 01-.983-3.452l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
        ></path>
        <g id="foot-left">
          <path
            id="color34"
            d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
          ></path>
          <path
            id="color35"
            d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
          ></path>
        </g>
      </g>
    </g>
    <g id="torso">
      <path
        id="trunk"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M206.362 138.849c0-19.513-15.69-27.041-31.609-27.041-15.92 0-30.453 7.528-30.453 27.041l1.628 33.818c0 19.514 12.905 35.333 28.825 35.333 15.919 0 28.825-11.375 28.825-30.888l2.784-38.263z"
        transform="matrix(.9389 0 0 1 11.346 0)"
      ></path>
      <path
        id="shoulders"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M214.151 94.883h-79.544c-8.61 0-15.591 6.98-15.591 15.591 0 8.61 6.981 15.59 15.591 15.59h79.544c8.61 0 15.59-6.98 15.59-15.59 0-8.611-6.98-15.591-15.59-15.591z"
        transform="matrix(.9389 0 0 1 11.346 0)"
      ></path>
      <path
        id="deltoids"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M216.797 118.579c0-18.088-18.911-32.751-42.237-32.751-23.328 0-42.238 14.663-42.238 32.751 0 18.087 18.91 32.75 42.238 32.75 8.693 0 16.773-2.037 23.49-5.528 11.305-5.877 18.747-15.876 18.747-27.222z"
        transform="matrix(.9389 0 0 1 11.346 0)"
      ></path>
      <path
        id="abs-back"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M176.982 139.667h-4.666c-11.126 0-20.146 8.164-20.146 18.235 0 0 .162 11.954.232 11.933l22.929 10.316c-.071-.059 22.568-10.166 22.277-10.316-.241-.124.009.038 0 0l-.481-11.933c0-10.071-9.019-18.235-20.145-18.235z"
        transform="matrix(.9389 0 0 1 11.346 3)"
      ></path>
      <path
        id="torso-shadow"
        fill={colorsSkin.strong}
        fillRule="nonzero"
        d="M196.395 94.883h-43.672c-11.267 0-20.401 8.039-20.401 17.957v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956V112.84c0-9.918-9.134-17.957-20.402-17.957z"
        transform="matrix(.9389 0 0 1 11.346 0)"
      ></path>
      <path
        id="torso1"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M200.206 94.883h-51.293c-9.163 0-16.591 7.47-16.591 16.687v10.057c0 9.216 7.428 16.688 16.591 16.688h51.293c9.163 0 16.591-7.472 16.591-16.688V111.57c0-9.217-7.428-16.687-16.591-16.687z"
        transform="matrix(.9389 0 0 1 11.346 0)"
      ></path>
      <g
        id="nipples"
        fill={colorsSkin.strong}
        fillRule="nonzero"
        transform="matrix(.9389 0 0 1 11.346 0)"
      >
        <path
          id="nipples1"
          d="M178.089 131.458c0-1.138-1.592-2.06-3.558-2.06-1.964 0-3.557.922-3.557 2.06v3.995c0 1.137 1.593 2.06 3.557 2.06 1.966 0 3.558-.923 3.558-2.06v-3.995z"
        ></path>
        <path
          id="color36"
          d="M151.932 133.158h-3.021c-1.204 0-2.181.882-2.181 1.97 0 1.086.977 1.968 2.181 1.968h3.021c1.204 0 2.18-.882 2.18-1.968 0-1.088-.976-1.97-2.18-1.97z"
        ></path>
        <path
          id="color37"
          d="M201.112 133.158h-3.02c-1.205 0-2.181.882-2.181 1.97 0 1.086.976 1.968 2.181 1.968h3.02c1.205 0 2.181-.882 2.181-1.968 0-1.088-.976-1.97-2.181-1.97z"
        ></path>
      </g>
      <g transform="matrix(.9389 0 0 1 11.346 2)">
        <g id="abs">
          <g id="abs-tablet" fill={colorsSkin.strong} fillRule="nonzero">
            <path d="M169.662 145.801h-5.35c-2.552 0-4.62 1.803-4.62 4.028 0 2.224 2.068 4.027 4.62 4.027h5.35c2.552 0 4.62-1.803 4.62-4.027 0-2.225-2.068-4.028-4.62-4.028zM169.662 155.377h-5.35c-2.552 0-4.62 1.804-4.62 4.027 0 2.225 2.068 4.029 4.62 4.029h5.35c2.552 0 4.62-1.804 4.62-4.029 0-2.223-2.068-4.027-4.62-4.027zM169.662 164.78h-5.35c-2.552 0-4.62 1.803-4.62 4.027 0 2.225 2.068 4.028 4.62 4.028h5.35c2.552 0 4.62-1.803 4.62-4.028 0-2.224-2.068-4.027-4.62-4.027zM186.094 145.801h-5.35c-2.552 0-4.62 1.803-4.62 4.027 0 2.225 2.068 4.028 4.62 4.028h5.35a5.18 5.18 0 001.801-.318c1.656-.612 2.818-2.042 2.818-3.71 0-2.224-2.068-4.027-4.619-4.027zM186.094 155.377h-5.35c-2.552 0-4.62 1.804-4.62 4.027 0 2.225 2.068 4.029 4.62 4.029h5.35c2.551 0 4.619-1.804 4.619-4.029 0-2.223-2.068-4.027-4.619-4.027zM186.094 164.779h-5.35c-2.552 0-4.62 1.803-4.62 4.028 0 2.224 2.068 4.027 4.62 4.027h5.35c2.551 0 4.619-1.803 4.619-4.027 0-2.225-2.068-4.028-4.619-4.028z"></path>
          </g>
        </g>
      </g>
    </g>
    <g id="underwear">
      <path
        fill="#4F4F4F"
        fillRule="nonzero"
        d="M150.351 177.277c-1.518 0-4.712.085-4.798.033 1.623 17.749 14.284 32.116 29.233 32.116 15.145 0 27.272-14.074 28.636-32.157l-53.071.008z"
        transform="matrix(.9389 0 0 1 108.62 6.916) translate(-103.451 -6.916)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M203.422 177.269l-28.338 5.515v46.917h35.057c.268-14.102-4.182-36.029-6.719-52.432zM145.553 177.269l29.126 5.515v46.917h-34.085c-.267-14.102 2.422-36.029 4.959-52.432z"
        transform="matrix(.9389 0 0 1 108.62 6.916) translate(-103.451 -6.916)"
      ></path>
    </g>
  </g>
);

import { IColorSkin } from "../../variablesAvatar/VariableAvatar";

// Ectomorphic Endomorph
export const getEcEnSkinFem = (
  colorsSkin: IColorSkin,
  cloth: boolean
): React.SVGProps<SVGGElement> => (
  <g transform="translate(3.401 -11.606)">
    <g transform="translate(-108.547 15.076)">
      <g id="legs">
        <g id="legs1">
          <g id="leg-left" transform="matrix(.93686 0 0 1 11.476 1.582)">
            <path
              id="tibia-left"
              fill={colorsSkin.light}
              fillRule="nonzero"
              d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-1.112-24.01-1.112-26.082v-10.717a6.984 6.984 0 016.98-6.98z"
              transform="translate(-.426 -.667)"
            ></path>
            <path
              id="thigh-left"
              fill={colorsSkin.light}
              fillRule="nonzero"
              d="M191.234 175.194h7.531c1.941 0 7.246.163 7.286.447.941 7.881 2.217 7.64 2.594 29.149 0 0-.111 11.238-1.417 20.721-.98 7.115-4.868 19.42-6.462 22.229 0 0-2.293 4.918-2.293 6.087 0 3.73.495 8.854-5.006 8.768h-4.452c-3.995 0-9.246.016-9.246-3.98 0-.862-.163-10.669-.399-11.843l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
              transform="translate(.852 -.667)"
            ></path>
            <g
              id="foot-left"
              transform="translate(-2.556) translate(.852 -.667)"
            >
              <path
                id="color3"
                fill={colorsSkin.light}
                fillRule="nonzero"
                d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
              ></path>
              <path
                id="color31"
                fill={colorsSkin.light}
                fillRule="nonzero"
                d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
              ></path>
            </g>
          </g>
          <g id="leg-right" transform="matrix(.93686 0 0 1 12.275 -.084)">
            <path
              id="tibia-right"
              fill={colorsSkin.light}
              fillRule="nonzero"
              d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-1.112-24.01-1.112-26.082v-10.717a6.984 6.984 0 016.98-6.98z"
              transform="matrix(-1 0 0 1 352.135 0)"
            ></path>
            <path
              id="thigh-right"
              fill={colorsSkin.light}
              fillRule="nonzero"
              d="M191.234 175.194h7.531c1.941 0 7.246.163 7.286.447.941 7.881 2.217 7.64 2.594 29.149 0 0-.111 11.238-1.417 20.721-.98 7.115-4.868 19.42-6.462 22.229 0 0-2.293 4.918-2.293 6.087 0 3.73.495 8.854-5.006 8.768h-4.452c-3.995 0-9.246.016-9.246-3.98 0-.862-.163-10.669-.399-11.843l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
              transform="matrix(-1 0 0 1 351.162 1)"
            ></path>
            <g
              id="foot-left1"
              transform="translate(-33.012 1.314) translate(.852 -.667)"
            >
              <path
                id="color32"
                fill={colorsSkin.light}
                fillRule="nonzero"
                d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
              ></path>
              <path
                id="color33"
                fill={colorsSkin.light}
                fillRule="nonzero"
                d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g transform="matrix(.76586 0 0 1 16.086 0)">
      <path
        id="deltoids"
        fill={colorsSkin.strong}
        fillOpacity="0.99"
        fillRule="nonzero"
        d="M67.966 106.844c1.352 0 2.699.074 4.034.218 16.102 1.737 30.453 13.632 30.453 28.139l-2.528 15.532 5.425 39.452c0 15.71-19.726 5.315-37.384 5.315s-37.508 10.395-37.508-5.315l5.634-39.452v-15.532c0-15.71 14.216-28.357 31.874-28.357z"
      ></path>
      <g transform="matrix(.94527 0 0 1 3.65 0)">
        <path
          id="deltoids1"
          fill={colorsSkin.light}
          fillOpacity="0.99"
          fillRule="nonzero"
          d="M67.966 106.844c1.352 0 2.699.074 4.034.218 16.102 1.737 30.453 13.632 30.453 28.139l-2.528 15.532 5.425 39.452c0 15.71-19.726 5.315-37.384 5.315s-37.508 10.395-37.508-5.315l5.634-39.452v-15.532c0-15.71 14.216-28.357 31.874-28.357z"
        ></path>
      </g>
    </g>
    <g transform="matrix(.95634 0 0 1 2.97 0)">
      <path
        id="trunk"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M67.925 170.441h1.023c11.532 0 17.073-4.06 20.313-2.908 1.52.541 2.534 2.229 3.422 6.022l1.24 16.386c.572 2.443-11.519 29.167-24.975 29.167h-1.023c-8.563 0-25.366-25.77-24.571-29.167l1.867-16.386c2.781-11.878 5.761-3.114 22.704-3.114z"
      ></path>
    </g>
    <g transform="matrix(1.06411 0 0 1 -4.329 0)">
      <path
        id="abs-back"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M67.891 159.689c12.211 0 22.264 13.834 22.264 30.252H45.822c0-16.418 9.857-30.252 22.069-30.252z"
      ></path>
    </g>
    <g id="arms" transform="matrix(.8863 0 0 1 7.742 0)">
      <g id="arm-right" transform="matrix(.82307 0 0 1 13.177 0)">
        <path
          id="bicep-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M106.462 123.095c5.175-1.001 11.84 6.466 12.98 12.361 0 0 3.368 30.511 3.336 32.875-.057 4.141-1.945 7.178-6.027 7.967-4.457.863-7.418-1.357-8.882-5.756-.497-1.493-3.462-27.641-3.533-29.225-.195-4.355-1.768-17.469 2.126-18.222z"
          transform="matrix(-1 0 0 1 135.265 -1)"
        ></path>
        <path
          id="forearm-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M114.564 156.787l.067-.002c4.241-.131 7.919 4.578 8.215 10.517l2.223 44.6c.295 5.94-2.903 10.861-7.145 10.992l-.066.002c-4.241.131-7.92-4.578-8.216-10.518l-2.222-44.6c-.296-5.94 2.903-10.861 7.144-10.991z"
          transform="matrix(-1 0 0 1 135.265 -1)"
        ></path>
        <g id="hand-right" fill={colorsSkin.light} fillRule="nonzero">
          <path d="M21.541 202.739l-2.666-.015c-5.109-.027-9.491 5.517-9.789 12.383l-.264 6.1c-.298 6.866 3.602 12.454 8.711 12.481l2.665.015c5.109.027 9.491-5.517 9.789-12.383l.265-6.1c.297-6.866-3.603-12.454-8.711-12.481z"></path>
          <path d="M22.199 220.089c-1.111-4.367-5.03-6.578-8.752-4.939-3.722 1.639-5.839 6.508-4.728 10.874l3.036 11.926c1.111 4.366 5.03 6.577 8.752 4.938 3.723-1.639 5.839-6.507 4.728-10.874l-3.036-11.925z"></path>
        </g>
        <path
          id="thumb-right"
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M30.913 217.757c.125-3.044-1.581-5.522-3.812-5.536-2.23-.014-4.14 2.443-4.266 5.487l-.343 8.313c-.126 3.044 1.581 5.523 3.812 5.537 2.23.013 4.14-2.443 4.266-5.487l.343-8.314z"
        ></path>
      </g>
      <g
        id="arm-left"
        fillRule="nonzero"
        transform="matrix(.82307 0 0 1 12.049 0)"
      >
        <path
          id="bicep-left1"
          fill={colorsSkin.light}
          d="M106.462 123.095c5.175-1.001 11.84 6.466 12.98 12.361 0 0 3.368 30.511 3.336 32.875-.057 4.141-1.945 7.178-6.027 7.967-4.457.863-7.418-1.357-8.882-5.756-.497-1.493-3.462-27.641-3.533-29.225-.195-4.355-1.768-17.469 2.126-18.222z"
        ></path>
        <path
          id="forearm-left1"
          fill={colorsSkin.light}
          d="M114.564 156.787l.067-.002c4.241-.131 7.919 4.578 8.215 10.517l2.223 44.6c.295 5.94-2.903 10.861-7.145 10.992l-.066.002c-4.241.131-7.92-4.578-8.216-10.518l-2.222-44.6c-.296-5.94 2.903-10.861 7.144-10.991z"
        ></path>
        <g id="hand-left" fill={colorsSkin.light}>
          <path d="M114.657 202.739l2.665-.015c5.109-.027 9.492 5.517 9.789 12.383l.265 6.1c.297 6.866-3.603 12.454-8.711 12.481l-2.666.015c-5.109.027-9.491-5.517-9.789-12.383l-.264-6.1c-.298-6.866 3.602-12.454 8.711-12.481z"></path>
          <path d="M113.998 220.089c1.111-4.367 5.03-6.578 8.752-4.939 3.723 1.639 5.839 6.508 4.728 10.874l-3.036 11.926c-1.111 4.366-5.03 6.577-8.752 4.938-3.722-1.639-5.839-6.507-4.728-10.874l3.036-11.925z"></path>
        </g>
        <path
          id="thumb-left"
          fill={colorsSkin.strong}
          d="M105.284 217.757c-.125-3.044 1.581-5.522 3.812-5.536 2.231-.014 4.141 2.443 4.266 5.487l.343 8.313c.126 3.044-1.581 5.523-3.811 5.537-2.231.013-4.141-2.443-4.267-5.487l-.343-8.314z"
        ></path>
      </g>
    </g>
    <g transform="matrix(.7295 0 0 1 18.421 0)">
      <path
        id="shoulders"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M29.534 115.593h77.272c7.658 0 13.823 5.745 13.823 12.882s-6.165 12.882-13.823 12.882H29.534c-7.658 0-13.823-5.745-13.823-12.882s6.165-12.882 13.823-12.882z"
      ></path>
    </g>
    <g transform="matrix(.7295 0 0 1 18.421 0)">
      <path
        id="bust"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M56.05 114.065h24.095c3.728 0 7.254.758 10.405 2.116 8.229 3.545 13.897 11.178 13.897 20.064 0 12.287-10.838 22.179-24.302 22.179H56.05c-13.463 0-24.302-9.892-24.302-22.179 0-12.288 10.839-22.18 24.302-22.18z"
      ></path>
    </g>
    <g transform="matrix(.7295 0 0 1 18.421 0)">
      <path
        id="breasts-back"
        fill={colorsSkin.strong}
        fillOpacity="0.99"
        fillRule="nonzero"
        d="M68.738 141.357a5.648 5.648 0 015.659 5.66v5.249a5.647 5.647 0 01-5.659 5.66 5.647 5.647 0 01-5.66-5.66v-5.249a5.648 5.648 0 015.66-5.66z"
      ></path>
    </g>
    <g transform="matrix(.7295 0 0 1 18.421 0)">
      <path
        id="breasts"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M54.444 135.418a8.105 8.105 0 00-4.042 1.053c-3.843.216-7.446 1.666-10.078 4.055-2.632 2.389-4.097 5.539-4.097 8.812 0 3.42 1.599 6.701 4.446 9.119 2.846 2.419 6.707 3.777 10.733 3.777 3.667-.003 7.208-1.135 9.97-3.185 2.761-2.05 4.556-4.88 5.052-7.968a5.59 5.59 0 00.157-1.312v-8.015c0-3.51-3.262-6.336-7.313-6.336h-4.828zm22.482 0c-4.05 0-7.312 2.826-7.312 6.336v8.015c0 .45.055.889.157 1.312.496 3.088 2.291 5.918 5.052 7.968 2.761 2.05 6.303 3.182 9.97 3.185 4.026 0 7.886-1.358 10.733-3.777 2.846-2.418 4.446-5.699 4.446-9.119-.001-3.273-1.465-6.423-4.098-8.812-2.632-2.389-6.234-3.839-10.077-4.055a8.106 8.106 0 00-4.043-1.053h-4.828z"
      ></path>
    </g>
    {!cloth && (
      <g id="topwear">
        <path
          fill="#4F4F4F"
          fillOpacity="0.99"
          fillRule="nonzero"
          d="M67.966 106.844c1.352 0 2.699.074 4.034.218 16.102 1.737 30.453 13.632 30.453 28.139l-10.108 15.532H45.384l-9.292-15.532c0-15.71 14.216-28.357 31.874-28.357z"
          transform="matrix(.76586 0 0 1 16.086 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M56.05 114.065h24.095c13.464 0 24.302 9.892 24.302 22.18 0 5.205 1.222 10.713-2.832 14.488-5.135 4.782-13.709 7.691-21.47 7.691H56.05c-7.384 0-16.91-2.976-21.36-7.691-3.663-3.881-2.942-8.94-2.942-14.488 0-12.288 10.839-22.18 24.302-22.18z"
          transform="matrix(.7295 0 0 1 18.421 0)"
        ></path>
      </g>
    )}
    <g id="shorts">
      <path
        fill="#4F4F4F"
        fillRule="nonzero"
        d="M39.689 189.941c-2.327 22.986 13.516 40.331 28.407 40.331 15.086 0 34.141-17.298 31.085-40.331H39.689z"
        transform="matrix(.84644 0 0 1 9.364 0) matrix(1.11777 0 0 1 -8.18 0)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M103.263 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.171-17.111-2.871-27.931z"
        transform="matrix(.84644 0 0 1 9.364 0)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M103.755 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.664-17.111-2.379-27.931z"
        transform="matrix(.84644 0 0 1 9.364 0) matrix(-1 0 0 1 139.256 0)"
      ></path>
    </g>
    <g transform="translate(65.978 180.057)">
      <path
        id="navel"
        fill={colorsSkin.strong}
        d="M3.426 1.376a1.262 1.262 0 00-2.522 0v2.347a1.261 1.261 0 002.522 0V1.376z"
      ></path>
    </g>
    <g transform="matrix(.81837 0 0 1 48.868 169.092)">
      <path
        id="belly"
        fill={colorsSkin.strong}
        fillRule="nonzero"
        d="M46.87 5.592C24.159.713.82 5.592.82 5.592.82 4.488 11.735.713 24.159.713S46.87 4.488 46.87 5.592z"
      ></path>
    </g>
  </g>
);

export const getBEnSkinFem = (
  colorsSkin: IColorSkin,
  cloth: boolean
): React.SVGProps<SVGGElement> => (
  <g transform="translate(3.401 -10.072)">
    <g id="legs" transform="matrix(.8863 0 0 1 7.742 0)">
      <g id="leg-left" transform="matrix(1.13121 0 0 1 -131.344 16.658)">
        <path
          id="tibia-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-1.112-24.01-1.112-26.082v-10.717a6.984 6.984 0 016.98-6.98z"
          transform="translate(-.426 -.667)"
        ></path>
        <path
          id="thigh-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M191.234 175.194h7.531c1.941 0 7.246.163 7.286.447.941 7.881 2.217 7.64 2.594 29.149 0 0-.111 11.238-1.417 20.721-.98 7.115-4.868 19.42-6.462 22.229 0 0-2.293 4.918-2.293 6.087 0 3.73.495 8.854-5.006 8.768h-4.452c-3.995 0-9.246.016-9.246-3.98 0-.862-.163-10.669-.399-11.843l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
          transform="translate(.852 -.667)"
        ></path>
        <g id="foot-left" transform="translate(-2.556) translate(.852 -.667)">
          <path
            id="color3"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
          ></path>
          <path
            id="color31"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
          ></path>
        </g>
      </g>
      <g id="leg-right" transform="matrix(1.13121 0 0 1 -130.38 14.992)">
        <path
          id="tibia-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-1.112-24.01-1.112-26.082v-10.717a6.984 6.984 0 016.98-6.98z"
          transform="matrix(-1 0 0 1 352.135 0)"
        ></path>
        <path
          id="thigh-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M191.234 175.194h7.531c1.941 0 7.246.163 7.286.447.941 7.881 2.217 7.64 2.594 29.149 0 0-.111 11.238-1.417 20.721-.98 7.115-4.868 19.42-6.462 22.229 0 0-2.293 4.918-2.293 6.087 0 3.73.495 8.854-5.006 8.768h-4.452c-3.995 0-9.246.016-9.246-3.98 0-.862-.163-10.669-.399-11.843l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
          transform="matrix(-1 0 0 1 351.162 1)"
        ></path>
        <g
          id="foot-left1"
          transform="translate(-33.012 1.314) translate(.852 -.667)"
        >
          <path
            id="color32"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
          ></path>
          <path
            id="color33"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
          ></path>
        </g>
      </g>
    </g>
    <g id="arms" transform="matrix(.8863 0 0 1 7.742 0)">
      <g id="arm-right" transform="matrix(.82307 0 0 1 13.177 0)">
        <path
          id="bicep-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M106.462 123.095c5.175-1.001 11.84 6.466 12.98 12.361 0 0 3.368 30.511 3.336 32.875-.057 4.141-1.945 7.178-6.027 7.967-4.457.863-7.418-1.357-8.882-5.756-.497-1.493-3.462-27.641-3.533-29.225-.195-4.355-1.768-17.469 2.126-18.222z"
          transform="matrix(-1 0 0 1 135.265 -1)"
        ></path>
        <path
          id="forearm-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M114.564 156.787l.067-.002c4.241-.131 7.919 4.578 8.215 10.517l2.223 44.6c.295 5.94-2.903 10.861-7.145 10.992l-.066.002c-4.241.131-7.92-4.578-8.216-10.518l-2.222-44.6c-.296-5.94 2.903-10.861 7.144-10.991z"
          transform="matrix(-1 0 0 1 135.265 -1)"
        ></path>
        <g id="hand-right" fill={colorsSkin.light} fillRule="nonzero">
          <path d="M21.541 202.739l-2.666-.015c-5.109-.027-9.491 5.517-9.789 12.383l-.264 6.1c-.298 6.866 3.602 12.454 8.711 12.481l2.665.015c5.109.027 9.491-5.517 9.789-12.383l.265-6.1c.297-6.866-3.603-12.454-8.711-12.481z"></path>
          <path d="M22.199 220.089c-1.111-4.367-5.03-6.578-8.752-4.939-3.722 1.639-5.839 6.508-4.728 10.874l3.036 11.926c1.111 4.366 5.03 6.577 8.752 4.938 3.723-1.639 5.839-6.507 4.728-10.874l-3.036-11.925z"></path>
        </g>
        <path
          id="thumb-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M30.913 217.757c.125-3.044-1.581-5.522-3.812-5.536-2.23-.014-4.14 2.443-4.266 5.487l-.343 8.313c-.126 3.044 1.581 5.523 3.812 5.537 2.23.013 4.14-2.443 4.266-5.487l.343-8.314z"
        ></path>
      </g>
      <g
        id="arm-left"
        fill={colorsSkin.light}
        fillRule="nonzero"
        transform="matrix(.82307 0 0 1 12.049 0)"
      >
        <path
          id="bicep-left1"
          d="M106.462 123.095c5.175-1.001 11.84 6.466 12.98 12.361 0 0 3.368 30.511 3.336 32.875-.057 4.141-1.945 7.178-6.027 7.967-4.457.863-7.418-1.357-8.882-5.756-.497-1.493-3.462-27.641-3.533-29.225-.195-4.355-1.768-17.469 2.126-18.222z"
        ></path>
        <path
          id="forearm-left1"
          d="M114.564 156.787l.067-.002c4.241-.131 7.919 4.578 8.215 10.517l2.223 44.6c.295 5.94-2.903 10.861-7.145 10.992l-.066.002c-4.241.131-7.92-4.578-8.216-10.518l-2.222-44.6c-.296-5.94 2.903-10.861 7.144-10.991z"
        ></path>
        <g id="hand-left">
          <path d="M114.657 202.739l2.665-.015c5.109-.027 9.492 5.517 9.789 12.383l.265 6.1c.297 6.866-3.603 12.454-8.711 12.481l-2.666.015c-5.109.027-9.491-5.517-9.789-12.383l-.264-6.1c-.298-6.866 3.602-12.454 8.711-12.481z"></path>
          <path d="M113.998 220.089c1.111-4.367 5.03-6.578 8.752-4.939 3.723 1.639 5.839 6.508 4.728 10.874l-3.036 11.926c-1.111 4.366-5.03 6.577-8.752 4.938-3.722-1.639-5.839-6.507-4.728-10.874l3.036-11.925z"></path>
        </g>
        <path
          id="thumb-left"
          d="M105.284 217.757c-.125-3.044 1.581-5.522 3.812-5.536 2.231-.014 4.141 2.443 4.266 5.487l.343 8.313c.126 3.044-1.581 5.523-3.811 5.537-2.231.013-4.141-2.443-4.267-5.487l-.343-8.314z"
        ></path>
      </g>
    </g>
    <g transform="matrix(.85232 0 0 1 10.186 0)">
      <path
        id="deltoids"
        fill={colorsSkin.strong}
        fillOpacity="0.99"
        fillRule="nonzero"
        d="M36.092 135.201c0-15.71 66.361-14.507 66.361 0l-2.528 15.532 5.425 39.452c0 15.71-19.726 5.315-37.384 5.315s-37.508 10.395-37.508-5.315l5.634-39.452v-15.532z"
      ></path>
      <path
        id="deltoids1"
        fill={colorsSkin.light}
        fillOpacity="0.99"
        fillRule="nonzero"
        d="M67.966 106.844c1.352 0 2.699.074 4.034.218 16.102 1.737 30.453 13.632 30.453 28.139l-2.528 15.532 5.425 39.452c0 15.71-19.726 5.315-37.384 5.315s-37.508 10.395-37.508-5.315l5.634-39.452v-15.532c0-15.71 14.216-28.357 31.874-28.357z"
        transform="matrix(.94632 0 0 1 3.645 0)"
      ></path>
    </g>
    <g transform="matrix(.95634 0 0 1 2.97 0)">
      <path
        id="trunk"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M67.925 170.441h1.023c16.943 0 22.442-8.764 25.223 3.114l3.57 16.63c.572 2.443.406 5.104-.365 7.792-2.975 10.373-14.972 21.131-28.428 21.131h-1.023c-8.563 0-16.712-4.418-22.32-10.321-2.314-2.434-4.194-5.121-5.494-7.854-1.784-3.754-2.472-7.595-1.677-10.992l4.832-16.386c2.781-11.878 7.716-3.114 24.659-3.114z"
      ></path>
    </g>
    <g transform="matrix(.7295 0 0 1 18.421 0)">
      <path
        id="shoulders"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M29.534 115.593h77.272c7.658 0 13.823 5.745 13.823 12.882s-6.165 12.882-13.823 12.882H29.534c-7.658 0-13.823-5.745-13.823-12.882s6.165-12.882 13.823-12.882z"
      ></path>
    </g>
    <g transform="matrix(.78403 0 0 1 15.475 0)">
      <path
        id="bust"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M56.05 114.065h24.095c13.464 0 24.302 9.892 24.302 22.18 0 12.287-10.838 22.179-24.302 22.179H56.05c-13.463 0-24.302-9.892-24.302-22.179 0-12.288 10.839-22.18 24.302-22.18z"
      ></path>
    </g>
    <g transform="matrix(.7295 0 0 1 18.421 0)">
      <path
        id="breasts-back"
        fill={colorsSkin.strong}
        fillOpacity="0.99"
        fillRule="nonzero"
        d="M68.738 141.357a5.648 5.648 0 015.659 5.66v5.249a5.647 5.647 0 01-5.659 5.66 5.647 5.647 0 01-5.66-5.66v-5.249a5.648 5.648 0 015.66-5.66z"
      ></path>
    </g>
    <g transform="matrix(.8439 0 0 1 10.754 0)">
      <path
        id="breasts"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M54.444 135.418a8.105 8.105 0 00-4.042 1.053c-3.843.216-7.446 1.666-10.078 4.055-2.632 2.389-4.097 5.539-4.097 8.812 0 3.42 1.599 6.701 4.446 9.119 2.846 2.419 6.707 3.777 10.733 3.777 3.667-.003 7.208-1.135 9.97-3.185 2.761-2.05 4.556-4.88 5.052-7.968a5.59 5.59 0 00.157-1.312v-8.015c0-3.51-3.262-6.336-7.313-6.336h-4.828zm22.482 0c-4.05 0-7.312 2.826-7.312 6.336v8.015c0 .45.055.889.157 1.312.496 3.088 2.291 5.918 5.052 7.968 2.761 2.05 6.303 3.182 9.97 3.185 4.026 0 7.886-1.358 10.733-3.777 2.846-2.418 4.446-5.699 4.446-9.119-.001-3.273-1.465-6.423-4.098-8.812-2.632-2.389-6.234-3.839-10.077-4.055a8.106 8.106 0 00-4.043-1.053h-4.828z"
      ></path>
    </g>
    <g transform="translate(65.978 183.119)">
      <path
        id="navel"
        fill={colorsSkin.strong}
        d="M3.426 1.376a1.262 1.262 0 00-2.522 0v2.347a1.261 1.261 0 002.522 0V1.376z"
      ></path>
    </g>
    <g transform="matrix(1.00002 0 0 1 44.537 171.154)">
      <path
        id="belly"
        fill={colorsSkin.strong}
        fillRule="nonzero"
        d="M46.87 5.592C24.159.713.82 5.592.82 5.592.82 4.488 11.735.713 24.159.713S46.87 4.488 46.87 5.592z"
      ></path>
    </g>
    {!cloth && (
      <g id="topwear">
        <path
          fill="#4F4F4F"
          fillOpacity="0.99"
          fillRule="nonzero"
          d="M67.966 106.844c1.352 0 2.699.074 4.034.218 16.102 1.737 30.453 13.632 30.453 28.139l-10.108 15.532H45.384l-9.292-15.532c0-15.71 14.216-28.357 31.874-28.357z"
          transform="matrix(1.07607 0 0 1.05937 -4.98 -6.343) matrix(.76586 0 0 1 16.086 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M56.05 114.065h24.095c13.464 0 24.302 9.892 24.302 22.18 0 5.205 1.222 10.713-2.832 14.488-5.135 4.782-13.709 7.691-21.47 7.691H56.05c-7.384 0-16.91-2.976-21.36-7.691-3.663-3.881-2.942-8.94-2.942-14.488 0-12.288 10.839-22.18 24.302-22.18z"
          transform="matrix(1.07607 0 0 1.05937 -4.98 -6.343) matrix(.7295 0 0 1 18.421 0)"
        ></path>
      </g>
    )}
    <g id="underwear">
      <path
        fill="#4F4F4F"
        fillRule="nonzero"
        d="M39.689 189.941c-2.327 22.986 13.516 40.331 28.407 40.331 15.086 0 34.141-17.298 31.085-40.331H39.689z"
        transform="matrix(.93814 0 0 1 2.983 0) matrix(1.11777 0 0 1 -8.18 0)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M103.263 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.171-17.111-2.871-27.931z"
        transform="matrix(.93814 0 0 1 2.983 0)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M103.755 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.664-17.111-2.379-27.931z"
        transform="matrix(.93814 0 0 1 2.983 0) matrix(-1 0 0 1 139.256 0)"
      ></path>
    </g>
  </g>
);

export const getMEnSkinFem = (
  colorsSkin: IColorSkin,
  cloth: boolean
): React.SVGProps<SVGGElement> => (
  <g transform="translate(3.522 -10.066)">
    <g id="legs" transform="matrix(.91758 0 0 1 5.608 0)">
      <g id="leg-left" transform="matrix(1.13121 0 0 1 -131.344 16.658)">
        <path
          id="tibia-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-1.112-24.01-1.112-26.082v-10.717a6.984 6.984 0 016.98-6.98z"
          transform="translate(-.426 -.667)"
        ></path>
        <path
          id="thigh-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M191.234 175.194h7.531c1.941 0 7.246.163 7.286.447.941 7.881 2.217 7.64 2.594 29.149 0 0-.111 11.238-1.417 20.721-.98 7.115-4.868 19.42-6.462 22.229 0 0-2.293 4.918-2.293 6.087 0 3.73.495 8.854-5.006 8.768h-4.452c-3.995 0-9.246.016-9.246-3.98 0-.862-.163-10.669-.399-11.843l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
          transform="translate(.852 -.667)"
        ></path>
        <g id="foot-left" transform="translate(-2.556) translate(.852 -.667)">
          <path
            id="color3"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
          ></path>
          <path
            id="color31"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
          ></path>
        </g>
      </g>
      <g id="leg-right" transform="matrix(1.13121 0 0 1 -130.38 14.992)">
        <path
          id="tibia-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-1.112-24.01-1.112-26.082v-10.717a6.984 6.984 0 016.98-6.98z"
          transform="matrix(-1 0 0 1 352.135 0)"
        ></path>
        <path
          id="thigh-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M191.234 175.194h7.531c1.941 0 7.246.163 7.286.447.941 7.881 2.217 7.64 2.594 29.149 0 0-.111 11.238-1.417 20.721-.98 7.115-4.868 19.42-6.462 22.229 0 0-2.293 4.918-2.293 6.087 0 3.73.495 8.854-5.006 8.768h-4.452c-3.995 0-9.246.016-9.246-3.98 0-.862-.163-10.669-.399-11.843l-1.474-7.336-1.417-9.349a31.478 31.478 0 01-.355-4.711v-35.072c0-8.345 6.765-15.11 15.11-15.11z"
          transform="matrix(-1 0 0 1 351.162 1)"
        ></path>
        <g
          id="foot-left1"
          transform="translate(-33.012 1.314) translate(.852 -.667)"
        >
          <path
            id="color32"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
          ></path>
          <path
            id="color33"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
          ></path>
        </g>
      </g>
    </g>
    <g transform="matrix(.85232 0 0 1 10.186 0)">
      <path
        id="deltoids"
        fill={colorsSkin.light}
        fillOpacity="0.99"
        fillRule="nonzero"
        d="M67.966 106.844c1.352 0 2.699.074 4.034.218 16.102 1.737 30.453 13.632 30.453 28.139l-2.528 15.532 5.425 39.452c0 15.71-19.726 5.315-37.384 5.315s-37.508 10.395-37.508-5.315l5.634-39.452v-15.532c0-15.71 14.216-28.357 31.874-28.357z"
      ></path>
    </g>
    <g transform="matrix(.95634 0 0 1 2.97 0)">
      <path
        id="trunk"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M67.925 170.441h1.023c16.943 0 22.442-8.764 25.223 3.114l3.57 16.63c.572 2.443.406 5.104-.365 7.792-2.975 10.373-14.972 21.131-28.428 21.131h-1.023c-8.563 0-16.712-4.418-22.32-10.321-2.314-2.434-4.194-5.121-5.494-7.854-1.784-3.754-2.472-7.595-1.677-10.992l4.832-16.386c2.781-11.878 7.716-3.114 24.659-3.114z"
      ></path>
    </g>
    <g transform="matrix(.7295 0 0 1 18.421 0)">
      <path
        id="breasts-back"
        fill={colorsSkin.strong}
        fillOpacity="0.99"
        fillRule="nonzero"
        d="M68.738 141.357a5.648 5.648 0 015.659 5.66v5.249a5.647 5.647 0 01-5.659 5.66 5.647 5.647 0 01-5.66-5.66v-5.249a5.648 5.648 0 015.66-5.66z"
      ></path>
    </g>
    <g transform="matrix(.8439 0 0 1 10.754 -2)">
      <path
        id="breasts"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M54.444 135.418a8.105 8.105 0 00-4.042 1.053c-3.843.216-7.446 1.666-10.078 4.055-2.632 2.389-4.097 5.539-4.097 8.812 0 3.42 1.599 6.701 4.446 9.119 2.846 2.419 6.707 3.777 10.733 3.777 3.667-.003 7.208-1.135 9.97-3.185 2.761-2.05 4.556-4.88 5.052-7.968a5.59 5.59 0 00.157-1.312v-8.015c0-3.51-3.262-6.336-7.313-6.336h-4.828zm22.482 0c-4.05 0-7.312 2.826-7.312 6.336v8.015c0 .45.055.889.157 1.312.496 3.088 2.291 5.918 5.052 7.968 2.761 2.05 6.303 3.182 9.97 3.185 4.026 0 7.886-1.358 10.733-3.777 2.846-2.418 4.446-5.699 4.446-9.119-.001-3.273-1.465-6.423-4.098-8.812-2.632-2.389-6.234-3.839-10.077-4.055a8.106 8.106 0 00-4.043-1.053h-4.828z"
      ></path>
    </g>
    <g id="arms" transform="matrix(1.03227 0 0 1 -2.198 0)">
      <g id="arm-right" transform="translate(3.063 .3)">
        <path
          id="bicep-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M106.462 123.095c5.175-1.001 11.406 1.097 12.546 6.992 0 0 3.802 35.88 3.77 38.244-.057 4.141-1.945 7.178-6.027 7.967-4.457.863-7.418-1.357-8.882-5.756-.497-1.493-5.499-28.339-5.499-28.339.142-1.58.376-3.53.305-5.114-.195-4.355-.107-13.241 3.787-13.994z"
          transform="matrix(-1 0 0 1 135.389 0)"
        ></path>
        <path
          id="forearm-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M114.564 156.787l.067-.002c4.241-.131 7.919 4.578 8.215 10.517l2.223 44.6c.295 5.94-2.903 10.861-7.145 10.992l-.066.002c-4.241.131-7.92-4.578-8.216-10.518l-2.222-44.6c-.296-5.94 2.903-10.861 7.144-10.991z"
          transform="matrix(-1 0 0 1 135.389 0)"
        ></path>
        <g id="hand-right" fill={colorsSkin.light} fillRule="nonzero">
          <path d="M21.541 202.739l-2.666-.015c-5.109-.027-9.491 5.517-9.789 12.383l-.264 6.1c-.298 6.866 3.602 12.454 8.711 12.481l2.665.015c5.109.027 9.491-5.517 9.789-12.383l.265-6.1c.297-6.866-3.603-12.454-8.711-12.481z"></path>
          <path d="M22.199 220.089c-1.111-4.367-5.03-6.578-8.752-4.939-3.722 1.639-5.839 6.508-4.728 10.874l3.036 11.926c1.111 4.366 5.03 6.577 8.752 4.938 3.723-1.639 5.839-6.507 4.728-10.874l-3.036-11.925z"></path>
        </g>
        <path
          id="thumb-right"
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M30.913 217.757c.125-3.044-1.581-5.522-3.812-5.536-2.23-.014-4.14 2.443-4.266 5.487l-.343 8.313c-.126 3.044 1.581 5.523 3.812 5.537 2.23.013 4.14-2.443 4.266-5.487l.343-8.314z"
        ></path>
      </g>
      <g id="arm-left" fillRule="nonzero" transform="translate(-2.821 2.52)">
        <path
          id="bicep-left"
          fill={colorsSkin.light}
          d="M106.462 123.095c5.175-1.001 11.406 1.097 12.546 6.992 0 0 3.802 35.88 3.77 38.244-.057 4.141-1.945 7.178-6.027 7.967-4.457.863-7.418-1.357-8.882-5.756-.497-1.493-5.499-28.339-5.499-28.339.142-1.58.376-3.53.305-5.114-.195-4.355-.107-13.241 3.787-13.994z"
        ></path>
        <path
          id="forearm-left"
          fill={colorsSkin.light}
          d="M114.564 156.787l.067-.002c4.241-.131 7.919 4.578 8.215 10.517l2.223 44.6c.295 5.94-2.903 10.861-7.145 10.992l-.066.002c-4.241.131-7.92-4.578-8.216-10.518l-2.222-44.6c-.296-5.94 2.903-10.861 7.144-10.991z"
        ></path>
        <g id="hand-left" fill={colorsSkin.light}>
          <path d="M114.657 202.739l2.665-.015c5.109-.027 9.492 5.517 9.789 12.383l.265 6.1c.297 6.866-3.603 12.454-8.711 12.481l-2.666.015c-5.109.027-9.491-5.517-9.789-12.383l-.264-6.1c-.298-6.866 3.602-12.454 8.711-12.481z"></path>
          <path d="M113.998 220.089c1.111-4.367 5.03-6.578 8.752-4.939 3.723 1.639 5.839 6.508 4.728 10.874l-3.036 11.926c-1.111 4.366-5.03 6.577-8.752 4.938-3.722-1.639-5.839-6.507-4.728-10.874l3.036-11.925z"></path>
        </g>
        <path
          id="thumb-left"
          fill={colorsSkin.strong}
          d="M105.284 217.757c-.125-3.044 1.581-5.522 3.812-5.536 2.231-.014 4.141 2.443 4.266 5.487l.343 8.313c.126 3.044-1.581 5.523-3.811 5.537-2.231.013-4.141-2.443-4.267-5.487l-.343-8.314z"
        ></path>
      </g>
    </g>
    <g transform="matrix(.95547 0 0 1 2.508 2.627)">
      <path
        id="shoulders"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M29.534 115.593h77.272c7.658 0 13.823 5.745 13.823 12.882s-6.165 12.882-13.823 12.882H29.534c-7.658 0-13.823-5.745-13.823-12.882s6.165-12.882 13.823-12.882z"
      ></path>
    </g>
    <g transform="matrix(.915 0 0 1 5.46 2.627)">
      <path
        id="bust"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M56.05 114.065h24.095c13.464 0 24.302 9.892 24.302 22.18 0 12.287-10.838 22.179-24.302 22.179H56.05c-13.463 0-24.302-9.892-24.302-22.179 0-12.288 10.839-22.18 24.302-22.18z"
      ></path>
    </g>
    <g transform="translate(65.478 183.112)">
      <path
        id="navel"
        fill={colorsSkin.strong}
        d="M3.426 1.376a1.262 1.262 0 00-2.522 0v2.347a1.261 1.261 0 002.522 0V1.376z"
      ></path>
    </g>
    <g transform="matrix(1.00002 0 0 1 44.037 171.148)">
      <path
        id="belly"
        fill={colorsSkin.strong}
        fillRule="nonzero"
        d="M46.87 5.592C24.159.713.82 5.592.82 5.592.82 4.488 11.735.713 24.159.713S46.87 4.488 46.87 5.592z"
      ></path>
    </g>
    <g id="underwear">
      <path
        fill="#4F4F4F"
        fillRule="nonzero"
        d="M39.689 189.941c-2.327 22.986 13.516 40.331 28.407 40.331 15.086 0 34.141-17.298 31.085-40.331H39.689z"
        transform="matrix(.93814 0 0 1 2.983 0) matrix(1.11777 0 0 1 -8.18 0)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M103.263 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.171-17.111-2.871-27.931z"
        transform="matrix(.93814 0 0 1 2.983 0)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M103.755 189.941L69.117 194.4l1.129 36.1c-.562 5.119-.507 10.387.391 15.505l33.011.097c2.54-12.18 2.273-20.433 2.486-28.23.27-9.881.664-17.111-2.379-27.931z"
        transform="matrix(.93814 0 0 1 2.983 0) matrix(-1 0 0 1 139.256 0)"
      ></path>
    </g>
    {!cloth && (
      <g id="topwear">
        <path
          fill="#4F4F4F"
          fillOpacity="0.99"
          fillRule="nonzero"
          d="M67.966 106.844c1.352 0 2.699.074 4.034.218 16.102 1.737 30.453 13.632 30.453 28.139l-10.108 15.532H45.384l-9.292-15.532c0-15.71 14.216-28.357 31.874-28.357z"
          transform="matrix(1.42013 0 0 1.09309 -28.924 -9.952) matrix(.70584 0 0 1 19.192 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M56.05 114.065h24.095c13.464 0 24.52 9.799 24.305 22.085-.271 15.513-16.544 22.274-24.305 22.274H56.05c-7.384 0-24.565-7.185-24.302-22.179.216-12.286 10.839-22.18 24.302-22.18z"
          transform="matrix(1.42013 0 0 1.09309 -28.924 -9.952) matrix(.65715 0 0 1 22.866 0)"
        ></path>
      </g>
    )}
  </g>
);

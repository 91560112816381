export interface IComparisonData {
  group: string;
  gender: string;
  name: string;
  endo: number;
  meso: number;
  ecto: number;
  codeSoma: string;
  imageName?: string;
}

export const comparisonDatas: IComparisonData[] = [
  {
    group: "Tribe",
    gender: "Man",
    name: "Nilote",
    endo: 2,
    meso: 3,
    ecto: 6,
    codeSoma: "MEc",
    imageName: "nilote_man",
  },
  {
    group: "Tribe",
    gender: "Man",
    name: "Manu",
    endo: 2,
    meso: 7,
    ecto: 2,
    codeSoma: "BM",
    imageName: "manu_man",
  },
  {
    group: "Tribe",
    gender: "Woman",
    name: "Manu",
    endo: 2,
    meso: 5,
    ecto: 2,
    codeSoma: "BM",
    imageName: "manu_woman",
  },
  {
    group: "Tribe",
    gender: "Man",
    name: "Eskimo",
    endo: 4,
    meso: 6,
    ecto: 2,
    codeSoma: "EnM",
    imageName: "eskimo_man",
  },
  {
    group: "Tribe",
    gender: "Woman",
    name: "Eskimo",
    endo: 6,
    meso: 4,
    ecto: 1,
    codeSoma: "MEn",
    imageName: "eskimo_woman",
  },
  {
    group: "Tribe",
    gender: "Man",
    name: "Brahmin",
    endo: 2,
    meso: 4,
    ecto: 4,
    codeSoma: "EcM",
    imageName: "brahmin_man",
  },
  {
    group: "Sport",
    gender: "Man",
    name: "Rugby",
    endo: 3,
    meso: 6,
    ecto: 1,
    codeSoma: "EnM",
  },
  {
    group: "Sport",
    gender: "Man",
    name: "Weightlifting",
    endo: 2,
    meso: 7,
    ecto: 1,
    codeSoma: "EnM",
  },
  {
    group: "Sport",
    gender: "Man",
    name: "Football",
    endo: 2,
    meso: 5,
    ecto: 2,
    codeSoma: "BM",
  },
  {
    group: "Sport",
    gender: "Man",
    name: "Sumo",
    endo: 5,
    meso: 6,
    ecto: 1,
    codeSoma: "EnM",
    imageName: "sumo_man",
  },
  {
    group: "Sport",
    gender: "Woman",
    name: "Sumo",
    endo: 7,
    meso: 5,
    ecto: 1,
    codeSoma: "MEn",
    imageName: "sumo_woman",
  },
  {
    group: "Sport",
    gender: "Man",
    name: "Bodybuilder",
    endo: 1,
    meso: 9,
    ecto: 1,
    codeSoma: "BM",
  },
  {
    group: "Sport",
    gender: "Woman",
    name: "Bodybuilder",
    endo: 2,
    meso: 5,
    ecto: 2,
    codeSoma: "BM",
  },
  {
    group: "Sport",
    gender: "Woman",
    name: "Rower",
    endo: 3,
    meso: 5,
    ecto: 3,
    codeSoma: "BM",
    imageName: "rower_woman",
  },
  {
    group: "Sport",
    gender: "Man",
    name: "Gymnast",
    endo: 1,
    meso: 6,
    ecto: 2,
    codeSoma: "EcM",
  },
  {
    group: "Sport",
    gender: "Man",
    name: "Track Cyclist",
    endo: 2,
    meso: 5,
    ecto: 3,
    codeSoma: "EcM",
    imageName: "track_cyclist_man",
  },
  {
    group: "Sport",
    gender: "Woman",
    name: "Swimmer",
    endo: 2,
    meso: 4,
    ecto: 4,
    codeSoma: "M-Ec",
  },
  {
    group: "Sport",
    gender: "Man",
    name: "Basketball",
    endo: 2,
    meso: 4,
    ecto: 4,
    codeSoma: "M-Ec",
  },
  {
    group: "Sport",
    gender: "Woman",
    name: "Basketball",
    endo: 4,
    meso: 4,
    ecto: 3,
    codeSoma: "C",
  },
  {
    group: "Sport",
    gender: "Man",
    name: "Golf",
    endo: 4,
    meso: 6,
    ecto: 2,
    codeSoma: "EnM",
    imageName: "golfer_man",
  },
  {
    group: "Sport",
    gender: "Man",
    name: "Crossfit",
    endo: 2,
    meso: 7,
    ecto: 1,
    codeSoma: "EnM",
  },
  {
    group: "Sport",
    gender: "Woman",
    name: "Crossfit",
    endo: 2,
    meso: 5,
    ecto: 2,
    codeSoma: "BM",
  },
  {
    group: "Occupation",
    gender: "Man",
    name: "Designer",
    endo: 2,
    meso: 4,
    ecto: 3,
    codeSoma: "EcM",
  },
  {
    group: "Occupation",
    gender: "Woman",
    name: "Student",
    endo: 4,
    meso: 3,
    ecto: 3,
    codeSoma: "C",
  },
  {
    group: "Occupation",
    gender: "Man",
    name: "Executive",
    endo: 5,
    meso: 4,
    ecto: 2,
    codeSoma: "MEn",
  },
  {
    group: "Occupation",
    gender: "Man",
    name: "Salesman",
    endo: 3,
    meso: 4,
    ecto: 2,
    codeSoma: "C",
  },
  {
    group: "Occupation",
    gender: "Woman",
    name: "Accountant",
    endo: 4,
    meso: 3,
    ecto: 2,
    codeSoma: "MEn",
  },
];

import { IColorSkin } from "../../variablesAvatar/VariableAvatar";

// Mesomorphic Ectomorph
export const getCSkin = (
  colorsSkin: IColorSkin
): React.SVGProps<SVGGElement> => (
  <g transform="translate(-105.407 -9.704)">
    <g id="arms">
      <g id="arm-left" transform="matrix(.78241 0 0 1 36.705 -3.418)">
        <path
          id="bicep-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M218.65 125.151c.118-1.179 8.954-18.472 10.633-15.208l6.676 4.452c.629 1.223 1.047 23.259.415 26.827l-.314 6.517c-.166.936-.25 1.865-.25 2.775v2.21c0 1.06-.071 11.81-.029 11.745 0 0-14.493.094-14.655-.174l-1.04-19.084c-.931-3.331-1.786-18.831-1.786-18.831l.35-1.229z"
          transform="translate(-2.982 -.667)"
        ></path>
        <path
          id="forearm-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M227.659 138.035l.067.003c4.278.19 7.825 5.194 7.921 11.178l.725 44.928c.096 5.983-3.294 10.68-7.572 10.49l-.067-.003c-4.278-.19-7.824-5.194-7.921-11.177l-.724-44.928c-.097-5.984 3.293-10.68 7.571-10.491z"
          transform="translate(-2.982 -.667)"
        ></path>
        <g id="hand-left" transform="translate(-1.704 -.667)">
          <path
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M225.638 185.911l2.264.203c4.338.39 7.918 6.342 7.996 13.294l.069 6.176c.078 6.952-3.376 12.271-7.714 11.881l-2.263-.204c-4.244-.381-7.761-6.084-7.986-12.839a17.171 17.171 0 01-.01-.455l-.07-6.175c-.078-6.952 3.376-12.271 7.714-11.881z"
          ></path>
          <path
            id="color"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M224.158 203.261c1.1-4.265 4.696-6.117 8.032-4.137 3.336 1.981 5.148 7.044 4.048 11.31l-3.006 11.649c-1.099 4.265-4.696 6.117-8.031 4.136-3.336-1.981-5.148-7.044-4.048-11.309l3.005-11.649z"
          ></path>
        </g>
        <path
          id="thumb-left"
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M217.066 200.326c-.026-3.072 1.462-5.429 3.322-5.266 1.859.163 3.388 2.786 3.413 5.858l.068 8.39c.025 3.072-1.462 5.43-3.321 5.266-1.86-.163-3.388-2.786-3.413-5.858l-.069-8.39z"
          transform="translate(-1.704 -.667)"
        ></path>
      </g>
      <g id="arm-right" transform="matrix(-.78241 0 0 1 312.961 -3.813)">
        <path
          id="bicep-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M217.644 123.155c.118-1.179 12.592-12.265 14.271-9.001 0 0 3.18-.01 3.431 1.417 0 0 1.66 22.083 1.028 25.651l-.314 6.517c-.166.936-.25 1.865-.25 2.775v2.21c0 1.06-.071 11.81-.029 11.745 0 0-14.493.094-14.655-.174l-1.04-19.084c-.931-3.331-2.463-18.436-2.463-18.436l.021-3.62z"
          transform="translate(-4.687 -.667)"
        ></path>
        <path
          id="forearm-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M227.659 138.035l.067.003c4.278.19 7.825 5.194 7.921 11.178l.725 44.928c.096 5.983-3.294 10.68-7.572 10.49l-.067-.003c-4.278-.19-7.824-5.194-7.921-11.177l-.724-44.928c-.097-5.984 3.293-10.68 7.571-10.491z"
          transform="translate(-4.687 -.667)"
        ></path>
        <g id="hand-right">
          <path
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M226.465 185.911l2.264.203c4.338.39 7.918 6.342 7.996 13.294l.07 6.176c.078 6.952-3.376 12.271-7.715 11.881l-2.263-.204c-4.338-.39-7.918-6.342-7.996-13.294l-.07-6.175c-.078-6.952 3.376-12.271 7.714-11.881z"
            transform="translate(-3.409 -.667)"
          ></path>
          <path
            id="color1"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M224.985 203.261c1.1-4.265 4.696-6.117 8.032-4.137 3.336 1.981 5.148 7.044 4.048 11.31l-3.005 11.649c-1.1 4.265-4.697 6.117-8.032 4.136-3.336-1.981-5.148-7.044-4.047-11.309l3.004-11.649z"
            transform="translate(-4.687 -.667)"
          ></path>
        </g>
        <path
          id="thumb-right"
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M217.893 200.326c-.025-3.072 1.462-5.429 3.322-5.266 1.86.163 3.388 2.786 3.413 5.858l.068 8.39c.025 3.072-1.461 5.43-3.321 5.266-1.86-.163-3.388-2.786-3.413-5.858l-.069-8.39z"
          transform="translate(-4.687 -.667)"
        ></path>
      </g>
    </g>
    <g id="legs">
      <g id="leg-right" transform="matrix(.78241 0 0 1.02452 38.705 -7.337)">
        <path
          id="tibia-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M169.017 259.532c-.582-3.268-18.379-5.165-18.379.066l-.336 21.121.001 17.613-1.894 18.05c0 3.945 3.869 7.142 8.642 7.142h2.016c4.773 0 8.642-3.197 8.642-7.142v-26.356c0-1.835 1.112-24.013 1.112-26.044 0 0 .266-4.054.196-4.45z"
          transform="translate(.852 -.667)"
        ></path>
        <path
          id="thigh-right"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M176.124 190.304c-.186-1.816 27.045-1.424 27.085-1.14l1.803 23.243c.212 1.481.583 16.779.3 18.485 0 0-6.419 28.298-6.395 29.467.047 2.312-18.096 2.519-18.314-.242-.068-.86-4.957-29.225-4.957-29.225-.236-1.559.478-40.588.478-40.588z"
          transform="matrix(-1 0 0 1 350.484 -.943)"
        ></path>
        <g id="foot-right" transform="translate(.852 -.667)">
          <path
            id="color3"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M161.873 308.505h-8.116c-4.68 0-8.473 3.384-8.473 7.558 0 4.174 3.793 7.557 8.473 7.557h8.116c4.679 0 8.473-3.383 8.473-7.557s-3.794-7.558-8.473-7.558z"
          ></path>
          <path
            id="color31"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M167.54 315.333h-19.45c-1.549 0-2.806 1.121-2.806 2.503v3.274c0 1.382 1.257 2.502 2.806 2.502h19.45c1.55 0 2.806-1.12 2.806-2.502v-3.274c0-1.382-1.256-2.503-2.806-2.503z"
          ></path>
        </g>
      </g>
      <g
        id="leg-left"
        transform="matrix(.78241 0 0 1.02697 39.705 -8.126) translate(.852 -.667)"
      >
        <path
          id="tibia-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M188.016 245.119h2.082a9.457 9.457 0 019.456 9.456l.001 25.327-.001 17.983 1.894 18.43c0 4.028-3.869 7.292-8.643 7.292h-2.015c-4.773 0-8.642-3.264-8.642-7.292v-27.417c0-1.764-1.112-24.01-1.112-26.082v-10.717a6.984 6.984 0 016.98-6.98z"
        ></path>
        <path
          id="thigh-left"
          fill={colorsSkin.light}
          fillRule="nonzero"
          d="M176.124 190.304c-.06-1.972 27.736-3.538 27.776-3.254l.108 13.502.288 30.064c0 1.729-4.583 27.943-4.583 29.112 0 3.73-18.677 3.63-18.677-.366 0-.862-4.407-28.746-4.407-28.746-.236-1.559-.505-40.312-.505-40.312z"
        ></path>
        <g id="foot-left">
          <path
            id="color32"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M188.995 308.505h8.115c4.68 0 8.474 3.373 8.474 7.532s-3.794 7.531-8.474 7.531h-8.115c-4.679 0-8.474-3.372-8.474-7.531s3.795-7.532 8.474-7.532z"
          ></path>
          <path
            id="color33"
            fill={colorsSkin.light}
            fillRule="nonzero"
            d="M183.327 311.254h19.451c1.549 0 2.806 1.618 2.806 3.616v4.729c0 1.997-1.257 3.616-2.806 3.616h-19.451c-1.549 0-2.806-1.619-2.806-3.616v-4.729c0-1.998 1.257-3.616 2.806-3.616z"
          ></path>
        </g>
      </g>
    </g>
    <g id="torso">
      <path
        id="trunk"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M206.362 138.849c0-19.513-15.69-27.041-31.609-27.041-15.92 0-30.453 7.528-30.453 27.041l-1.124 38.305c0 19.514 15.657 30.846 31.577 30.846 13.242 0 25.975-7.841 29.884-21.709.79-2.804 1.22-5.855 1.22-9.137l.505-38.305z"
        transform="matrix(.78241 0 0 1 39.372 -.084)"
      ></path>
      <path
        id="shoulders"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M214.151 94.883h-79.544c-8.61 0-15.591 6.98-15.591 15.591 0 8.61 6.981 9.686 15.591 9.686l79.544.991c8.61 0 15.59-2.067 15.59-10.677 0-8.611-6.98-15.591-15.59-15.591z"
        transform="matrix(.78241 0 0 1 39.372 -.084)"
      ></path>
      <path
        id="torso-shadow"
        fill={colorsSkin.strong}
        fillRule="nonzero"
        d="M196.395 94.883h-43.672c-11.267 0-20.401 8.039-20.401 17.957v10.822c0 9.916 9.134 14.367 20.401 14.367h43.672c2.421 0 4.743-.205 6.898-.626 7.873-1.539 13.504-5.956 13.504-13.741V112.84c0-9.918-9.134-17.957-20.402-17.957z"
        transform="matrix(.71233 0 0 1 51.605 -.084)"
      ></path>
      <path
        id="torso1"
        fill={colorsSkin.light}
        fillRule="nonzero"
        d="M200.206 94.883h-51.293c-9.163 0-16.591 7.47-16.591 16.687v10.057c0 9.216 7.428 13.5 16.591 13.5h51.293c9.163 0 16.591-4.284 16.591-13.5V111.57c0-9.217-7.428-16.687-16.591-16.687z"
        transform="matrix(.71233 0 0 1 51.605 -.084)"
      ></path>
      <g
        id="nipples"
        transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
      >
        <path
          id="nipples1"
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M178.089 127.458c0-1.138-1.592-2.06-3.558-2.06-1.964 0-3.557.922-3.557 2.06v3.995c0 1.137 1.593 2.06 3.557 2.06 1.966 0 3.558-.923 3.558-2.06v-3.995z"
        ></path>
        <path
          id="color34"
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M151.932 129.158h-3.021c-1.204 0-2.181.882-2.181 1.97 0 1.086.977 1.968 2.181 1.968h3.021c1.204 0 2.18-.882 2.18-1.968 0-1.088-.976-1.97-2.18-1.97z"
        ></path>
        <path
          id="color35"
          fill={colorsSkin.strong}
          fillRule="nonzero"
          d="M201.112 129.158h-3.02c-1.205 0-2.181.882-2.181 1.97 0 1.086.976 1.968 2.181 1.968h3.02c1.205 0 2.181-.882 2.181-1.968 0-1.088-.976-1.97-2.181-1.97z"
        ></path>
      </g>
      <g transform="translate(174.146 169.704)">
        <path
          id="navel"
          fill={colorsSkin.strong}
          d="M3.426 1.376a1.262 1.262 0 00-2.522 0v2.347a1.261 1.261 0 002.522 0V1.376z"
        ></path>
      </g>
    </g>
    <g id="underwear">
      <path
        fill="#4F4F4F"
        fillRule="nonzero"
        d="M213.083 177.041s-21.885.169-37.999.113c-16.215-.056-38.96-.224-39.656 0 1.623 17.749 24.409 32.272 39.358 32.272 15.145 0 36.933-14.302 38.297-32.385z"
        transform="matrix(.695 0 0 1 126.127 7.498) matrix(.90872 0 0 1 -86.71 -7.583)"
      ></path>
      <path
        fill="#4B4B4B"
        fillRule="nonzero"
        d="M213.079 177.041l-37.995 5.743 1.46 46.917h36.535c.268-14.102 2.537-36.257 0-52.66zM135.428 177.154l39.251 5.63v46.917h-38.06c-.267-14.102-6.018-36.625-1.191-52.547z"
        transform="matrix(.695 0 0 1 126.127 7.498) matrix(.90872 0 0 1 -86.71 -7.583)"
      ></path>
    </g>
  </g>
);

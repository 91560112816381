import { getSpecificColors } from "../../../../datas/Colors";
import { ITypesSkin } from "../../Avatar";

interface IBeard {
  none: React.SVGProps<SVGGElement>;
  short: React.SVGProps<SVGGElement>;
  medium: React.SVGProps<SVGGElement>;
  large: React.SVGProps<SVGGElement>;
}

export const getClothMale = (mainColor: number): ITypesSkin => ({
  BM: (
    <g id="clothes" transform="translate(-104.838 -9.812)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l1.508 40.311h57.693l2.862-40.311z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904h79.891c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-15.903-15.659-15.903h-9.743c-.336 9.733-13.711 17.467-23.379 17.467h-14.347c-9.668 0-21.477-7.734-21.812-17.467h-10.61z"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.203 94.044a1.046 1.046 0 00-.687.226.784.784 0 00-.302.578c-.459 9.13-9.104 16.344-19.813 16.344h-16.726c-10.71 0-19.355-7.214-19.814-16.344a.772.772 0 00-.311-.576 1.083 1.083 0 00-1.056-.14.966.966 0 00-.303.192.824.824 0 00-.194.277.716.716 0 00-.056.319c.501 9.995 10.021 17.93 21.734 17.93h16.726c11.713 0 21.232-7.935 21.734-17.93a.713.713 0 00-.054-.325.805.805 0 00-.197-.283 1.063 1.063 0 00-.681-.268z"
          transform="matrix(1 0 0 .95537 0 5.037)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.89894 0 0 1 15.896 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(1.01412 0 0 1 -2.405 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(1.06155 0 0 1 -9.84 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(1.10436 0 0 1 -16.505 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.9076 0 0 1 17.98 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.359-4.633 8.359-10.349 0-5.717-3.742-10.35-8.359-10.35z"
          transform="matrix(1.01367 0 0 1 -2.468 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(1.05753 0 0 1 -11.445 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(1.10013 0 0 1 -19.504 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
        ></path>
      </g>
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M150.351 177.277c-1.518 0-4.742.026-4.78 0 .02.004.01-.723 0 0-.228 17.31 14.578 32.149 29.215 32.149 15.145 0 27.5-14.066 28.864-32.149h-53.299z"
          transform="matrix(.99674 0 0 1 .459 0) translate(.154)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M203.65 177.277l-28.566 5.507v46.917h35.057c.268-14.102-3.954-36.021-6.491-52.424zM145.615 178.37l.094.013 28.97 4.401v46.917h-34.085c-.267-14.102 2.484-34.928 5.021-51.331z"
          transform="matrix(.99674 0 0 1 .459 0) translate(.154)"
        ></path>
      </g>
    </g>
  ),
  EnM: (
    <g id="clothes" transform="translate(-105.499 -9.812)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l1.508 40.311h57.693l2.862-40.311z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904h79.891c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-15.903-15.659-15.903h-9.743c-.336 9.733-13.711 17.467-23.379 17.467h-14.347c-9.668 0-21.477-7.734-21.812-17.467h-10.61z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.203 94.044a1.046 1.046 0 00-.687.226.784.784 0 00-.302.578c-.459 9.13-9.104 16.344-19.813 16.344h-16.726c-10.71 0-19.355-7.214-19.814-16.344a.772.772 0 00-.311-.576 1.083 1.083 0 00-1.056-.14.966.966 0 00-.303.192.824.824 0 00-.194.277.716.716 0 00-.056.319c.501 9.995 10.021 17.93 21.734 17.93h16.726c11.713 0 21.232-7.935 21.734-17.93a.713.713 0 00-.054-.325.805.805 0 00-.197-.283 1.063 1.063 0 00-.681-.268z"
          transform="matrix(1.05625 0 0 1 -9.173 0) matrix(1 0 0 .95537 0 5.037)"
        ></path>
      </g>
      <g id="short">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M150.351 177.277c-1.518 0-4.858.137-4.777.074 1.623 17.749 14.263 32.075 29.212 32.075 15.145 0 27.228-14.066 28.592-32.149h-53.027z"
          transform="matrix(1.05625 0 0 1 100.26 6.916) translate(-103.451 -6.916)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M203.382 177.277l-28.298 5.507v46.917h35.057c.268-14.102-4.222-36.021-6.759-52.424zM145.573 178.37l29.106 4.414v46.917h-34.085c-.267-14.102 2.442-34.928 4.979-51.331z"
          transform="matrix(1.05625 0 0 1 100.26 6.916) translate(-103.451 -6.916)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(1.05625 0 0 1 -9.173 0) matrix(.90456 0 0 1 15.141 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(1.05625 0 0 1 -9.173 0) matrix(1.01638 0 0 1 -2.79 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(1.05625 0 0 1 -9.173 0) matrix(1.05464 0 0 1 -8.657 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(1.05625 0 0 1 -9.173 0) matrix(1.0964 0 0 1 -15.166 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(1.05625 0 0 1 -9.173 0) matrix(.89406 0 0 1 20.575 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.682-4.633 8.682-10.349 0-5.717-4.065-10.35-8.682-10.35z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(1.05625 0 0 1 -9.173 0) matrix(1.04586 0 0 1 -9.03 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(1.05625 0 0 1 -9.173 0) matrix(1.08799 0 0 1 -17 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
      </g>
    </g>
  ),
  EcM: (
    <g id="clothes" transform="translate(-105.5 -9.982)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l1.464 39.3 57.737 1.011 2.862-40.311z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904h79.891c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-15.903-15.659-15.903h-9.743c-.336 9.733-13.711 17.467-23.379 17.467h-14.347c-9.668 0-21.477-7.734-21.812-17.467h-10.61z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.203 94.044a1.046 1.046 0 00-.687.226.784.784 0 00-.302.578c-.459 9.13-9.104 16.344-19.813 16.344h-16.726c-10.71 0-19.355-7.214-19.814-16.344a.772.772 0 00-.311-.576 1.083 1.083 0 00-1.056-.14.966.966 0 00-.303.192.824.824 0 00-.194.277.716.716 0 00-.056.319c.501 9.995 10.021 17.93 21.734 17.93h16.726c11.713 0 21.232-7.935 21.734-17.93a.713.713 0 00-.054-.325.805.805 0 00-.197-.283 1.063 1.063 0 00-.681-.268z"
          transform="matrix(.9389 0 0 1 11.346 0) matrix(1 0 0 .95537 0 5.037)"
        ></path>
      </g>
      <g id="short">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M150.351 177.277c-1.518 0-4.712.085-4.798.033 1.623 17.749 14.284 32.116 29.233 32.116 15.145 0 27.272-14.074 28.636-32.157l-53.071.008z"
          transform="matrix(.9389 0 0 1 108.62 6.916) translate(-103.451 -6.916)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M203.422 177.269l-28.338 5.515v46.917h35.057c.268-14.102-4.182-36.029-6.719-52.432zM145.553 177.269l29.126 5.515v46.917h-34.085c-.267-14.102 2.422-36.029 4.959-52.432z"
          transform="matrix(.9389 0 0 1 108.62 6.916) translate(-103.451 -6.916)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.9389 0 0 1 11.346 0) matrix(.91458 0 0 1 13.585 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.9389 0 0 1 11.346 0) matrix(1.05744 0 0 1 -9.396 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.9389 0 0 1 11.346 0) matrix(1.09949 0 0 1 -15.949 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.9389 0 0 1 11.346 0) matrix(.91034 0 0 1 17.212 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.359-4.633 8.359-10.349 0-5.717-3.742-10.35-8.359-10.35z"
          transform="matrix(.9389 0 0 1 11.346 0) matrix(1.01367 0 0 1 -2.468 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.9389 0 0 1 11.346 0) matrix(1.04452 0 0 1 -8.658 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.9389 0 0 1 11.346 0) matrix(1.0866 0 0 1 -16.617 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.9389 0 0 1 11.346 0)"
        ></path>
      </g>
    </g>
  ),
  MEc: (
    <g id="clothes" transform="translate(-104.689 -10.397)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l1.4 39.173 57.801 1.138 2.862-40.311z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904h79.891c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-15.903-15.659-15.903h-9.743c-.336 9.733-13.711 17.467-23.379 17.467h-14.347c-9.668 0-21.477-7.734-21.812-17.467h-10.61z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.203 94.044a1.046 1.046 0 00-.687.226.784.784 0 00-.302.578c-.459 9.13-9.104 16.344-19.813 16.344h-16.726c-10.71 0-19.355-7.214-19.814-16.344a.772.772 0 00-.311-.576 1.083 1.083 0 00-1.056-.14.966.966 0 00-.303.192.824.824 0 00-.194.277.716.716 0 00-.056.319c.501 9.995 10.021 17.93 21.734 17.93h16.726c11.713 0 21.232-7.935 21.734-17.93a.713.713 0 00-.054-.325.805.805 0 00-.197-.283 1.063 1.063 0 00-.681-.268z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .95764 0 4.78)"
        ></path>
      </g>
      <g id="short1">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M150.351 177.277c-.008.004-4.864-.056-4.863-.043 1.623 17.749 14.349 32.192 29.298 32.192 15.145 0 27.218-14.111 28.582-32.194l-53.017.045z"
          transform="matrix(.78241 0 0 1 119.767 7.498) translate(-103.451 -6.916)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M203.368 177.232l-28.284 5.552v46.917h35.057c.268-14.102-4.236-36.066-6.773-52.469zM145.488 177.232l29.191 5.552v46.917h-34.085c-.267-14.102 2.357-36.066 4.894-52.469z"
          transform="matrix(.78241 0 0 1 119.767 7.498) translate(-103.451 -6.916)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.92993 0 0 1 10.813 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.02797 0 0 1 -4.765 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.03227 0 0 1 -5.517 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.0739 0 0 1 -11.998 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.89596 0 0 1 19.837 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.359-4.633 8.359-10.349 0-5.717-3.742-10.35-8.359-10.35z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.01389 0 0 1 -2.852 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.05551 0 0 1 -10.98 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.09803 0 0 1 -19.024 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.78241 0 0 1 38.705 .582)"
        ></path>
      </g>
    </g>
  ),
  BEc: (
    <g id="clothes" transform="translate(-105.407 -10.09)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l1.508 40.311h57.693l2.862-40.311z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .83162 .852 17.96)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .78241 .852 23.07)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .80175 .852 20.925)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904l79.81.669c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-16.572-15.659-16.572h-12.218c-.237 16.307-11.155 21.221-20.823 21.221h-14.347c-9.668 0-21.561-7.753-21.812-21.221h-10.61z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .84667 .852 13.881)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.44 94.883c.009-.027-1.43-.005-1.437 0-.195.143-.225.317-.233.536-.459 9.13-8.66 15.773-19.369 15.773h-16.726c-10.71 0-18.154-7.265-19.817-16.309-.014-.077-1.967-.136-1.967 0-.016 10.003 10.071 17.967 21.784 17.967h16.726c11.713 0 20.788-7.364 21.29-17.359a.713.713 0 00-.054-.325c-.043-.105-.23-.181-.197-.283z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.97863 0 0 1 3.949 -.667)"
        ></path>
      </g>
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M150.351 177.277c-1.518 0-3.859.086-3.857.101 1.623 17.749 13.343 32.048 28.292 32.048 15.145 0 27.318-14.083 28.682-32.166l-53.117.017z"
          transform="matrix(.99334 0 0 1 1.338 0) matrix(.79383 0 0 1 37.166 -.084)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M203.535 177.263l-28.451 5.521.886 47.331h30.238c.268-14.102-.136-36.449-2.673-52.852z"
          transform="matrix(.99334 0 0 1 1.338 0) matrix(.80241 0 0 1.00152 35.367 -.357)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M145.665 177.284l29.014 5.5v46.917l-32.904.414c-.267-14.102 1.353-36.428 3.89-52.831z"
          transform="matrix(.99334 0 0 1 1.338 0) matrix(.77199 0 0 1.0099 41.006 -1.863)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.89891 0 0 1 16.8 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.06179 0 0 1 -9.005 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.1046 0 0 1 -15.67 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.90447 0 0 1 19.371 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.359-4.633 8.359-10.349 0-5.717-3.742-10.35-8.359-10.35z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.01367 0 0 1 -1.616 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.04137 0 0 1 -7.148 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.08332 0 0 1 -15.083 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
      </g>
    </g>
  ),
  EnEc: (
    <g id="clothes" transform="translate(-105.407 -9.918)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l-3.141 39.095h67.418l-2.214-39.095z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .83162 .852 17.96)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .78241 .852 23.07)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .80175 .852 20.925)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904l79.81.669c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-16.572-15.659-16.572h-10.94c-.237 16.307-12.433 21.221-22.101 21.221h-14.347c-9.668 0-21.561-7.753-21.812-21.221h-10.61z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .84667 .852 13.881)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.44 94.883c.009-.027-1.43-.005-1.437 0-.195.143-.182.323-.233.536-2.23 9.251-9.411 15.824-19.369 15.773h-16.726c-10.71 0-18.154-7.265-19.817-16.309-.014-.077-1.967-.136-1.967 0-.016 10.003 10.071 17.967 21.784 17.967h16.726c11.713 0 20.788-7.364 21.29-17.359a.713.713 0 00-.054-.325c-.043-.105-.23-.181-.197-.283z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
      </g>
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M212.181 177.041c0-.001-20.983.169-37.097.113a7569.35 7569.35 0 00-37.977 0c1.623 17.749 22.73 32.272 37.679 32.272 15.079 0 39.837-14.464 37.395-32.385z"
          transform="matrix(.695 0 0 1 126.127 7.498) translate(-102.599 -7.583)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M212.181 177.041l-.025.004-37.072 5.739 1.46 46.917h35.972c.268-14.102 2.202-36.257-.335-52.66zM137.107 177.154l37.572 5.63v46.917h-38.06c-.267-14.102-4.339-36.625.488-52.547z"
          transform="matrix(.695 0 0 1 126.127 7.498) translate(-102.599 -7.583)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.91724 0 0 1 14.18 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.01638 0 0 1 -1.938 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.07237 0 0 1 -10.726 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.11562 0 0 1 -17.458 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.91102 0 0 1 19.284 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.09833 0 0 1 -17.004 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.14258 0 0 1 -25.374 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
      </g>
    </g>
  ),
  EcEn: (
    <g id="clothes" transform="translate(-105.144 -10.77)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M205.47 133.16c0-18.726-15.214-14.352-31.134-14.352-15.919 0-30.472-3.861-30.472 14.865l-7.422 43.368h75.571l-6.543-43.881z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.1137 0 0 1 -18.947 -.667)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .83162 .852 17.96)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .78241 .852 23.07)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .80175 .852 20.925)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904l79.81.669c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-16.572-15.659-16.572h-12.218c-.237 16.307-11.155 21.221-20.823 21.221h-14.347c-9.668 0-21.561-7.753-21.812-21.221h-10.61z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .84667 .852 13.881)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.44 94.883c.009-.027-1.43-.005-1.437 0-.195.143-.225.317-.233.536-.459 9.13-8.66 15.773-19.369 15.773h-16.726c-10.71 0-18.154-7.265-19.817-16.309-.014-.077-1.967-.136-1.967 0-.016 10.003 10.071 17.967 21.784 17.967h16.726c11.713 0 20.788-7.364 21.29-17.359a.713.713 0 00-.054-.325c-.043-.105-.23-.181-.197-.283z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.97863 0 0 1 3.949 -.667)"
        ></path>
      </g>
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M215.875 177.041s-24.677.056-40.791 0c-16.215-.056-43.13-.013-43.129 0 1.623 17.749 27.882 32.385 42.831 32.385 15.145 0 39.725-14.302 41.089-32.385z"
          transform="matrix(.695 0 0 1 126.127 7.498) matrix(1.12932 0 0 1 -125.09 -7.583)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M215.876 177.041l-40.792 5.743 1.46 46.917h35.972c.268-14.102 8.339-46.71 3.36-52.66zM131.973 177.041l42.706 5.743v46.917h-38.06c-.267-14.102-9.474-36.738-4.646-52.66z"
          transform="matrix(.695 0 0 1 126.127 7.498) matrix(1.12932 0 0 1 -125.09 -7.583)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.93548 0 0 1 10.604 .582) matrix(.90222 0 0 1 17.386 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.93548 0 0 1 10.604 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.93548 0 0 1 10.604 .582) matrix(1.02395 0 0 1 -2.635 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.93548 0 0 1 10.604 .582) matrix(1.07969 0 0 1 -11.724 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.93548 0 0 1 10.604 .582) matrix(1.12323 0 0 1 -18.503 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.93548 0 0 1 10.604 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.93548 0 0 1 10.604 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.93548 0 0 1 10.604 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.93548 0 0 1 10.604 .582) matrix(.94477 0 0 1 13.875 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.93548 0 0 1 10.604 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.93548 0 0 1 10.604 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.93548 0 0 1 10.604 .582) matrix(1.08082 0 0 1 -13.44 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.93548 0 0 1 10.604 .582) matrix(1.12436 0 0 1 -21.676 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.93548 0 0 1 10.604 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.93548 0 0 1 10.604 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.93548 0 0 1 10.604 .582) translate(2.13 -.667)"
        ></path>
      </g>
    </g>
  ),
  BEn: (
    <g id="clothes" transform="translate(-105.357 -10.77)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M205.47 133.16c0-18.726-15.214-14.352-31.134-14.352-15.919 0-30.472-3.861-30.472 14.865l-7.913 43.481 76.376-.113-6.857-43.881z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.20699 0 0 1 -35.193 -.667)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .83162 .852 17.96)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .78241 .852 23.07)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .80175 .852 20.925)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904l79.81.669c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-16.572-15.659-16.572h-12.218c-.237 16.307-11.155 21.221-20.823 21.221h-14.347c-9.668 0-21.561-7.753-21.812-21.221h-10.61z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .84667 .852 13.881)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.44 94.883c.009-.027-1.43-.005-1.437 0-.195.143-.225.317-.233.536-.459 9.13-8.66 15.773-19.369 15.773h-16.726c-10.71 0-18.154-7.265-19.817-16.309-.014-.077-1.967-.136-1.967 0-.016 10.003 10.071 17.967 21.784 17.967h16.726c11.713 0 20.788-7.364 21.29-17.359a.713.713 0 00-.054-.325c-.043-.105-.23-.181-.197-.283z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.97863 0 0 1 3.949 -.667)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) matrix(.94416 0 0 1 9.663 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) matrix(1.0369 0 0 1 -4.815 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) matrix(1.07872 0 0 1 -11.324 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) matrix(.96355 0 0 1 9.497 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) translate(1.139 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) translate(1.139 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) matrix(1.06328 0 0 1 -11.025 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) matrix(1.10611 0 0 1 -19.127 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(1.0086 0 0 1 -1.66 .582) translate(2.13 -.667)"
        ></path>
      </g>
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M216.802 177.041s-25.604.169-41.718.113c-16.215-.056-43.568-.224-44.263 0 1.623 17.749 29.016 32.272 43.965 32.272 15.145 0 40.652-14.302 42.016-32.385z"
          transform="matrix(.695 0 0 1 126.127 7.498) matrix(1.20751 0 0 1 -138.69 -7.583)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M216.802 177.154l-41.718 5.63 1.46 46.917h35.972c.268-14.102 9.265-46.597 4.286-52.547zM130.821 177.041l43.858 5.743v46.917h-38.06c-.267-14.102-10.626-36.738-5.798-52.66z"
          transform="matrix(.695 0 0 1 126.127 7.498) matrix(1.20751 0 0 1 -138.69 -7.583)"
        ></path>
      </g>
    </g>
  ),
  MEn: (
    <g id="clothes" transform="translate(-105.792 -10.622)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l-3.464 40.311h68.315l-2.788-40.311z"
          transform="matrix(1.05625 0 0 1.00135 -9.173 -.125) matrix(1 0 0 .97627 0 2.82)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(1.05625 0 0 1.00135 -9.173 -.125)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(1.05625 0 0 1.00135 -9.173 -.125)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(1.05625 0 0 1.00135 -9.173 -.125)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904h79.891c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-15.903-15.659-15.903h-9.743c-.336 9.733-13.711 17.467-23.379 17.467h-14.347c-9.668 0-21.215-7.758-21.55-17.491l-10.872.024z"
          transform="matrix(1.05625 0 0 1.00135 -9.173 -.125) matrix(1 0 0 1.05012 0 -6.35)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.203 94.044a1.046 1.046 0 00-.687.226.784.784 0 00-.302.578c-.459 9.13-11.298 16.181-19.813 16.344h-16.726c-10.71 0-19.373-7.655-19.832-16.785a.772.772 0 00-.311-.576 1.083 1.083 0 00-1.056-.14.966.966 0 00-.303.192.824.824 0 00-.194.277.716.716 0 00-.056.319c.501 9.995 10.039 18.371 21.752 18.371h16.726c11.713 0 21.232-7.935 21.734-17.93a.713.713 0 00-.054-.325.805.805 0 00-.197-.283 1.063 1.063 0 00-.681-.268z"
          transform="matrix(1.05625 0 0 1.00135 -9.173 -.125) matrix(1 0 0 1.04013 0 -4.528)"
        ></path>
      </g>
      <g id="short">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M216.802 177.041s-25.604.169-41.718.113c-16.215-.056-43.568-.224-44.263 0 1.623 17.749 29.016 32.272 43.965 32.272 15.145 0 40.652-14.302 42.016-32.385z"
          transform="matrix(.695 0 0 1 126.127 7.498) matrix(1.20751 0 0 1 -138.69 -7.583)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M216.802 177.154l-41.718 5.63 1.46 46.917h35.972c.268-14.102 9.265-46.597 4.286-52.547zM130.821 177.041l43.858 5.743v46.917h-38.06c-.267-14.102-10.626-36.738-5.798-52.66z"
          transform="matrix(.695 0 0 1 126.127 7.498) matrix(1.20751 0 0 1 -138.69 -7.583)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.9868 0 0 1 2.112 .582) matrix(.94741 0 0 1 9.03 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.9868 0 0 1 2.112 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.9868 0 0 1 2.112 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.9868 0 0 1 2.112 .582) matrix(1.0361 0 0 1 -4.883 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.9868 0 0 1 2.112 .582) matrix(1.07788 0 0 1 -11.388 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.9868 0 0 1 2.112 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.9868 0 0 1 2.112 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.9868 0 0 1 2.112 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.9868 0 0 1 2.112 .582) matrix(.9599 0 0 1 10.65 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.9868 0 0 1 2.112 .582) translate(1.139 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.9868 0 0 1 2.112 .582) translate(1.139 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.9868 0 0 1 2.112 .582) matrix(1.0437 0 0 1 -7.13 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.9868 0 0 1 2.112 .582) matrix(1.08574 0 0 1 -15.084 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.9868 0 0 1 2.112 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.9868 0 0 1 2.112 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.9868 0 0 1 2.112 .582) translate(2.13 -.667)"
        ></path>
      </g>
    </g>
  ),
  "M-Ec": (
    <g id="clothes" transform="translate(-105.5 -10.418)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l1.508 40.311h57.693l2.862-40.311z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904h79.891c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-15.903-15.659-15.903h-9.743c-.336 9.733-13.711 17.467-23.379 17.467h-14.347c-9.668 0-21.477-7.734-21.812-17.467h-10.61z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.203 94.044a1.046 1.046 0 00-.687.226.784.784 0 00-.302.578c-.459 9.13-9.104 16.344-19.813 16.344h-16.726c-10.71 0-19.355-7.214-19.814-16.344a.772.772 0 00-.311-.576 1.083 1.083 0 00-1.056-.14.966.966 0 00-.303.192.824.824 0 00-.194.277.716.716 0 00-.056.319c.501 9.995 10.021 17.93 21.734 17.93h16.726c11.713 0 21.232-7.935 21.734-17.93a.713.713 0 00-.054-.325.805.805 0 00-.197-.283 1.063 1.063 0 00-.681-.268z"
          transform="matrix(.86065 0 0 1 25.026 .582) matrix(1 0 0 .95537 0 5.037)"
        ></path>
      </g>
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M150.351 177.277c-.042.027-4.515-.011-4.513.013 1.623 17.749 13.999 32.136 28.948 32.136 15.145 0 27.269-14.063 28.633-32.146l-53.068-.003z"
          transform="matrix(.86065 0 0 1 25.158 .582)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M203.419 177.277l-28.335 5.507v46.917h35.057c.268-14.102-4.185-36.021-6.722-52.424zM145.838 177.277l28.841 5.507v46.917h-34.085c-.267-14.102 2.707-36.021 5.244-52.424z"
          transform="matrix(.86065 0 0 1 25.158 .582)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.86065 0 0 1 25.026 .582) matrix(.9314 0 0 1 10.99 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.86065 0 0 1 25.026 .582) matrix(1.05327 0 0 1 -8.543 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.86065 0 0 1 25.026 .582) matrix(1.09574 0 0 1 -15.155 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.86065 0 0 1 25.026 .582) matrix(.91241 0 0 1 16.964 0)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.359-4.633 8.359-10.349 0-5.717-3.742-10.35-8.359-10.35z"
          transform="matrix(.86065 0 0 1 25.026 .582) matrix(1.0167 0 0 1 -3.014 0)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.86065 0 0 1 25.026 .582) matrix(1.06028 0 0 1 -11.683 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.86065 0 0 1 25.026 .582) matrix(1.10299 0 0 1 -19.763 0)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.86065 0 0 1 25.026 .582)"
        ></path>
      </g>
    </g>
  ),
  "En-Ec": (
    <g id="clothes" transform="translate(-105.623 -10.622)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l-8.292 39.095 76.376-.113-6.021-38.982z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .83162 .852 17.96)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .78241 .852 23.07)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .80175 .852 20.925)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904l79.81.669c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-16.572-15.659-16.572h-12.218c-.237 16.307-11.155 21.221-20.823 21.221h-14.347c-9.668 0-21.561-7.753-21.812-21.221h-10.61z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .84667 .852 13.881)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.44 94.883c.009-.027-1.43-.005-1.437 0-.195.143-.187.322-.233.536-2.072 9.625-9.289 15.96-19.369 15.773h-16.726c-10.71 0-18.154-7.265-19.817-16.309-.014-.077-1.967-.136-1.967 0-.016 10.003 10.071 17.967 21.784 17.967h16.726c11.713 0 20.788-7.364 21.29-17.359a.713.713 0 00-.054-.325c-.043-.105-.23-.181-.197-.283z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.97863 0 0 1 3.949 -.667)"
        ></path>
      </g>
      <g id="short">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M216.802 177.041s-25.604.169-41.718.113c-16.215-.056-43.568-.224-44.263 0 1.623 17.749 29.016 32.272 43.965 32.272 15.145 0 40.652-14.302 42.016-32.385z"
          transform="matrix(.695 0 0 1 126.127 7.498) translate(-102.599 -7.583)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M216.802 177.154l-41.718 5.63 1.46 46.917h35.972c.268-14.102 9.265-46.597 4.286-52.547zM130.821 177.041l43.858 5.743v46.917h-38.06c-.267-14.102-10.626-36.738-5.798-52.66z"
          transform="matrix(.695 0 0 1 126.127 7.498) translate(-102.599 -7.583)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.78241 0 0 1 37.705 .582) matrix(.97137 0 0 1 5.109 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.78241 0 0 1 37.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.78241 0 0 1 37.705 .582) matrix(1.04033 0 0 1 -5.426 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.78241 0 0 1 37.705 .582) matrix(1.09006 0 0 1 -13.472 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.78241 0 0 1 37.705 .582) matrix(1.13402 0 0 1 -20.315 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.78241 0 0 1 37.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.78241 0 0 1 37.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.78241 0 0 1 37.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.78241 0 0 1 37.705 .582) matrix(.96097 0 0 1 11.197 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.78241 0 0 1 37.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.78241 0 0 1 37.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.78241 0 0 1 37.705 .582) matrix(1.09109 0 0 1 -15.423 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.78241 0 0 1 37.705 .582) matrix(1.13504 0 0 1 -23.738 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.78241 0 0 1 37.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.78241 0 0 1 37.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.78241 0 0 1 37.705 .582) translate(2.13 -.667)"
        ></path>
      </g>
    </g>
  ),
  "M-En": (
    <g id="clothes" transform="translate(-105.464 -9.705)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l-2.216 40.311 65.82.137-1.541-40.448z"
          transform="matrix(1.05625 0 0 1 -9.173 0) matrix(1 0 0 .97627 0 2.82)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904h79.891c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-15.903-15.659-15.903h-9.743c-.336 9.733-13.711 17.467-23.379 17.467h-14.347c-9.668 0-21.477-7.734-21.812-17.467h-10.61z"
          transform="matrix(1.05625 0 0 1 -9.173 0)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.203 94.044a1.046 1.046 0 00-.687.226.784.784 0 00-.302.578c-.459 9.13-9.104 16.344-19.813 16.344h-16.726c-10.71 0-19.355-7.214-19.814-16.344a.772.772 0 00-.311-.576 1.083 1.083 0 00-1.056-.14.966.966 0 00-.303.192.824.824 0 00-.194.277.716.716 0 00-.056.319c.501 9.995 10.021 17.93 21.734 17.93h16.726c11.713 0 21.232-7.935 21.734-17.93a.713.713 0 00-.054-.325.805.805 0 00-.197-.283 1.063 1.063 0 00-.681-.268z"
          transform="matrix(1.05625 0 0 1 -9.173 0) matrix(1 0 0 .95537 0 5.037)"
        ></path>
      </g>
      <g id="short">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M217.464 177.041s-26.266.169-42.38.113c-16.215-.056-44.241-.337-44.936-.113 1.623 17.749 29.689 32.385 44.638 32.385 15.145 0 41.314-14.302 42.678-32.385z"
          transform="matrix(.695 0 0 1 126.127 7.498) matrix(1.14563 0 0 1 -127.928 -7.583)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M217.464 177.175l-42.38 5.609.193 46.917h43.602c.268-14.102 3.564-46.576-1.415-52.526zM130.148 177.046l44.531 5.738v46.917h-45.41c-.267-14.102-3.949-36.733.879-52.655z"
          transform="matrix(.695 0 0 1 126.127 7.498) matrix(1.14563 0 0 1 -127.928 -7.583)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) matrix(.93151 0 0 1 11.407 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) matrix(1.03506 0 0 1 -5.12 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) matrix(1.06586 0 0 1 -10.406 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) matrix(1.10884 0 0 1 -17.097 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) matrix(.92054 0 0 1 16.53 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) translate(1.139 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) matrix(1.0304 0 0 1 -4.795 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) matrix(1.05052 0 0 1 -8.705 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) matrix(1.09285 0 0 1 -16.71 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(1.01868 0 0 1 -3.44 .582) translate(2.13 -.667)"
        ></path>
      </g>
    </g>
  ),
  C: (
    <g id="clothes" transform="translate(-105.407 -9.704)">
      <g id="tshirt">
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M206.306 138.059c0-18.726-16.05-19.251-31.97-19.251-15.919 0-30.093.525-30.093 19.251l-1.067 39.095h62.675l.455-39.095z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M216.799 118.659c0-18.087-21.903 0-45.229 0-23.327 0-39.246-18.087-39.246 0 0 18.088 18.91 32.751 42.238 32.751 23.326 0 42.237-14.663 42.237-32.751z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .83162 .852 17.96)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M201.383 115.82l-49.655 1.994c-11.267 0-19.404-14.895-19.404-4.977v10.822c0 9.916 9.134 17.956 20.401 17.956h43.672c11.268 0 20.402-8.04 20.402-17.956v-10.822c0-9.918-4.148 2.983-15.416 2.983z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .78241 .852 23.07)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M199.211 118.94h-50.296c-9.163 0-16.591-16.436-16.591-7.219v10.057c0 9.215 7.428 16.687 16.591 16.687h51.293c9.163 0 16.591-7.472 16.591-16.687v-10.057c0-9.217-8.425 7.219-17.588 7.219z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .80175 .852 20.925)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).normalColor}
          fillRule="nonzero"
          d="M134.272 94.883c-8.675 0-15.66 7.093-15.66 15.903s6.985 15.904 15.66 15.904l79.81.669c8.675 0 15.659-7.094 15.659-15.904 0-8.81-6.984-16.572-15.659-16.572h-11.945c-1.166 15.939-11.995 20.997-21.096 21.221h-14.347c-9.668 0-21.561-7.753-21.812-21.221h-10.61z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1 0 0 .84667 .852 13.881)"
        ></path>
        <path
          fill={getSpecificColors(mainColor).darkColor}
          fillRule="nonzero"
          d="M204.44 94.883c.009-.027-1.43-.005-1.437 0-.195.143-.225.317-.233.536-.459 9.13-8.66 15.773-19.369 15.773h-16.726c-10.71 0-18.154-7.265-19.817-16.309-.014-.077-1.967-.136-1.967 0-.016 10.003 10.071 17.967 21.784 17.967h16.726c11.713 0 20.788-7.364 21.29-17.359a.713.713 0 00-.054-.325c-.043-.105-.23-.181-.197-.283z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.97863 0 0 1 3.949 -.667)"
        ></path>
      </g>
      <g id="shoes">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M167.44 292.797h-17.149c-.911 0-1.65.914-1.65 2.043v17.906c0 1.129.739 2.043 1.65 2.043h17.149c.911 0 1.651-.914 1.651-2.043V294.84c0-1.129-.74-2.043-1.651-2.043z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.92214 0 0 1 13.32 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M160.515 299.103h-5.43c-1.29 0-2.336 1.295-2.336 2.892v2.595c0 1.598 1.046 2.893 2.336 2.893h5.43c1.291 0 2.336-1.295 2.336-2.893v-2.595c0-1.597-1.045-2.892-2.336-2.892z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M161.998 303.559h-8.006c-4.616 0-8.359 4.633-8.359 10.35 0 5.716 3.743 10.349 8.359 10.349h8.006c4.616 0 8.358-4.633 8.358-10.349 0-5.717-3.742-10.35-8.358-10.35z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M168.068 312.961h-19.961c-1.59 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.289 3.429 2.879 3.429h19.961c1.591 0 2.88-1.535 2.88-3.429v-4.482c0-1.892-1.289-3.427-2.88-3.427z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M169.315 319.962h-22.64c-.576 0-1.042.577-1.042 1.289v1.686c0 .712.466 1.29 1.042 1.29h22.64c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.04105 0 0 1 -5.532 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 304.079h-6.348c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.348c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M163.244 308.853h-3.05c-1.075 0-1.948 1.079-1.948 2.412 0 1.332.873 2.412 1.948 2.412h3.05c1.076 0 1.947-1.08 1.947-2.412 0-1.333-.871-2.412-1.947-2.412z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M161.087 306.452h-6.348c-.331 0-.599.332-.599.741s.268.741.599.741h6.348c.33 0 .599-.332.599-.741s-.269-.741-.599-.741z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(.852 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M199.322 292.796h-17.149c-.911 0-1.651.915-1.651 2.044v17.905c0 1.129.74 2.044 1.651 2.044h17.149c.911 0 1.65-.915 1.65-2.044V294.84c0-1.129-.739-2.044-1.65-2.044z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(.92624 0 0 1 16.608 -.667)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M195.463 299.132h-5.431c-1.29 0-2.336 1.296-2.336 2.892v2.596c0 1.597 1.046 2.892 2.336 2.892h5.431c1.29 0 2.336-1.295 2.336-2.892v-2.596c0-1.596-1.046-2.892-2.336-2.892z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M196.887 303.559h-8.005c-4.617 0-8.36 4.633-8.36 10.35 0 5.716 3.743 10.349 8.36 10.349h8.005c4.617 0 8.697-4.551 8.697-10.267 0-5.717-4.08-10.432-8.697-10.432z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M203.015 312.961h-19.962c-1.589 0-2.879 1.535-2.879 3.427v4.482c0 1.894 1.29 3.429 2.879 3.429h19.962c1.59 0 2.879-1.535 2.879-3.429v-4.482c0-1.892-1.289-3.427-2.879-3.427z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M204.205 319.962h-22.641c-.575 0-1.042.577-1.042 1.289v1.686c0 .712.467 1.29 1.042 1.29h22.641c.575 0 1.041-.578 1.041-1.29v-1.686c0-.712-.466-1.289-1.041-1.289z"
          transform="matrix(.78241 0 0 1 38.705 .582) matrix(1.04028 0 0 1 -5.49 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 304.079h-6.349c-.331 0-.599.332-.599.742 0 .409.268.741.599.741h6.349c.33 0 .599-.332.599-.741 0-.41-.269-.742-.599-.742z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#585858"
          fillRule="nonzero"
          d="M198.132 308.882h-3.049c-1.076 0-1.948 1.08-1.948 2.412 0 1.332.872 2.412 1.948 2.412h3.049c1.076 0 1.948-1.08 1.948-2.412 0-1.332-.872-2.412-1.948-2.412z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M195.976 306.48h-6.349c-.331 0-.599.333-.599.741 0 .41.268.742.599.742h6.349c.33 0 .599-.332.599-.742 0-.408-.269-.741-.599-.741z"
          transform="matrix(.78241 0 0 1 38.705 .582) translate(2.13 -.667)"
        ></path>
      </g>
      <g id="shorts">
        <path
          fill="#4F4F4F"
          fillRule="nonzero"
          d="M213.079 177.154s-21.881.056-37.995 0c-16.215-.056-38.96-.224-39.656 0 1.623 17.749 24.409 32.272 39.358 32.272 15.145 0 36.929-14.189 38.293-32.272z"
          transform="matrix(.695 0 0 1 126.127 7.498) matrix(.90872 0 0 1 -86.71 -7.583)"
        ></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M212.516 177.154l-37.432 5.63 1.46 46.917h36.535c.268-14.102 1.974-36.144-.563-52.547zM135.428 177.154l39.251 5.63v46.917h-38.06c-.267-14.102-6.018-36.625-1.191-52.547z"
          transform="matrix(.695 0 0 1 126.127 7.498) matrix(.90872 0 0 1 -86.71 -7.583)"
        ></path>
      </g>
    </g>
  ),
});

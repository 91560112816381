import * as React from "react";
import CallToAction from "../CTA/CallToAction";
import Header from "./Header";

const Home = () => {
  return (
    <>
      <Header />
      <CallToAction />
      {/* <FooterCTA /> */}
    </>
  );
};
export default Home;

import * as React from "react";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, useMediaQuery } from "@mui/material/";
import Typography from "@mui/material/Typography";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

export interface Icategory {
  label: string;
  bgColor: string;
}

function TypesPage1(props: any) {
  const medium = useMediaQuery("(max-width:899px)");
  const small = useMediaQuery("(max-width:599px)");
  const extraSmall = useMediaQuery("(max-width:480px)");

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "25px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "300px",
          width: "100%",
        }}
      >
        {props.currentCategory.map((step: Icategory, index: number) => (
          <Box
            key={index}
            sx={{
              display:
                props.labelCategory === "category1"
                  ? props.currentIndexCat1 === index
                    ? "flex"
                    : "none"
                  : props.currentIndexCat2 === index
                  ? "flex"
                  : "none",
              backgroundColor: step.bgColor,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              position: "relative",
              color: "#FFFF",
              padding: "20px",
              width: "100%",
            }}
          >
            <KeyboardArrowLeft
              onClick={() => {
                if (props.labelCategory === "category1") {
                  props.currentIndexCat1 === 0
                    ? props.setCurrentIndexCat1(
                        props.currentCategory.length - 1
                      )
                    : props.setCurrentIndexCat1((c: number) => c - 1);
                } else {
                  props.currentIndexCat2 === 0
                    ? props.setCurrentIndexCat2(
                        props.currentCategory.length - 1
                      )
                    : props.setCurrentIndexCat2((c: number) => c - 1);
                }
              }}
              sx={{
                fontSize: 50,
                left: "0px",
                position: "absolute",
                cursor: "pointer",
              }}
            />
            <Typography
              sx={{
                fontWeight: "Bold",
              }}
            >
              {step.label}
            </Typography>
            <KeyboardArrowRight
              onClick={() => {
                if (props.labelCategory === "category1") {
                  props.currentIndexCat1 === props.currentCategory.length - 1
                    ? props.setCurrentIndexCat1(0)
                    : props.setCurrentIndexCat1((c: number) => c + 1);
                } else {
                  props.currentIndexCat2 === props.currentCategory.length - 1
                    ? props.setCurrentIndexCat2(0)
                    : props.setCurrentIndexCat2((c: number) => c + 1);
                }
              }}
              sx={{
                fontSize: 50,
                right: "0px",
                position: "absolute",
                cursor: "pointer",
              }}
            />
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 3,
          cursor: "pointer",
        }}
      >
        {props.labelCategory === "category1" ? (
          <svg
            style={{
              maxWidth: "500px",
              width: "100%",
              height: extraSmall ? "50%" : "500px",
              paddingLeft: "20px",
            }}
            viewBox="0 0 598 601"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M97.7955 236.696L234.751 318.014L239.466 351.176L262.243 362.494V528.133L211.179 526.023L140.484 519.168L67.3721 506.605L34.5514 499.073L13.0312 488.312L20.0249 453.879L29.1712 417.294L50.1556 349.504L75.4407 283.865L97.7955 236.696Z"
              onClick={() => {
                window.scrollTo(0, props.section3Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat1].label ===
                "ENDOMORPH"
                  ? props.currentCategory[props.currentIndexCat1].bgColor
                  : "white"
              }
            />
            <path
              d="M191.669 106.949C224.291 64.3408 285.904 17.0728 285.904 17.0728C285.904 17.0728 339.082 61.1158 385.401 116.935C431.72 172.755 446.191 210.203 446.191 210.203L313.359 288.818L285.904 273.456L259.142 288.818L117.273 203.877C117.273 203.877 157.074 146.545 191.669 106.949Z"
              onClick={() => {
                window.scrollTo(0, props.section1Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat1].label ===
                "MESOMORPH"
                  ? props.currentCategory[props.currentIndexCat1].bgColor
                  : "white"
              }
            />
            <path
              d="M309.523 529.194L309.523 367.738L330.815 351.759L333.828 320.541L471.568 242.771L480.398 252.588L544.051 426.593L556.14 477.55L544.049 492.811L490.007 505.244L390.527 520.506L309.523 529.194Z"
              onClick={() => {
                window.scrollTo(0, props.section2Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat1].label ===
                "ECTOMORPH"
                  ? props.currentCategory[props.currentIndexCat1].bgColor
                  : "white"
              }
            />
            <path
              d="M322 286.75L450.546 211.644L465.711 240.17L336.803 312.387L334.998 295.777L322 286.75Z"
              onClick={() => {
                window.scrollTo(0, props.section4Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat1].label === "HYBRID"
                  ? props.currentCategory[props.currentIndexCat1].bgColor
                  : "white"
              }
            />
            <path
              d="M234.595 313.277L99.9848 237.706L116.729 209.187L249.012 286.597L235.488 296.92L234.595 313.277Z"
              onClick={() => {
                window.scrollTo(0, props.section4Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat1].label === "HYBRID"
                  ? props.currentCategory[props.currentIndexCat1].bgColor
                  : "white"
              }
            />
            <path
              d="M304.796 371.96L304.796 526.204L266.677 526.204L266.677 371.96L285.284 380.769L304.796 371.96Z"
              onClick={() => {
                window.scrollTo(0, props.section4Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat1].label === "HYBRID"
                  ? props.currentCategory[props.currentIndexCat1].bgColor
                  : "white"
              }
            />
            <path
              d="M282.883 539.769C282.883 532.269 282.883 532.269 282.882 532.269H282.876H282.848L282.732 532.268C282.629 532.268 282.472 532.268 282.263 532.267C282.067 532.267 281.825 532.266 281.539 532.264C281.217 532.263 280.837 532.261 280.403 532.258C278.766 532.247 276.35 532.226 273.251 532.183C267.052 532.097 258.121 531.925 247.217 531.582C225.4 530.894 195.724 529.519 164.249 526.772C132.743 524.023 99.5941 519.912 70.7773 513.787C44.342 508.167 22.4987 501.034 8.78708 492.306C50.2314 314.367 104.358 153.863 285.146 9.81177C464.018 155.101 514.621 327.401 556.665 470.555C558.708 477.51 560.73 484.396 562.746 491.207C559.912 492.306 556.078 493.729 551.247 495.381C539.374 499.443 521.479 504.895 497.604 510.359C449.857 521.287 378.172 532.269 282.883 532.269V539.769Z"
              stroke="black"
              strokeWidth="15"
            />
            <path
              d="M285.807 11.3232L285.807 273.27"
              stroke="black"
              strokeWidth="15"
            />
            <path
              d="M262.744 364.716L262.744 529.565"
              stroke="black"
              strokeWidth="15"
            />
            <path
              d="M309.422 361.275L309.422 530.472"
              stroke="black"
              strokeWidth="15"
            />
            <path
              d="M249.051 349.796L10.617 490.434"
              stroke="black"
              strokeWidth="15"
            />
            <path
              d="M475.807 239.144L333.41 319.633"
              stroke="black"
              strokeWidth="15"
            />
            <path
              d="M454.082 204.577L316.142 284.45"
              stroke="black"
              strokeWidth="15"
            />
            <path
              d="M557.791 486.431L326.095 351.131"
              stroke="black"
              strokeWidth="15"
            />
            <path
              d="M236.678 319.064L95.0297 239.479"
              stroke="black"
              strokeWidth="15"
            />
            <path
              d="M256.967 285.764L116.453 202.856"
              stroke="black"
              strokeWidth="15"
            />
            <path
              d="M241.808 296.752L286.974 270.676L332.14 296.752V348.906L286.974 374.982L241.808 348.906V296.752Z"
              fill="#4298B4"
            />
            <path
              d="M241.808 296.752L286.974 270.676L332.14 296.752V348.906L286.974 374.982L241.808 348.906V296.752Z"
              onClick={() => {
                window.scrollTo(0, props.section5Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat1].label ===
                "CENTRAL"
                  ? props.currentCategory[props.currentIndexCat1].bgColor
                  : "white"
              }
            />
            <path
              d="M241.808 296.752L286.974 270.676L332.14 296.752V348.906L286.974 374.982L241.808 348.906V296.752Z"
              stroke="black"
              strokeWidth="15"
            />
          </svg>
        ) : (
          <svg
            style={{
              maxWidth: "500px",
              width: "100%",
              height: extraSmall ? "50%" : "500px",
              paddingLeft: "20px",
            }}
            viewBox="0 0 598 601"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M90.8879 235.582L245.729 326.536L11.9316 466.457L25.0208 418.346L44.1889 349.317L90.8879 235.582Z"
              onClick={() => {
                window.scrollTo(0, props.section3Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Mesomorphic Endomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
            />
            <path
              d="M245.087 328.923L246.907 350.079L264.164 360.568L188.437 408.409L144.961 435.197L101.924 459.79L25.0211 505.281L3.55273 492.718L11.8868 466.189L127.834 398.523L245.087 328.923Z"
              onClick={() => {
                window.scrollTo(0, props.section3Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Balanced Endomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
            />
            <path
              d="M156.379 428.171L266.37 359.469V448.348V535.651L202.05 532.921L141.121 527.561L68.0093 518.824L25.0215 505.281L156.379 428.171Z"
              onClick={() => {
                window.scrollTo(0, props.section3Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Ectomorphic Endomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
            />
            <path
              d="M305.432 20.3022L362.65 73.0643L415.217 137.834L457.389 203.562L372.374 254.631L305.432 293.161H305.433L305.432 20.3022Z"
              onClick={() => {
                window.scrollTo(0, props.section1Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Ectomorphic Mesomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
            />
            <path
              d="M266.099 20.1733L286.304 2.66223L305.294 20.1733L304.823 292.34L286.816 281.536L266.098 294.486L266.098 170.295L266.099 20.1733Z"
              onClick={() => {
                window.scrollTo(0, props.section1Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Balanced Mesomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
            />
            <path
              d="M186.176 98.096L234.674 47.407L266.099 17.3439V292.025L109.07 202.245L145.034 148.298L186.176 98.096Z"
              onClick={() => {
                window.scrollTo(0, props.section1Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Endomorphic Mesomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
            />
            <path
              d="M305.501 535.653L305.501 362.894L546.755 502.008L488.521 516.127L438.707 525.813L390.623 531.694L305.501 535.653Z"
              onClick={() => {
                window.scrollTo(0, props.section2Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Endomorphic Ectomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
            />
            <path
              d="M546.755 502.008L305.501 362.894L325.41 350.726L325.41 328.947L346.935 340.334L560.924 465.065L568.518 493.625L546.755 502.008Z"
              onClick={() => {
                window.scrollTo(0, props.section2Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Balanced Ectomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
            />
            <path
              d="M325.466 327.069L475.891 235.187L521.661 340.335L548.977 427.168L559.131 462.858L325.466 327.069Z"
              onClick={() => {
                window.scrollTo(0, props.section2Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Mesomorphic Ectomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
            />
            <path
              d="M283.52 539.583C283.52 537.083 283.519 537.083 283.518 537.083H283.511H283.481L283.361 537.083L283.207 537.083C283.118 537.083 283.01 537.082 282.884 537.082C282.673 537.081 282.411 537.08 282.1 537.079C281.786 537.077 281.421 537.075 281.007 537.072C279.359 537.062 276.931 537.04 273.819 536.997C267.594 536.911 258.634 536.739 247.696 536.394C225.818 535.704 196.045 534.325 164.451 531.568C132.846 528.81 99.472 524.677 70.3743 518.492C42.0454 512.47 18.1313 504.572 3.77416 494.344C45.6536 313.64 100.048 149.862 285.811 3.22165C468.456 150.04 519.79 324.867 562.067 468.849C564.558 477.332 567.018 485.709 569.471 493.967C568.816 494.24 568 494.574 567.023 494.963C564.028 496.156 559.519 497.868 553.503 499.926C541.47 504.043 523.407 509.543 499.356 515.048C451.255 526.056 379.197 537.083 283.52 537.083V539.583Z"
              stroke="black"
              strokeWidth="5"
            />
            <path
              d="M266.65 18.1055L266.65 535.289"
              stroke="black"
              strokeWidth="5"
            />
            <path
              d="M304.787 18.1055L304.787 535.288"
              stroke="black"
              strokeWidth="5"
            />
            <path
              d="M459.547 202.749L8.74514 468.709"
              stroke="black"
              strokeWidth="5"
            />
            <path
              d="M475.264 237.476L26.3785 505.372"
              stroke="black"
              strokeWidth="5"
            />
            <path
              d="M546.097 501.607L92.13 236.327"
              stroke="black"
              strokeWidth="5"
            />
            <path
              d="M561.125 465.176L108.636 202.352"
              stroke="black"
              strokeWidth="5"
            />
            <path
              d="M245.593 327.135L267.612 294.758L304.186 294.758L324.587 327.198L305.985 362.08H267.997L245.593 327.135Z"
              fill="white"
            />
            <path
              d="M245.593 327.135L267.612 294.758L304.186 294.758L324.587 327.198L305.985 362.08H267.997L245.593 327.135Z"
              fill="white"
            />
            <path
              d="M245.593 327.135L267.612 294.758L304.186 294.758L324.587 327.198L305.985 362.08H267.997L245.593 327.135Z"
              stroke="black"
              strokeWidth="5"
            />
            <path
              d="M474.973 236.144L325.601 325.38V304.31V304.015L325.343 303.872L306.881 293.661L457.532 204.435L474.973 236.144Z"
              onClick={() => {
                window.scrollTo(0, props.section4Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Mesomorph Ectomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
              stroke="black"
            />
            <path
              d="M267.997 535.595L268.843 362.87L286.538 374.138L286.81 374.311L287.08 374.135L304.296 362.896L301.297 535.595H267.997Z"
              onClick={() => {
                window.scrollTo(0, props.section4Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Endomorph Ectomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
              stroke="black"
            />
            <path
              d="M247.306 304.407L244.503 323.812L92.549 235.145L109.199 204.017L262.764 293.33L247.513 304.07L247.337 304.194L247.306 304.407Z"
              onClick={() => {
                window.scrollTo(0, props.section4Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Mesomorph Endomorph"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
              stroke="black"
            />
            <path
              d="M246.571 304.913L286.053 282.119L325.534 304.913V350.503L286.053 373.297L246.571 350.503V304.913Z"
              fill="white"
            />
            <path
              d="M246.571 304.913L286.053 282.119L325.534 304.913V350.503L286.053 373.297L246.571 350.503V304.913Z"
              fill="white"
            />
            <path
              d="M246.571 304.913L286.053 282.119L325.534 304.913V350.503L286.053 373.297L246.571 350.503V304.913Z"
              stroke="black"
              strokeWidth="5.60979"
            />
            <path
              d="M246.569 304.913L286.052 282.118L325.534 304.913V350.503L286.052 373.298L246.569 350.503V304.913Z"
              onClick={() => {
                window.scrollTo(0, props.section5Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Central"
                  ? props.currentCategory[props.currentIndexCat2].bgColor
                  : "white"
              }
              stroke="black"
              strokeWidth="5.60993"
            />
            <path
              d="M122.237 431.297L115.726 418.79L121.19 415.946C122.503 415.263 123.671 415.023 124.694 415.227C125.711 415.419 126.484 416.024 127.014 417.041C127.365 417.715 127.495 418.384 127.404 419.047C127.314 419.71 127.025 420.311 126.539 420.85C127.353 420.682 128.088 420.75 128.743 421.056C129.404 421.343 129.934 421.871 130.334 422.64C130.913 423.752 130.984 424.812 130.547 425.821C130.116 426.812 129.232 427.655 127.896 428.351L122.237 431.297ZM120.669 422.959L123.561 421.453C125.11 420.647 125.574 419.646 124.952 418.452C124.331 417.257 123.245 417.063 121.696 417.87L118.804 419.375L120.669 422.959ZM123.523 428.44L126.716 426.778C128.277 425.965 128.732 424.932 128.079 423.679C127.426 422.425 126.32 422.205 124.758 423.017L121.565 424.679L123.523 428.44ZM134.451 424.939L127.94 412.432L136.26 408.101L137.202 409.911L131.064 413.106L132.855 416.547L138.603 413.555L139.545 415.365L133.797 418.357L135.691 421.994L141.829 418.798L142.771 420.608L134.451 424.939ZM144.823 419.54L140.307 410.865L142.471 409.738L143.173 411.086C143.21 410.436 143.409 409.851 143.771 409.332C144.144 408.807 144.644 408.382 145.271 408.055C147.317 406.99 148.958 407.646 150.196 410.023L152.948 415.31L150.73 416.464L148.034 411.284C147.683 410.61 147.297 410.187 146.877 410.015C146.469 409.837 145.998 409.886 145.466 410.163C144.816 410.502 144.403 410.98 144.228 411.597C144.059 412.196 144.15 412.832 144.501 413.507L147.04 418.385L144.823 419.54Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Balanced Endomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M158.071 487.176V473.076H167.451V475.116H160.531V478.996H167.011V481.036H160.531V485.136H167.451V487.176H158.071ZM174.226 487.376C173.2 487.376 172.306 487.17 171.546 486.756C170.786 486.33 170.2 485.73 169.786 484.956C169.373 484.183 169.166 483.276 169.166 482.236C169.166 481.196 169.373 480.303 169.786 479.556C170.2 478.796 170.786 478.216 171.546 477.816C172.306 477.403 173.2 477.196 174.226 477.196C174.853 477.196 175.473 477.296 176.086 477.496C176.7 477.696 177.206 477.976 177.606 478.336L176.866 480.076C176.52 479.77 176.126 479.536 175.686 479.376C175.26 479.216 174.846 479.136 174.446 479.136C173.58 479.136 172.906 479.41 172.426 479.956C171.96 480.49 171.726 481.256 171.726 482.256C171.726 483.243 171.96 484.023 172.426 484.596C172.906 485.156 173.58 485.436 174.446 485.436C174.833 485.436 175.246 485.356 175.686 485.196C176.126 485.036 176.52 484.796 176.866 484.476L177.606 486.236C177.206 486.583 176.693 486.863 176.066 487.076C175.453 487.276 174.84 487.376 174.226 487.376ZM179.38 487.176V473.076H188.76V475.116H181.84V478.996H188.32V481.036H181.84V485.136H188.76V487.176H179.38ZM191.073 487.176V477.396H193.513V478.916C193.847 478.356 194.293 477.93 194.853 477.636C195.427 477.343 196.067 477.196 196.773 477.196C199.08 477.196 200.233 478.536 200.233 481.216V487.176H197.733V481.336C197.733 480.576 197.587 480.023 197.293 479.676C197.013 479.33 196.573 479.156 195.973 479.156C195.24 479.156 194.653 479.39 194.213 479.856C193.787 480.31 193.573 480.916 193.573 481.676V487.176H191.073Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Ectomorphic Endomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M98.4316 335.729V321.629H100.572L105.572 330.849L110.552 321.629H112.652V335.729H110.332V326.229L106.292 333.589H104.792L100.752 326.269V335.729H98.4316ZM115.755 335.729V321.629H125.135V323.669H118.215V327.549H124.695V329.589H118.215V333.689H125.135V335.729H115.755ZM127.448 335.729V325.949H129.888V327.469C130.222 326.909 130.668 326.482 131.228 326.189C131.802 325.896 132.442 325.749 133.148 325.749C135.455 325.749 136.608 327.089 136.608 329.769V335.729H134.108V329.889C134.108 329.129 133.962 328.576 133.668 328.229C133.388 327.882 132.948 327.709 132.348 327.709C131.615 327.709 131.028 327.942 130.588 328.409C130.162 328.862 129.948 329.469 129.948 330.229V335.729H127.448Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Mesomorphic Endomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M293.236 475.943H279.136V466.563H281.176V473.483H285.056V467.003H287.096V473.483H291.196V466.563H293.236V475.943ZM293.236 464.249H283.456L283.456 461.809H284.976C284.416 461.476 283.99 461.029 283.696 460.469C283.403 459.896 283.256 459.256 283.256 458.549C283.256 456.243 284.596 455.089 287.276 455.089H293.236V457.589H287.396C286.636 457.589 286.083 457.736 285.736 458.029C285.39 458.309 285.216 458.749 285.216 459.349C285.216 460.083 285.45 460.669 285.916 461.109C286.37 461.536 286.976 461.749 287.736 461.749H293.236V464.249ZM288.876 452.49H286.876V446.53H288.876V452.49ZM293.236 443.638H279.136V434.258H281.176V441.178H285.056V434.698H287.096V441.178H291.196V434.258H293.236V443.638ZM293.436 427.483C293.436 428.51 293.23 429.403 292.816 430.163C292.39 430.923 291.79 431.51 291.016 431.923C290.243 432.337 289.336 432.543 288.296 432.543C287.256 432.543 286.363 432.337 285.616 431.923C284.856 431.51 284.276 430.923 283.876 430.163C283.463 429.403 283.256 428.51 283.256 427.483C283.256 426.857 283.356 426.237 283.556 425.623C283.756 425.01 284.036 424.503 284.396 424.103L286.136 424.843C285.83 425.19 285.596 425.583 285.436 426.023C285.276 426.45 285.196 426.863 285.196 427.263C285.196 428.13 285.47 428.803 286.016 429.283C286.55 429.75 287.316 429.983 288.316 429.983C289.303 429.983 290.083 429.75 290.656 429.283C291.216 428.803 291.496 428.13 291.496 427.263C291.496 426.877 291.416 426.463 291.256 426.023C291.096 425.583 290.856 425.19 290.536 424.843L292.296 424.103C292.643 424.503 292.923 425.017 293.136 425.643C293.336 426.257 293.436 426.87 293.436 427.483Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Endomorph Ectomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M374.428 282.876L367.378 270.665L369.231 269.595L378.171 275.08L377.874 264.605L379.693 263.555L386.743 275.766L384.734 276.926L379.984 268.698L380.165 277.092L378.866 277.842L371.707 273.523L376.437 281.716L374.428 282.876ZM387.094 270.528L386.094 268.796L391.256 265.816L392.256 267.548L387.094 270.528ZM396.94 269.878L389.89 257.667L398.014 252.977L399.034 254.744L393.041 258.204L394.981 261.564L400.593 258.324L401.613 260.091L396.001 263.331L398.051 266.881L404.044 263.421L405.064 265.188L396.94 269.878ZM411.031 261.974C410.142 262.487 409.265 262.755 408.4 262.777C407.529 262.787 406.72 262.561 405.976 262.098C405.231 261.635 404.599 260.953 404.079 260.052C403.559 259.152 403.291 258.275 403.276 257.422C403.254 256.557 403.472 255.761 403.93 255.035C404.382 254.297 405.052 253.671 405.941 253.158C406.484 252.844 407.071 252.621 407.702 252.488C408.333 252.354 408.912 252.343 409.438 252.455L409.667 254.332C409.214 254.24 408.756 254.234 408.295 254.316C407.846 254.39 407.448 254.528 407.102 254.728C406.351 255.161 405.904 255.735 405.762 256.448C405.625 257.143 405.806 257.924 406.306 258.79C406.799 259.644 407.391 260.203 408.082 260.466C408.778 260.711 409.501 260.617 410.252 260.184C410.586 259.99 410.904 259.714 411.205 259.356C411.506 258.997 411.727 258.593 411.867 258.142L413.388 259.297C413.215 259.797 412.911 260.296 412.474 260.794C412.043 261.274 411.562 261.667 411.031 261.974Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Mesomorph Ectomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M155.368 257.231L162.418 245.02L164.271 246.09L163.991 256.575L172.914 251.08L174.732 252.13L167.682 264.341L165.673 263.181L170.423 254.954L163.245 259.308L161.945 258.558L162.107 250.199L157.377 258.391L155.368 257.231ZM172.394 262.027L173.394 260.295L178.556 263.275L177.556 265.007L172.394 262.027ZM177.88 270.229L184.93 258.018L193.053 262.708L192.033 264.475L186.04 261.015L184.1 264.375L189.712 267.615L188.692 269.382L183.08 266.142L181.03 269.692L187.023 273.152L186.003 274.919L177.88 270.229ZM188.007 276.076L192.897 267.606L195.01 268.826L194.25 270.142C194.819 269.824 195.419 269.678 196.051 269.704C196.694 269.736 197.321 269.929 197.933 270.283C199.931 271.436 200.26 273.173 198.92 275.494L195.94 280.656L193.775 279.406L196.695 274.348C197.075 273.69 197.224 273.137 197.144 272.691C197.075 272.25 196.78 271.88 196.261 271.58C195.625 271.214 195.001 271.122 194.386 271.306C193.79 271.486 193.302 271.904 192.922 272.563L190.172 277.326L188.007 276.076Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Mesomorph Endomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M354.856 486.176V472.076H364.236V474.116H357.316V477.996H363.796V480.036H357.316V484.136H364.236V486.176H354.856ZM366.55 486.176V476.396H368.99V477.916C369.323 477.356 369.77 476.93 370.33 476.636C370.903 476.343 371.543 476.196 372.25 476.196C374.557 476.196 375.71 477.536 375.71 480.216V486.176H373.21V480.336C373.21 479.576 373.063 479.023 372.77 478.676C372.49 478.33 372.05 478.156 371.45 478.156C370.717 478.156 370.13 478.39 369.69 478.856C369.263 479.31 369.05 479.916 369.05 480.676V486.176H366.55ZM378.489 486.176V472.076H387.869V474.116H380.949V477.996H387.429V480.036H380.949V484.136H387.869V486.176H378.489ZM394.644 486.376C393.618 486.376 392.724 486.17 391.964 485.756C391.204 485.33 390.618 484.73 390.204 483.956C389.791 483.183 389.584 482.276 389.584 481.236C389.584 480.196 389.791 479.303 390.204 478.556C390.618 477.796 391.204 477.216 391.964 476.816C392.724 476.403 393.618 476.196 394.644 476.196C395.271 476.196 395.891 476.296 396.504 476.496C397.118 476.696 397.624 476.976 398.024 477.336L397.284 479.076C396.938 478.77 396.544 478.536 396.104 478.376C395.678 478.216 395.264 478.136 394.864 478.136C393.998 478.136 393.324 478.41 392.844 478.956C392.378 479.49 392.144 480.256 392.144 481.256C392.144 482.243 392.378 483.023 392.844 483.596C393.324 484.156 393.998 484.436 394.864 484.436C395.251 484.436 395.664 484.356 396.104 484.196C396.544 484.036 396.938 483.796 397.284 483.476L398.024 485.236C397.624 485.583 397.111 485.863 396.484 486.076C395.871 486.276 395.258 486.376 394.644 486.376Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Endomorphic Ectomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M433.998 332.761V318.661H436.138L441.138 327.881L446.118 318.661H448.218V332.761H445.898V323.261L441.858 330.621H440.358L436.318 323.301V332.761H433.998ZM451.321 332.761V318.661H460.701V320.701H453.781V324.581H460.261V326.621H453.781V330.721H460.701V332.761H451.321ZM467.476 332.961C466.45 332.961 465.556 332.754 464.796 332.341C464.036 331.914 463.45 331.314 463.036 330.541C462.623 329.768 462.416 328.861 462.416 327.821C462.416 326.781 462.623 325.888 463.036 325.141C463.45 324.381 464.036 323.801 464.796 323.401C465.556 322.988 466.45 322.781 467.476 322.781C468.103 322.781 468.723 322.881 469.336 323.081C469.95 323.281 470.456 323.561 470.856 323.921L470.116 325.661C469.77 325.354 469.376 325.121 468.936 324.961C468.51 324.801 468.096 324.721 467.696 324.721C466.83 324.721 466.156 324.994 465.676 325.541C465.21 326.074 464.976 326.841 464.976 327.841C464.976 328.828 465.21 329.608 465.676 330.181C466.156 330.741 466.83 331.021 467.696 331.021C468.083 331.021 468.496 330.941 468.936 330.781C469.376 330.621 469.77 330.381 470.116 330.061L470.856 331.821C470.456 332.168 469.943 332.448 469.316 332.661C468.703 332.861 468.09 332.961 467.476 332.961Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Mesomorphic Ectomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M336.585 170.575V156.475H345.965V158.515H339.045V162.395H345.525V164.435H339.045V168.535H345.965V170.575H336.585ZM352.74 170.775C351.713 170.775 350.82 170.568 350.06 170.155C349.3 169.728 348.713 169.128 348.3 168.355C347.887 167.581 347.68 166.675 347.68 165.635C347.68 164.595 347.887 163.701 348.3 162.955C348.713 162.195 349.3 161.615 350.06 161.215C350.82 160.801 351.713 160.595 352.74 160.595C353.367 160.595 353.987 160.695 354.6 160.895C355.213 161.095 355.72 161.375 356.12 161.735L355.38 163.475C355.033 163.168 354.64 162.935 354.2 162.775C353.773 162.615 353.36 162.535 352.96 162.535C352.093 162.535 351.42 162.808 350.94 163.355C350.473 163.888 350.24 164.655 350.24 165.655C350.24 166.641 350.473 167.421 350.94 167.995C351.42 168.555 352.093 168.835 352.96 168.835C353.347 168.835 353.76 168.755 354.2 168.595C354.64 168.435 355.033 168.195 355.38 167.875L356.12 169.635C355.72 169.981 355.207 170.261 354.58 170.475C353.967 170.675 353.353 170.775 352.74 170.775ZM357.934 170.575V156.475H360.074L365.074 165.695L370.054 156.475H372.154V170.575H369.834V161.075L365.794 168.435H364.294L360.254 161.115V170.575H357.934Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Ectomorphic Mesomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M182.544 170.575V156.475H191.924V158.515H185.004V162.395H191.484V164.435H185.004V168.535H191.924V170.575H182.544ZM194.238 170.575V160.795H196.678V162.315C197.011 161.755 197.458 161.328 198.018 161.035C198.591 160.741 199.231 160.595 199.938 160.595C202.244 160.595 203.398 161.935 203.398 164.615V170.575H200.898V164.735C200.898 163.975 200.751 163.421 200.458 163.075C200.178 162.728 199.738 162.555 199.138 162.555C198.404 162.555 197.818 162.788 197.378 163.255C196.951 163.708 196.738 164.315 196.738 165.075V170.575H194.238ZM206.217 170.575V156.475H208.357L213.357 165.695L218.337 156.475H220.437V170.575H218.117V161.075L214.077 168.435H212.577L208.537 161.115V170.575H206.217Z"
              onClick={() => {
                window.scrollTo(0, props.section1Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Endomorphic Mesomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M292.383 178.101H278.283V171.941C278.283 170.461 278.609 169.314 279.263 168.501C279.903 167.688 280.796 167.281 281.943 167.281C282.703 167.281 283.356 167.474 283.903 167.861C284.449 168.248 284.849 168.781 285.103 169.461C285.329 168.661 285.729 168.041 286.303 167.601C286.863 167.148 287.576 166.921 288.443 166.921C289.696 166.921 290.669 167.348 291.363 168.201C292.043 169.041 292.383 170.214 292.383 171.721V178.101ZM284.263 175.641V172.381C284.263 170.634 283.589 169.761 282.243 169.761C280.896 169.761 280.223 170.634 280.223 172.381V175.641H284.263ZM290.443 175.641V172.041C290.443 170.281 289.736 169.401 288.323 169.401C286.909 169.401 286.203 170.281 286.203 172.041V175.641H290.443ZM292.383 164.292H278.283V162.152L287.503 157.152L278.283 152.172V150.072H292.383V152.392H282.883L290.243 156.432V157.932L282.923 161.972H292.383V164.292Z"
              onClick={() => {
                window.scrollTo(0, props.section1Ref.current?.offsetTop!);
              }}
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Balanced Mesomorph"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M287.385 332.961C285.918 332.961 284.658 332.668 283.605 332.081C282.565 331.481 281.765 330.641 281.205 329.561C280.645 328.468 280.365 327.181 280.365 325.701C280.365 324.221 280.645 322.941 281.205 321.861C281.765 320.781 282.565 319.948 283.605 319.361C284.658 318.761 285.918 318.461 287.385 318.461C288.332 318.461 289.225 318.608 290.065 318.901C290.918 319.194 291.618 319.614 292.165 320.161L291.325 322.201C290.725 321.694 290.112 321.328 289.485 321.101C288.858 320.861 288.185 320.741 287.465 320.741C286.038 320.741 284.952 321.174 284.205 322.041C283.458 322.894 283.085 324.114 283.085 325.701C283.085 327.288 283.458 328.514 284.205 329.381C284.952 330.248 286.038 330.681 287.465 330.681C288.185 330.681 288.858 330.568 289.485 330.341C290.112 330.101 290.725 329.728 291.325 329.221L292.165 331.261C291.618 331.794 290.918 332.214 290.065 332.521C289.225 332.814 288.332 332.961 287.385 332.961Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Central"
                  ? "white"
                  : "black"
              }
            />
            <path
              d="M412.176 409.737L419.226 397.526L424.561 400.606C425.843 401.346 426.672 402.202 427.05 403.174C427.434 404.135 427.34 405.112 426.767 406.105C426.387 406.764 425.893 407.233 425.284 407.513C424.676 407.793 424.014 407.873 423.299 407.752C423.878 408.348 424.215 409.005 424.309 409.721C424.422 410.433 424.262 411.164 423.828 411.915C423.202 413 422.346 413.63 421.26 413.803C420.192 413.972 419.006 413.68 417.701 412.927L412.176 409.737ZM418.367 403.935L421.19 405.565C422.703 406.438 423.796 406.291 424.469 405.125C425.142 403.959 424.723 402.939 423.21 402.066L420.387 400.436L418.367 403.935ZM415.277 409.287L418.394 411.087C419.919 411.967 421.034 411.795 421.741 410.571C422.447 409.347 422.039 408.295 420.514 407.415L417.397 405.615L415.277 409.287ZM424.101 416.622L431.151 404.411L439.274 409.101L438.254 410.867L432.261 407.407L430.321 410.767L435.933 414.007L434.913 415.774L429.301 412.534L427.251 416.085L433.244 419.545L432.224 421.312L424.101 416.622ZM437.992 424.872C437.103 424.359 436.432 423.733 435.981 422.995C435.536 422.246 435.328 421.433 435.356 420.556C435.385 419.68 435.66 418.792 436.18 417.891C436.7 416.99 437.325 416.32 438.056 415.88C438.794 415.428 439.593 415.219 440.451 415.253C441.316 415.275 442.193 415.543 443.082 416.056C443.624 416.369 444.111 416.766 444.542 417.246C444.974 417.726 445.272 418.222 445.439 418.733L443.928 419.87C443.781 419.431 443.557 419.033 443.256 418.674C442.967 418.322 442.649 418.046 442.302 417.846C441.552 417.413 440.832 417.313 440.143 417.546C439.472 417.775 438.887 418.322 438.387 419.188C437.893 420.043 437.705 420.835 437.823 421.565C437.958 422.29 438.402 422.869 439.152 423.302C439.487 423.495 439.885 423.633 440.346 423.714C440.807 423.796 441.268 423.785 441.728 423.681L441.489 425.575C440.969 425.675 440.384 425.661 439.735 425.532C439.104 425.399 438.523 425.179 437.992 424.872Z"
              fill={
                props.currentCategory[props.currentIndexCat2].label ===
                "Balanced Ectomorph"
                  ? "white"
                  : "black"
              }
            />
          </svg>
        )}
      </Box>
    </Grid>
  );
}

export default TypesPage1;
